import { format, parse } from "date-fns";

export class DateFormatter {
  /**
   * Parse a date string in the format "yyyy-MM-dd" to a Date object
   */
  static fromYYYYMMDD(date: string): Date {
    return parse(date, "yyyy-MM-dd", new Date());
  }

  /**
   *
   * @returns string with format yyyy-MM-dd
   */
  static toYYYYMMDD(date: Date) {
    try{
        return format(date, "yyyy-MM-dd");
    }catch (e){
        return "";
    }
  }

  /**
   * Parse a date string in the json string format to a Date object
   */
  static fromJSONDate(date: string): Date {
    return new Date(date);
  }

  /**
   * Format a date object to the format "MMM do, yyyy hh:mm a"
   */
  static toCreatedProjectDateFormat(date: Date): string {
    try{
      return format(date, "MMM do, yyyy hh:mm a");
    }catch (e){
      return "";
    }
  }

  /**
   * Format a date object to the format "MMM do, yyyy"
   */
  static toInvitedProjectDateFormat(date: Date): string {
    try{
      return format(date, "MMM do, yyyy");
    }
    catch (e){
      return "";
    }
  }

  /**
   * Format a date object to the format "MMM do, yyyy"
   */
  static toMMMdoYYYY(date: Date): string {
    try{
      return format(date, "MMM do, yyyy");
    }
    catch (e){
      return "";
    }
  }
}
