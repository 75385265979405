/** @jsxImportSource @emotion/react */
import React from "react";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";
import TextField from "./TextField";
import { css } from "@emotion/react";

type Props = {
  children?: string;
};

const RoundedLabel = ({ children }: Props) => {
  return (
    <div css={styles.mainContainer}>
      <TextField alignCenter fontWeight="regular" customFontSize={10} color="mainTitles">{children}</TextField>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    borderRadius: "20px",
    border: "1px solid " + colors.darkGrayTransparent50,
    padding: "5px",
    minWidth: "58px",
    minHeight: "23px",
    width: "min-content",
  }),
};

export default RoundedLabel;
