/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import AssignContactsForm from "src/presentation/components/CreateProjectForm/AssignContactsForm";
import {
  Button,
  Card,
  LoadingCover,
} from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";
import useFormContext from "../context/useFormContext";

type Props = {
  onClose: () => void;
};

const AssignContactModal = ({ onClose }: Props) => {
  const {
    formData,
    isLoadingForm,
    isLoadingUpdateContacts,
    fieldValues,
    onChangeValues,
    onSubmitContactsForm,
  } = useFormContext();

  const submitHandler = async () => {
    await onSubmitContactsForm();
    onClose();
  }

  if (isLoadingForm) return <Card customCss={styles.loadingCoverContainer}><LoadingCover /></Card>

  return (
    <div css={styles.mainContainer}>
      <Card customCss={styles.formContainer}>
        {isLoadingForm ? (
          <LoadingCover />
        ) : (
          <AssignContactsForm
            availableBillingContact={formData.availableBillingContact}
            availableOnSiteContacts={formData.availableOnSiteContacts}
            fieldValues={fieldValues}
            onChangeValues={onChangeValues}
          />
        )}
      </Card>
      <div css={styles.buttonsContainer}>
        <Button
          onClick={onClose}
          customCss={styles.actionButton}
          height={50}
          disabled={isLoadingForm || isLoadingUpdateContacts}
          color="error"
        >
          CANCEL
        </Button>
        <Button
          loading={isLoadingUpdateContacts}
          onClick={submitHandler}
          customCss={styles.actionButton}
          height={50}
          disabled={isLoadingForm || isLoadingUpdateContacts}
        >
          UPDATE
        </Button>
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    height: "90%",
    width: "90%",
    maxHeight: "580px",
    maxWidth: "600px",
    gap: "10px",
  }),
  formContainer: css({
    display: "flex",
    height: "100%",
    width: "100%",
    padding: "40px",
  }),
  buttonsContainer: css({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  }),
  actionButton: css({
    width: "50%",
    maxWidth: "215px",
  }),
  loadingCoverContainer: css({
    padding: "20%",
  })
};

export default AssignContactModal;
