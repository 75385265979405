/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { ReactComponent as AppLogo } from "src/presentation/assets/images/login-title.svg";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";

const Logo = () => {
  return (
    <div css={styles.mainContainer}>
      <AppLogo css={styles.appLogo} />
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "grid",
    placeItems: "center",
    width: "100%",
  }),
  appLogo: css({
    width: "50%",
    height: "fit-content",
  }),
};

export default Logo;
