import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  title: string;
  children: JSX.Element;
};

const PageWithTitle = ({ title, children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    const prevTitle = document.title;

    document.title = title;

    return () => {
      document.title = prevTitle;
    }
  }, [location.pathname]);

  return (children);
};

export default PageWithTitle;
