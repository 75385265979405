/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from "@emotion/react";
import React, { memo, CSSProperties } from "react";
import { colors, fonts } from "src/presentation/theme";
import { Margin } from "src/presentation/types";
import LoadingSpinner from "./LoadingSpinner";

type Props = {
  color?: keyof typeof colors;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  margin?: Margin;
  loading?: boolean;
  customCss?: Interpolation<Theme>
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = ({
  children,
  color = "primary",
  margin,
  width,
  height,
  loading,
  disabled,
  customCss,
  ...props
}: Props) => {
  const backgroundColor = colors[color];
  const textColor =
    color === "primaryLight" ? colors.primary : colors.pureWhite;

  return (
    <button
      css={[
        {
          backgroundColor: disabled ? colors.mediumGray : backgroundColor,
          color: textColor,
          margin,
          height,
          width,
          ":hover": { cursor: (disabled || loading ) ? "not-allowed" : "pointer" },
        },
        styles.mainContainer,
        customCss,
      ]}
      disabled={disabled || loading}
      {...props}
    >
      {!loading ? children : <LoadingSpinner color="pureWhite" size={25} />}
    </button>
  );
};

const styles = {
  mainContainer: css({
    display: "grid",
    placeItems: "center",
    borderRadius: "6px",
    textAlign: "center",
    fontWeight: fonts.weight.regular,
    fontSize: fonts.sizes.small,
    letterSpacing: "1px",
    userSelect: "none",
  }),
};

export default memo(Button);
