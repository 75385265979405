import React, { useContext } from 'react'
import { FormContext } from './FormProvider'

const useFormContext = () => {
  const {formData, fieldValues, onChangeValues, isLoadingForm, isLoadingUpdateInfo, isLoadingUpdateContacts, onSubmitInfoForm, onSubmitContactsForm} = useContext(FormContext)!;

  return {
    formData,
    fieldValues,
    onChangeValues,
    isLoadingForm,
    isLoadingUpdateInfo,
    isLoadingUpdateContacts,
    onSubmitInfoForm,
    onSubmitContactsForm,
  }
}

export default useFormContext