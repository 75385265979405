import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "src/presentation/di";

type Props = {
  appMainRoute?: string;
  children: JSX.Element;
};

const AuthRoute = ({ appMainRoute = "/", children }: Props) => {
  const { isLogged } = useAuth();

  if (isLogged) {
    return <Navigate replace to={appMainRoute} />;
  }

  return children;
};

export default AuthRoute;
