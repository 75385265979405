/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import HeaderContainer from "src/presentation/components/HeaderProfile/HeaderContainer";
import { LoadingCover, TextField } from "src/presentation/components/UI";

import useViewModel from "./CreateProjectScreenViewModel";
import CreateProjectForm from "./components/CreateProjectForm";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import FormProvider from "./context/FormProvider";
import ProjectCreatedSuccesfully from "./components/ProjectCreatedSuccesfully";

type Props = {
  onGoBackToDashboard: () => void;
};

const CreateProjectScreenView = ({ onGoBackToDashboard }: Props) => {
  const {
    data,
    isLoading,
    error,
    isError,
    projectCreated,
    fieldValues,
    onChangeValues,
    ...viewModel
  } = useViewModel();

  if (isLoading) return <LoadingCover />;

  if (isError) return <div>{error?.message}</div>;

  if(projectCreated) return <ProjectCreatedSuccesfully onBackToDashboard={onGoBackToDashboard}/>

  return (
    <div css={styles.mainContainer}>
      <HeaderContainer customCss={styles.headerContainer}>
        <TextField fontWeight="bold" customFontSize={32} color="darkGray">
          {data.userLogged?.company?.name}
        </TextField>
      </HeaderContainer>

      <div css={styles.createProjectFormContainer}>
        <FormProvider
          value={{
            data,
            fieldValues,
            onChangeValues,
            isLoadingForm: viewModel.isLoadingCreateProject,
            onSubmitForm: viewModel.onCreateProject,
          }}
        >
          <CreateProjectForm/>
        </FormProvider>
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "2rem 2rem",
  }),
  headerContainer: css({
    marginBottom: "50px",
    alignItems: "center",
    [BREAKPOINTS.MEDIUM]: {
      justifyContent: "center",
      marginBottom: "25px",
    },
  }),
  createProjectFormContainer: css({
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    maxWidth: "700px",
    margin: "0 auto 0 auto",
  }),
};

export default CreateProjectScreenView;
