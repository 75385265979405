import { IAuthRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";
import { IAuthLocalDataSource } from "./IAuthLocalDataSource"
import { IAuthRemoteDataSource } from "./IAuthRemoteDataSource";
import { AuthResponse } from "src/domain/entities/AuthResponse";
import { AuthData } from "src/domain/entities";
import { ApiAuthReponse } from "src/data/models/auth";

export class AuthRepositoryImp implements IAuthRepository {
    constructor(
        private authLocalDataSource: IAuthLocalDataSource,
        private authRemoteDataSource: IAuthRemoteDataSource
    ) {}

    async login(email: string, password: string): DataResponse<AuthResponse> {
        const apiAuthReponse = await this.authRemoteDataSource.login(email, password) as ApiAuthReponse;

        const authReponse = apiAuthReponse.toDomain();
        return authReponse;
    }

    async sendResetPasswordEmail(email: string): DataResponse<void> {
        await this.authRemoteDataSource.sendResetPasswordEmail(email);
    }

    async getAuthData(): DataResponse<AuthData | null> {
        return this.authLocalDataSource.getAuthData();
    }

    async setAuthData(authData: AuthData, remember: boolean): DataResponse<void> {
        return this.authLocalDataSource.setAuthData(authData, remember);
    }

    async deleteAuthData(): DataResponse<void> {
        return this.authLocalDataSource.deleteAuthData();
    }
}