/** @jsxImportSource @emotion/react */
import React from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import TextField from "./TextField";
import IconWithText from "./IconWithText";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

type Props = {
  items: string[];
  requiredLabel?: string;
  onRemoveItem: (item: string) => void;
  customCss?: Interpolation<Theme>;
};

/**
 * List of items that CANNOT be repeated
 */
const DeleteableItemList = ({ items, requiredLabel, onRemoveItem, customCss }: Props) => {
  return (
    <div css={customCss}>
      {items.length === 0 && requiredLabel ? (
        <TextField color="error">{requiredLabel}</TextField>
      ) : null}
      <div>
        {items.map((item, i) => (
          <IconWithText
            onClick={() => onRemoveItem(item)}
            key={item}
            icon={faXmark}
          >
            {item}
          </IconWithText>
        ))}
      </div>
    </div>
  );
};

export default DeleteableItemList;
