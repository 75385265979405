//AUTH
export const LOGIN_URL = "/api/v2/login";
export const RESET_PASSWORD_URL = "/api/forgot-password";

//PROJECT
export const GET_QUOTE_PROJECTS_LIST_URL = "/api/invitation";
export const GET_CREATED_PROJECTS_LIST_URL = "/api/project";

export const GET_QUOTE_PROJECT_BY_ID_URL = "/api/invitation";
export const GET_CREATED_PROJECT_BY_ID_URL = "/api/project";

export const CREATE_PROJECT_URL = "/api/project";
export const UPDATE_PROJECT_URL = "/api/project";
export const UPLOAD_PROJECT_QUOTE__URL = "/api/project-quote";
export const UPDATE_PROJECT_QUOTE_URL = "/api/project-quote"

export const GET_PROJECT_TYPES_URL = "/api/project-types"

//JOBSITE
export const GET_JOBSITES_URL = "/api/job-site"
export const CREATE_JOBISTE_URL = "/api/job-site"

//COMPANY
export const GET_COMPANY_CONTACTS_URL = "/api/company-contact"
export const UPDATE_COMPANY_CONTACT = "/api/company-contact"

//INVOICE
export const GET_INVOICES_PREV = "/api/invoices"

//FILE
export const UPLOAD_FILE_BASE_64 = "/api/document"
export const UPLOAD_FILE_WITH_NAME_BASE_64 = "/api/v2/document"