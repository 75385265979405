/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { CircularImage, TextField } from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";

type Props = {
    title: string;
    subtitle: string;
}

const PersonInfoContainer = ({title, subtitle}: Props) => {
  return (
    <div css={styles.mainContainer}>
      <CircularImage size="40px" defaultBackgroundColor="lightGreen" />

      <div>
        <TextField fontWeight="bold" fontSize="medium">
          {title}
        </TextField>
        <TextField color="greyText" fontWeight="light" fontSize="small">
          {subtitle}
        </TextField>
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "28px",
  }),
};

export default PersonInfoContainer;
