import { ApiFileData } from "src/data/models/file/ApiFileData";
import { CustomError, DataResponse, errorCodeEnum, errorMessageEnum, IHttpClient } from "src/domain";
import { IFileRemoteDataSource } from "./IFileRemoteDataSource";
import { ApiFileDataWithName } from "src/data/models/file/ApiFileDataWithName";
import { UPLOAD_FILE_BASE_64, UPLOAD_FILE_WITH_NAME_BASE_64 } from "src/data/apiUrls";

export class FileRemoteDataSourceImp implements IFileRemoteDataSource {
    constructor(
        private httpClient: IHttpClient
    ){}

    async uploadFileInBase64(base64: string, fileName?: string): DataResponse<ApiFileData> {
        const url = fileName === undefined ? UPLOAD_FILE_BASE_64 : UPLOAD_FILE_WITH_NAME_BASE_64;
    const documents = fileName === undefined ? [base64] : [{document: base64, name: fileName}]
        
        try {
            const fileDataArray = await this.httpClient.post<ApiFileData[]>(url, {documents}) as ApiFileData[];
            const fileData = ApiFileData.fromJSON(fileDataArray[0]);
            return fileData
        } catch (error) {
            throw new CustomError(errorCodeEnum.SERVER_ERROR, errorMessageEnum.FILE_UPLOAD_ERROR)
        }
    }
}