/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import {
  ActionButton,
  ActionButtonWithPlusIcon,
} from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";

type Props = {
  quoteUploaded: boolean;
  onClickMessage: () => void;
  onClickUploadQuote: () => void;
};

const ProjectActionButtons = ({
  quoteUploaded,
  onClickMessage,
  onClickUploadQuote,
}: Props) => {
  return (
    <div css={styles.mainContainer}>
      {/* <ActionButton onClick={onClickMessage} color="secondary">Message</ActionButton> */}
      {quoteUploaded ? (
        <ActionButton onClick={onClickUploadQuote} color="secondary">Update sent quote</ActionButton>
      ) : (
        <ActionButtonWithPlusIcon onClick={onClickUploadQuote} color="primary">
          Upload quote
        </ActionButtonWithPlusIcon>
      )}
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    gap: "10px",
  }),
};

export default ProjectActionButtons;
