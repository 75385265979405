import { JobSite } from "src/domain/entities/JobSite";
import { IJobSiteRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";

export class GetJobSitesUseCase {
    constructor(
        private jobSiteRepository: IJobSiteRepository
    ){}

    async execute(): DataResponse<JobSite[]> {
        return this.jobSiteRepository.getJobSites();
    }
}