/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";
import TextField from "./TextField";

export type Props = {
  color?: "primary" | "secondary";
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const ActionButton = ({ color = "primary", ...props }: Props) => {
  return (
    <button
      css={[
        styles.mainContainer,
        props.disabled ? {
          backgroundColor: colors.mediumGray,
          pointerEvents: "none",
        } : {
          backgroundColor: colors[(color + "Light") as keyof typeof colors],
          ":hover": {
            backgroundColor: colors[color],
          },
          ":hover p": {
            color: colors.pureWhite + " !important",
          },
          ":hover * svg": {
            color: colors.pureWhite + " !important",
          }
        },
      ]}
      {...props}
    >
      <TextField
        alignCenter
        color={props.disabled ? "pureWhite" : color}
        fontWeight="bold"
        fontSize="extraSmall"
      >
        {props.children}
      </TextField>
    </button>
  );
};

const styles: StyleType = {
  mainContainer: css({
    borderRadius: "100px",
    padding: "9px 18px",
    transition: "all 0.2s ease-in-out",
    "* svg": {
      transition: "all 0.2s ease-in-out",
    },
    alignItems: "center"
  }),
};

export default ActionButton;
