import { AuthData, User } from "src/domain/entities";
import { AuthResponse } from "src/domain/entities/AuthResponse";
import { IApiAuthReponse } from "./IApiAuthResponse";

export class ApiAuthReponse {
  constructor(readonly apiAuthResponse: IApiAuthReponse) {}

  toDomain(): AuthResponse {
    return new AuthResponse(
      new AuthData(this.apiAuthResponse.token),
      new User(
        this.apiAuthResponse.id.toString(),
        this.apiAuthResponse.name,
        this.apiAuthResponse.last_name ?? "",
        this.apiAuthResponse.email,
        this.apiAuthResponse.phone,
        this.apiAuthResponse.image,
        this.apiAuthResponse.sendbird_id,
        this.apiAuthResponse.sendbird_token,
        {
            id: this.apiAuthResponse.company.id,
            name: this.apiAuthResponse.company.name,
            address: this.apiAuthResponse.company.address,
        }
      )
    );
  }
}
