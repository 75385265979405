import { GetSavedUserUseCase } from "../usecases";
import { UpdateUserDataUseCase } from "../usecases/user/UpdateUserDataUseCase";

export class UserInteractor {
    constructor(
        private getSavedUserUseCase: GetSavedUserUseCase,
        private updateUserDataUseCase: UpdateUserDataUseCase,
    ){}

    async getSavedUser() {
        return this.getSavedUserUseCase.execute();
    }

    async updateUserData(firstName?: string, lastName?: string, email?: string, phoneNumber?: string) {
        return this.updateUserDataUseCase.execute(firstName, lastName, email, phoneNumber)
    }
}