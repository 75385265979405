export enum errorCodeEnum {
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    SERVER_ERROR = 500,
    BAD_REQUEST = 400,
    FORBIDDEN = 403,
    METHOD_NOT_ALLOWED = 405,
    CONFLICT = 409,
}

export enum errorMessageEnum {
    LOGGED_USER_NOT_FOUND = "Logged user not found",
    SERVER_ERROR = "Server error",
    INVALID_CREDENTIALS = "Invalid credentials",
    INVALID_EMAIL = "Invalid email",
    PASSWORDS_NOT_MATCH = "Passwords do not match",
    FILE_UPLOAD_ERROR = "File upload error",
    CREATE_JOBSITE_ERROR = "Error creating jobsite",
    UPLOAD_QUOTE_ERROR = "Error uploading quote",
    CREATE_PROJECT_ERROR = "Error creating project",
    UPDATE_PROJECT_ERROR = "Error updating project",
    GET_COMPANY_CONTACTS_ERROR = "Error getting company contacts",
    UPDATE_PROFILE_ERROR = "Error updating profile",
    GET_JOBSITES_ERROR = "Error getting JobSites",
    GET_PROJECT_TYPES_ERROR = "Error getting project types",
    GET_INVOICES_PREV_ERROR = "Error getting invoices",
}

export class CustomError extends Error {
    constructor(readonly code: errorCodeEnum, readonly error: string, readonly extraInfo?: string) {
        super(error);
    }
}