import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CompanyContactPrev, CreatedProject, CustomError, Project, ProjectType } from "src/domain";
import { QuotePrev } from "src/domain/entities/QuotePrev";
import { QuoteProject } from "src/domain/entities/QuoteProject";
import { UploadQuoteData } from "src/domain/usecases/project/UploadProjectQuoteUseCase";
import { companyInteractor, projectInteractor } from "src/presentation/di";

import useInputs from './hooks/useInputs'
import { OptionType } from "src/presentation/components/UI/DropDownMenu";

const CreatedProjectScreenViewModel = () => {
  const { id } = useParams<{ id: string }>();
  const [project, setProject] = useState<CreatedProject | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<null | CustomError>(null);

  // FORM
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [isLoadingUpdateContacts, setIsLoadingUpdateContacts] = useState(false);
  const [isLoadingUpdateInfo, setIsLoadingUpdateInfo] = useState(false);
  const [availableProjectTypes, setAvailableProjectTypes] = useState<OptionType[]>([]);
  const [availableOnSiteContacts, setAvailableOnSiteContacts] = useState<OptionType[]>([]);
  const [availableBillingContact, setAvailableBillingContact] = useState<OptionType[]>([]);

  const {values: fieldValues, onChange: onChangeValues, onReset} = useInputs();

  useEffect(() => {
    getCreatedProjectById(id!);
  }, []);

  const getCreatedProjectById = async (projectId: string) => {
    try {
      setIsLoading(true);
      const project = (await projectInteractor.getCreatedProjectById(
        projectId
      )) as CreatedProject;
      setProject(project);
    } catch (error) {
      setError(error as CustomError);
    } finally {
      setIsLoading(false);
    }
  };

  const loadContactsFormHandler = async () => {
    try {
      setIsLoadingForm(true);
      const contacts = await companyInteractor.getCompanyContactsPrev() as CompanyContactPrev[];
      const contactOptions = contacts.map((contact) => ({
        label: contact.fullName,
        value: contact.id,
      }))
      console.log({contacts})
      setAvailableOnSiteContacts(contactOptions);
      setAvailableBillingContact(contactOptions);
      onReset.onResetOnSiteContact(project!.siteSupervisorId);
      onReset.onResetBillingContact(project!.billingContactId);
    } catch (error) {
      setError(error as CustomError)
    } finally {
      setIsLoadingForm(false);
    }
  }

  const updateContactsHandler = async () => {
    try {
      setIsLoadingUpdateContacts(true);
      const updatedCreatedProject = await projectInteractor.updateProject(
        project!.id,
        fieldValues.onSiteContact,
        fieldValues.billingContact,
      ) as CreatedProject;
  
      setProject(updatedCreatedProject);
    } catch (error) {
      setError(error as CustomError);
    } finally {
      setIsLoadingUpdateContacts(false);
    }
  }

  const loadInfoFormHandler = async () => {
    try {
      setIsLoadingForm(true);
      const projectTypes = await projectInteractor.getProyectTypes() as ProjectType[]
      setAvailableProjectTypes(
        projectTypes.map(projectType => ({ label: projectType.name, value: projectType.id }))
      );

      onReset.onResetProjectType(project!.typeOfWork);
      onReset.onResetScopeOfWork(project!.scope);
      if (project!.deadline) onReset.onResetDeadlineDate(project!.deadline);
      onReset.onResetStartDate(project!.estimatedStartDate)
      onReset.onResetEndDate(project!.estimatedEndDate)
      onReset.onResetBudget(project!.budget)
      if (project!.squareFeet) onReset.onResetSquareFeet(project!.squareFeet)
      onReset.onResetMaterialsBeProvided(project!.materialBeProvided)
    } catch (error) {
      setError(error as CustomError)
    } finally {
      setIsLoadingForm(false);
    }
  }

  const updateInfoHandler = async () => {
    try {
      setIsLoadingUpdateInfo(true);
      const updatedCreatedProject = await projectInteractor.updateProject(
        project!.id,
        undefined,
        undefined,
        undefined,
        undefined,
        fieldValues.projectType,
        fieldValues.scopeOfWork,
        fieldValues.budget,
        fieldValues.materialsBeProvided,
        fieldValues.startDate,
        fieldValues.endDate,
        fieldValues.quoteDeadline,
        undefined,
        undefined,
        fieldValues.squareFeet,
      ) as CreatedProject;
  
      setProject(updatedCreatedProject);
    } catch (error) {
      setError(error as CustomError);
    } finally {
      setIsLoadingUpdateInfo(false);
    }
  }

  const openLocationHandler = () => {
    if (project) {
      if (
        project?.locationCoordinates?.lat &&
        project?.locationCoordinates?.lng
      ) {
        window.open(
          `https://maps.google.com?q=${project.locationCoordinates.lat},${project.locationCoordinates.lng}`
        );
      } else {
        alert("Location unavailable for this project");
      }
    }
  };

  return {
    data: {
      project,
    },
    formData: {
      availableProjectTypes,
      availableOnSiteContacts,
      availableBillingContact,
    },
    isLoading,
    error,
    isError: !!error,
    fieldValues,
    onChangeValues,
    isLoadingForm,
    isLoadingUpdateContacts,
    isLoadingUpdateInfo,
    onUpdateContacts: updateContactsHandler,
    onUpdateInfo: updateInfoHandler,
    onLoadContactsForm: loadContactsFormHandler,
    onLoadInfoForm: loadInfoFormHandler,
    onOpenLocation: openLocationHandler,
  };
};

export default CreatedProjectScreenViewModel;
