import React, { createContext } from 'react';
import viewModel from '../CreatedProjectScreenViewModel'

type FormDataType = ReturnType<typeof viewModel>["formData"]
type FieldValuesType = ReturnType<typeof viewModel>["fieldValues"]
type OnChangeValuesType = ReturnType<typeof viewModel>["onChangeValues"]
type IsLoadingForm = ReturnType<typeof viewModel>["isLoadingForm"]
type IsLoadingUpdateContactsType = ReturnType<typeof viewModel>["isLoadingUpdateContacts"]
type IsLoadingUpdateInfoType = ReturnType<typeof viewModel>["isLoadingUpdateInfo"]
type SubmitContactsFormType = ReturnType<typeof viewModel>["onUpdateContacts"]
type SubmitInfoFormType = ReturnType<typeof viewModel>["onUpdateInfo"]

export type FormContextType = {
  formData: FormDataType,
  fieldValues: FieldValuesType,
  onChangeValues: OnChangeValuesType,
  isLoadingForm: IsLoadingForm,
  isLoadingUpdateContacts: IsLoadingUpdateContactsType,
  isLoadingUpdateInfo: IsLoadingUpdateInfoType,
  onSubmitContactsForm: SubmitContactsFormType,
  onSubmitInfoForm: SubmitInfoFormType,
} | null

export const FormContext = createContext<FormContextType>(null)

const FormProvider = ({children, value}: {children: JSX.Element, value: FormContextType}) => {
  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  )
}

export default FormProvider