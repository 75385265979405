/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";

type Props = {
  children: React.ReactNode;
};

const FormStepContainer = ({ children }: Props) => {
  return <div css={styles.mainContainer}>{children}</div>;
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    flex: 1,
  }),
};

export default FormStepContainer;
