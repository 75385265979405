/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from "@emotion/react";
import React from "react";
import { TextField } from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";

type Props = {
  title: string;
  children: React.ReactNode;
  customCss?: Interpolation<Theme>

};

const TitleWithContent = ({ title, children, customCss }: Props) => {
  return (
    <div css={[styles.mainContainer, customCss]}>
      <TextField color="mainTitles" fontSize="medium" fontWeight="bold">
        {title}
      </TextField>
      <div>
        {children}
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
};

export default TitleWithContent;
