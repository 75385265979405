export class FileUtils {
    static getFilesFromInput(e: React.ChangeEvent<HTMLInputElement>): File[] {
        const newFilesQty = e.target.files?.length;
        const newFiles = e.target.files;

        const files: File[] = [];
    
        if (newFilesQty && newFilesQty > 0) {
          for (let i = 0; i < newFilesQty; i++) {
            const file = newFiles?.item(i);

            if (file) {
              files.push(file)
            }
          }
        }

        return files;
    }
}