export class QuoteProjectPrev {
    constructor(
        readonly id: string,
        readonly title: string,
        readonly companyName: string,
        readonly typeOfWork: string,
        readonly status: string,
        readonly estimatedStartDate: Date,
        readonly estimatedEndDate: Date,
        readonly location: string,
        readonly deadline?: Date,
    ){}
}