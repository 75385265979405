// COLORS
export const colors = {
  primary: '#26A74E', //green
  primaryLight: 'rgba(38, 167, 78, 0.2)', //light green
  secondary: '#FF9228', //orange
  secondaryLight: 'rgba(252, 163, 77, 0.2)', //light orange

  mainTitles: '#3E495A',
  // mulledWine: '#524B6B',
  error: '#E5252A',
  // space: '#00202B',

  pureWhite: '#FFFFFF',
  pureBlack: '#000000',
  white: '#F9F9F9',
  gray: '#F2F2F2',
  darkGray: 'rgba(0, 0, 0, 0.7)',
  mediumGray: 'rgba(0, 0, 0, 0.5)',
  darkGrayTransparent50: 'rgba(209, 211, 211, 0.8)',

  // Project colors
  lightGreen: "#B5E577",
  darkGrayText: "#505A75",
  greyText: "#8F97A7",

  
  // black: 'black',
  // silverSand: '#C4C4C4',

  // background: '#F9F9F9',
  // grayBackground2Light: 'rgba(217, 217, 217, 0.3)',

  // transparentWhite: 'rgba(255, 255, 255, 0.1)',
  // transparentBlack: 'rgba(0, 0, 0, 0.5)',
  // transparentBlack70: 'rgba(0, 0, 0, 0.7)',
  // textBlack: '#0D0140',
  // textGray: '#AAA6B9',
  // black2: '#3E495A',

  // grayBackground: '#E5E5E5',
  grayBackground2: '#D9D9D9',

  // backgroundImageColor: '#D9D9D9',

  // active: '#26A74E', //green

  // profileHeaderColors: {
  //   first: '#26A74E',
  //   second: '#6BCB77',
  // },

  // profileButton: {
  //   text: '#150B3D',
  //   icon: '#3E495A',
  //   shadowColor: '#99ABC6',
  // },
  // green_crayola: '#27AE60',
  // meatbrown: '#E2B93B',
  // red_fire_opal: '#EB5757',

  // philippine_yellow: '#FFC800',
};

export const fonts = {
  weight: {
    light: '300',
    regular: '500',
    bold: '700',
  },

  sizes: {
    minimal: "10px",
    extraSmall: "12px",
    small: "14px",
    medium: "16px",
    large: "18px",
    mediumLarge: "20px",
    extraLarge: "22px",
  },
};
