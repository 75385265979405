import React, { useState } from "react";
import { CustomError } from "src/domain";
import { useAuth } from "src/presentation/di";
import { ExpressionMatcher } from "src/presentation/utils/ExpressionMatcher";

const ForgotPasswordScreenViewMode = () => {
  const [email, setEmail] = useState("");
  
  const {onSendResetPasswordEmail} = useAuth();

  const resetPasswordHandler = async () => {
    setError(null);

    if (ExpressionMatcher.matchEmail(email)) {
      setLoading(true);
      try {
        await onSendResetPasswordEmail(email);
      } catch (error) {
        setError(error as CustomError);
        throw error;
      } finally {
        setLoading(false);
      }
    }
  };

  const changeEmailHandler = (email: string) => {
    setEmail(email);
  };

  const resetErrorHandler = () => {
    setError(null);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<CustomError | null>(null);

  return {
    loading,
    error,
    isError: !!error,
    email,
    onResetPassword: resetPasswordHandler,
    onResetError: resetErrorHandler,
    onChangeEmail: changeEmailHandler,
  };
};

export default ForgotPasswordScreenViewMode;
