/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { TabButton } from "../UI";
import { Link } from "react-router-dom";
import HeaderProfile from "../HeaderProfile/HeaderProfile";

type ProfileLink = {
  path: string;
  title: string;
};

type Props = {
  links: ProfileLink[];
  currentPath?: string;
  children?: React.ReactNode;
};

const ProfileNavbarContainer = ({ links, currentPath, children }: Props) => {
  return (
    <div css={styles.mainContainer}>
      <HeaderProfile customCss={styles.headerProfile} />

      <div css={styles.innerContainer}>
        <div css={styles.tabsContainer}>
          <div css={styles.tabsInnerContainer}>
            {links.map((link, i) => (
              <Link key={i} to={link.path}>
                <TabButton
                  key={i}
                  title={link.title.toUpperCase()}
                  selected={link.path === currentPath}
                />
              </Link>
            ))}
          </div>
        </div>
        <div css={styles.childrenContainer}>{children}</div>
      </div>
    </div>
  );
};

const MAX_WIDTH_CONTAINER = "700px";

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    padding: "2rem",
    gap: "30px",
  }),
  headerProfile: css({
    marginLeft: "auto",

    [BREAKPOINTS.MEDIUM]: {
      display: "none",
    },
  }),
  innerContainer: css({
    flex: 1,
    width: "100%",
    maxWidth: MAX_WIDTH_CONTAINER,
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    margin: "auto",
  }),
  tabsContainer: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    borderBottom: "3px solid #E5E5E5",

    [BREAKPOINTS.MEDIUM]: {
      flexDirection: "column-reverse",
      gap: "20px",
    },
  }),
  tabsInnerContainer: css({
    display: "flex",
    alignItems: "center",
  }),
  childrenContainer: css({
    display: "flex",
    flex: 1,
    position: "relative",
  }),
};

export default ProfileNavbarContainer;
