import React, { useEffect } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";
import HomeNavigation from "./HomeNavigation";
import LoginScreen from "src/presentation/pages/LoginScreen/LoginScreenView";
import { AppRoute, AuthRoute } from "./components";
import { useAuth } from "src/presentation/di";
import PageWithTitle from "../hoc/PageWithTitle";
import ForgotPasswordNavigation from "./ForgotPasswordNavigation";

const routes = {
  HOME: "/",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
};

const MainNavigation = () => {
  const { loadingUser, loadSavedUser } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    loadSavedUser();
  }, []);

  if (loadingUser) return null;

  return (
    <Routes>
      <Route
        path={`${routes.HOME}*`}
        element={
          <AppRoute>
              <HomeNavigation />
          </AppRoute>
        }
      />
      <Route
        path={routes.LOGIN}
        element={
          <AuthRoute>
            <PageWithTitle title="Login - Ubild">
              <LoginScreen />
            </PageWithTitle>
          </AuthRoute>
        }
      />
      <Route
        path={`${routes.FORGOT_PASSWORD}/*`}
        element={
          <AuthRoute>
            <ForgotPasswordNavigation
              onGoToLogin={() => navigate(routes.LOGIN)}
            />
          </AuthRoute>
        }
      />
      {/* <Route
        path="/sign-up"
        element={
          <AuthRoute>
            <div>SignUP</div>
          </AuthRoute>
        }
      /> */}
    </Routes>
  );
};

export default MainNavigation;
