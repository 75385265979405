/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import FieldsInfo from "src/presentation/components/UI/FieldsInfo";
import { StyleType } from "src/presentation/types";

const FLEX_TOTAL = 14;

const fields = [
  {name: "company", flex: 3 / FLEX_TOTAL},
  {name: "type of work", flex: 2 / FLEX_TOTAL},
  {name: "estimated start", flex: 2 / FLEX_TOTAL},
  {name: "estimated end", flex: 2 / FLEX_TOTAL},
  {name: "quote deadline", flex: 2 / FLEX_TOTAL},
  {name: "", flex: 3 / FLEX_TOTAL}, // empty field to fill the buttons space
]

const QuoteProjecFieldstInfo = () => {
  return (
    <FieldsInfo fields={fields} css={styles.mainContainer}/>
  );
};

const styles: StyleType = {
  mainContainer: css({
    gap: "10px",
    padding: "0 20px",
  }),
};

export default QuoteProjecFieldstInfo;
