/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import { StyleType } from "src/presentation/types";
import { IconWithText } from "../../UI";

export type LinkType = {
  title: string;
  path: string;
  icon: IconProp;
};

type Props = {
  links: LinkType[];
  currentPath?: string;
};

const Links = ({links, currentPath}: Props) => {
  return (
    <div css={styles.mainContainer}>
      {links.map((link, i) => (
        <Link key={i} to={link.path}>
          {link.icon && (
            <IconWithText selected={currentPath?.includes(link.path)} icon={link.icon}>
              {link.title}
            </IconWithText>
          )}
        </Link>
      ))}
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "5px",
  }),
};

export default Links;
