/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { StyleType } from "src/presentation/types";
import LoadingSpinner from "./LoadingSpinner";

const LoadingCover = () => {
  return (
    <div css={styles.mainContainer}>
      <LoadingSpinner color="primary" size="50px" />
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    width: "100%",
    height: "100%",
    display: "grid",
    placeItems: "center",
  }),
};

export default LoadingCover;
