import React from "react";
import ActionButton from "./ActionButton";
import { type Props as ActionProps } from "./ActionButton";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { colors } from "src/presentation/theme";

type Props = {
  icon: FontAwesomeIconProps["icon"];
  size?: FontAwesomeIconProps["size"];
} & ActionProps;

const ActionButtonWithIcon = ({ icon, color = "primary", size, ...props }: Props) => {
  return (
    <ActionButton color={color} {...props}>
      <>
        <FontAwesomeIcon size={size} icon={icon} color={colors[color]} />
        &nbsp;
        {props.children}
      </>
    </ActionButton>
  );
};

export default ActionButtonWithIcon;
