import { DataResponse } from "src/domain";
import { IUseChatSupport } from "./IUseChatSupport";
import { IChatSupportProvider } from "./IChatSupportProvider";

import { IntercomProvider, useIntercom } from "react-use-intercom";
import { INTERCOM_APP_ID } from "src/config";

export const useChatSupportIntercomImp: IUseChatSupport = () => {
  const { boot, shutdown } = useIntercom();

  const login = (userId: string, email: string): DataResponse<void> => {
    boot({ userId, email });
    return Promise.resolve();
  };

  const logout = (): DataResponse<void> => {
    shutdown();
    return Promise.resolve();
  };

  return {
    login,
    logout,
  };
};

export const IntercomChatSupportProvider: IChatSupportProvider = ({ children }) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>{children}</IntercomProvider>
  );
};
