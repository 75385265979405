import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomError, Project, QuotePrev } from "src/domain";
import { QuoteProject } from "src/domain/entities/QuoteProject";
import { UploadQuoteData } from "src/domain/usecases/project/UploadProjectQuoteUseCase";
import { projectInteractor } from "src/presentation/di";

const QuoteProjectScreenViewModel = () => {
  const { id } = useParams<{ id: string }>();
  const [project, setProject] = useState<QuoteProject | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<null | CustomError>(null);

  useEffect(() => {
    getInvitedProjectById(id!);
  }, []);

  const getInvitedProjectById = async (projectId: string) => {
    try {
      setIsLoading(true);
      const project = (await projectInteractor.getQuoteProjectById(
        projectId
      )) as QuoteProject;
      setProject(project);
    } catch (error) {
      setError(error as CustomError);
    } finally {
      setIsLoading(false);
    }
  };

  const openLocationHandler = () => {
    if (project) {
      if (
        project?.locationCoordinates?.lat &&
        project?.locationCoordinates?.lng
      ) {
        window.open(
          `https://maps.google.com?q=${project.locationCoordinates.lat},${project.locationCoordinates.lng}`
        );
      } else {
        alert("Location unavailable for this project");
      }
    }
  };

  const uploadQuoteHandler = async (data: UploadQuoteData) => {
    if (id) {
      try {
        let quotePrev: QuotePrev;
        if(project?.vendorQuote) {
          const quoteId = project.vendorQuote.id;
          quotePrev = await projectInteractor.updateProjectQuote(quoteId, data) as QuotePrev;
        } else {
          quotePrev = await projectInteractor.uploadProjectQuote(id, data) as QuotePrev;
        }
        setProject((prev) => {
          const updatedProject = Object.setPrototypeOf(
            { ...prev, vendorQuote: quotePrev },
            QuoteProject.prototype
          );
          return updatedProject;
        });
      } catch (error) {
        alert((error as CustomError).message);
      }
    }
  };

  return {
    data: {
      project,
    },
    isLoading,
    error,
    isError: !!error,
    onOpenLocation: openLocationHandler,
    onUploadQuote: uploadQuoteHandler,
  };
};

export default QuoteProjectScreenViewModel;
