/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import { DropDownMenu, InputField } from "src/presentation/components/UI";
import FormStepContainer from "../../pages/CreateProjectScreen/components/FormStepContainer";
import TextArea from "src/presentation/components/UI/TextArea";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { DateFormatter } from "src/domain";
import useFormContext from "../../pages/CreateProjectScreen/context/useFormContext";
import { OptionType } from "../UI/DropDownMenu";

type Props = {
  availableProjectTypes: OptionType[],
  fieldValues: {
    projectType: string,
    scopeOfWork: string,
    budget: {min: number, max: number}
    squareFeet: number,
    materialsBeProvided?: boolean,
    startDate: Date,
    endDate: Date,
    quoteDeadline: Date,
  },
  onChangeValues: {
    onChangeProjectType: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    onChangeScopeOfWork: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    onChangeBudget: (field: "min" | "max", e: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeSquareFeet: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeMaterialsBeProvided: (provided: boolean) => void,
    onChangeStartDate: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeEndDate: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeDeadlineDate: (e: React.ChangeEvent<HTMLInputElement>) => void
  }
}

const ScopeOfWorkForm = ({availableProjectTypes, fieldValues, onChangeValues}: Props) => {
  return (
    <FormStepContainer>
      <DropDownMenu
        defaultOptionLabel="Select project type..."
        value={fieldValues.projectType}
        label="Project type"
        onChange={onChangeValues.onChangeProjectType}
        options={availableProjectTypes}
      />
      <TextArea
        value={fieldValues.scopeOfWork}
        onChange={onChangeValues.onChangeScopeOfWork}
        height={100}
        label="Scope of work"
      />
      <div css={styles.inputsContainer}>
        <InputField
          selectOnFocus
          min={0}
          value={fieldValues.budget.min}
          onChange={(e) => onChangeValues.onChangeBudget("min", e)}
          type="number"
          height={40}
          label="Min budget"
        />
        <InputField
          selectOnFocus
          min={0}
          value={fieldValues.budget.max}
          onChange={(e) => onChangeValues.onChangeBudget("max", e)}
          type="number"
          height={40}
          label="Max budget"
        />
        <InputField
          selectOnFocus
          min={0}
          value={fieldValues.squareFeet}
          onChange={onChangeValues.onChangeSquareFeet}
          type="number"
          height={40}
          label="Square footage of project"
        />
      </div>
      <DropDownMenu
        defaultOptionLabel="Select an option..."
        value={
          fieldValues.materialsBeProvided === true
            ? "true"
            : fieldValues.materialsBeProvided === false
            ? "false"
            : undefined
        }
        label="Who is providing materials?"
        onChange={(e) =>
          onChangeValues.onChangeMaterialsBeProvided(
            e.target.value === "true" ? true : false
          )
        }
        options={[
          {
            value: "true",
            label: "My company",
          },
          {
            value: "false",
            label: "Subtrade",
          },
        ]}
      />
      <div css={styles.inputsContainer}>
        <InputField
          value={DateFormatter.toYYYYMMDD(fieldValues.startDate)}
          onChange={onChangeValues.onChangeStartDate}
          type="date"
          customCss={{ flex: 1 }}
          height={40}
          label="Start date"
          min={DateFormatter.toYYYYMMDD(new Date())}
          onKeyDown={(e) => e.preventDefault()}
        />
        <InputField
          value={DateFormatter.toYYYYMMDD(fieldValues.endDate)}
          onChange={onChangeValues.onChangeEndDate}
          type="date"
          customCss={{ flex: 1 }}
          height={40}
          label="End date"
          min={DateFormatter.toYYYYMMDD(fieldValues.startDate)}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div>
      <InputField
        value={DateFormatter.toYYYYMMDD(fieldValues.quoteDeadline)}
        onChange={onChangeValues.onChangeDeadlineDate}
        type="date"
        height={40}
        label="Quote deadline"
        min={DateFormatter.toYYYYMMDD(new Date())}
        onKeyDown={(e) => e.preventDefault()}
      />
    </FormStepContainer>
  );
};

const styles: StyleType = {
  inputsContainer: css({
    display: "flex",
    justifyContent: "space-between",
    gap: "35px",

    [BREAKPOINTS.SMALL]: {
      flexDirection: "column",
    },
  }),
};

export default ScopeOfWorkForm;
