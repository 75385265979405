import { LoginUseCase, LogoutUseCase } from "src/domain/usecases/auth";
import { SendResetPasswordEmailUseCase } from "../usecases/auth/sendResetPasswordEmailUseCase";

export class AuthInteractor {
    constructor(
        private loginUseCase: LoginUseCase,
        private logoutUseCase: LogoutUseCase,
        private sendResetPasswordEmailUseCase: SendResetPasswordEmailUseCase
    ){}

    login(email: string, password: string, saveData: boolean = true) {
        return this.loginUseCase.execute(email, password, saveData);
    }

    logout() {
        return this.logoutUseCase.execute();
    }

    sendResetPasswordEmail(email: string) {
        return this.sendResetPasswordEmailUseCase.execute(email);
    }

    resetPassword(newPassword: string, token: string) {
        return new Promise<void>((resolve) => setTimeout(() => resolve(), 2000));
    }
}