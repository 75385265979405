import { createContext, useContext, useState } from 'react';
import {IUserProvider} from './IUserProvider';
import {IUseUserStore, IUserStore, UserState} from './IUseUserStore';

const initialUserData = {
    user: null,
}

const initialContextState = {
    ...initialUserData,
    setUser: (user: UserState) => {},
    clearUser: () => {},
}

const UserContext = createContext<IUserStore>(initialContextState);

const UserContextProvider: IUserProvider = ({children}) => {
    const [user, setUser] = useState<UserState | null>(null);

    const setUserData = (user: UserState) => {
        setUser(user);
    }

    const clearUser = () => {
        setUser(null);
    }

    const store: IUserStore = {
        user,
        setUser: setUserData,
        clearUser,
    }

    return (
        <UserContext.Provider value={store}>{children}</UserContext.Provider>
    )
}

const useUserContextStore: IUseUserStore = () => {
    const userContextState = useContext(UserContext);

    return {
        ...userContextState
    }
}

export const UserProvider: IUserProvider = UserContextProvider;
export const useUserStore: IUseUserStore = useUserContextStore;