/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import FilePrev from "src/presentation/components/UI/FilePrev";
import ImagePrev from "src/presentation/components/UI/ImagePrev";
import VideoPrev from "src/presentation/components/UI/VideoPrev";
import { StyleType } from "src/presentation/types";
import TitleWithContent from "./TitleWithContent";

type Props = {
  imageUrls: string[];
  videoUrls: string[];
  fileUrls: string[],
};

const MediaAndFilesTab = ({ imageUrls, videoUrls, fileUrls }: Props) => {
  return (
    <div css={styles.mainContainer}>
      <div css={styles.mediaContainer}>
        <TitleWithContent title={`Job Gallery (${imageUrls.length})`}>
          <div css={styles.mediaInnerContainer}>
            {imageUrls.map((url, i) => (
              <ImagePrev key={i} src={url} alt="Job Image" />
            ))}
          </div>
        </TitleWithContent>

        {/* <TitleWithContent title={`Video Gallery (${videoUrls.length})`}>
        <div css={styles.mediaContainer}>
          {videoUrls.map((url, i) => (
            <VideoPrev key={i} downloadUrl={url} />
          ))}
        </div>
      </TitleWithContent> */}
      </div>

      <div css={styles.filesContainer}>
        <TitleWithContent title={`Files (${fileUrls.length})`}>
          <div css={styles.filesInnerContainer}>
            {fileUrls.map((fileUrl, i) => (
              <FilePrev key={i} title={`File ${i + 1}`} fileUrl={fileUrl} />
            ))}
          </div>
        </TitleWithContent>
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flex: 1,
    gap: "50px",

    [BREAKPOINTS.SMALL_MEDIUM]: {
      flexDirection: "column",
      gap: "40px",
    },
  }),
  mediaContainer: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "40px",
    width: "50%",

    [BREAKPOINTS.SMALL_MEDIUM]: {
      width: "100%",
    },
  }),
  filesContainer: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "15px",
    width: "50%",

    [BREAKPOINTS.SMALL_MEDIUM]: {
      width: "100%",
    },
  }),
  filesInnerContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  }),
  mediaInnerContainer: css({
    display: "flex",
    flexWrap: "wrap",
    gap: "15px",
  }),
  imageContainer: css({
    width: "335px",
    height: "150px",
    borderRadius: "15px",
    overflow: "hidden",
    cursor: "pointer",

    [BREAKPOINTS.SMALL]: {
      width: "100%",
    },
  }),
  image: css({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }),
};

export default MediaAndFilesTab;
