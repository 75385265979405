/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import TextField from "./TextField";

type Props = {
  label: string;
  children: React.ReactNode;
};

const WithLabel = ({ label, children }: Props) => {
  return (
    <div css={styles.mainContainer}>
      <TextField fontWeight="bold" fontSize="small" color="darkGray">
        {label}
      </TextField>
      {children}
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  }),
};

export default WithLabel;
