import React, { useState } from "react";
import { CustomError } from "src/domain";
import { useAuth } from "src/presentation/di";
import { ExpressionMatcher } from "src/presentation/utils/ExpressionMatcher";

const LoginScreenViewModel = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<CustomError | null>(null);

  const { login } = useAuth();

  const loginHandler = async () => {
    setError(null)
    if(ExpressionMatcher.matchEmail(email)) {
      setLoading(true);
      try {
        await login(email, password, rememberMe);
      } catch (error) {
        setError(error as CustomError);
      } finally {
        setLoading(false);
      }
    }
  };

  const changeEmailHandler = (email: string) => {
    setEmail(email);
  }

  const changePasswordHandler = (password: string) => {
    setPassword(password);
  }

  const switchRememberMeHandler = () => {
    setRememberMe(prev => !prev)
  }

  const resetErrorHandler = () => {
    setError(null)
  }

  return {
    onLogin: loginHandler,
    loading,
    error,
    isError: !!error,
    email,
    password,
    rememberMe,
    onSwitchRememberMe: switchRememberMeHandler,
    onResetError: resetErrorHandler,
    onChangeEmail: changeEmailHandler,
    onChangePassword: changePasswordHandler,
  };
};

export default LoginScreenViewModel;
