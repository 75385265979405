import React, { useEffect, useState } from "react";
import { CustomError } from "src/domain";
import { InvoicePrev } from "src/domain/entities/InvoicePrev";
import { invoiceInteractor } from "src/presentation/di";

const InvoiceListScreenViewModel = () => {
  const [invoices, setInvoices] = useState<InvoicePrev[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<CustomError | null>(null);

  useEffect(() => {
    const fetchInvoicesHandler = async () => {
      try {
        setIsLoading(true);
        
        const invoicesPrev = await invoiceInteractor.getInvoicesPrev() as InvoicePrev[];
        setInvoices(invoicesPrev);
      } catch (error) {
        setError(error as CustomError);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvoicesHandler();
  }, []);

  return {
    data: {
      invoices,
    },
    isLoading,
    error,
    isError: !!error,
  };
};

export default InvoiceListScreenViewModel;
