import { CreatedProjectPrev } from "./CreatedProjectPrev";
import { QuotePrev } from "./QuotePrev";

export class CreatedProject extends CreatedProjectPrev {
  constructor(
    id: string,
    title: string,
    companyName: string,
    typeOfWork: string,
    status: string,
    estimatedStartDate: Date,
    estimatedEndDate: Date,
    location: string,

    readonly budget: {min: number, max: number},
    readonly locationCoordinates: {lat?: number, lng?: number},
    readonly siteSupervisorId: string,
    readonly siteSupervisorName: string,
    readonly billingContactId: string,
    readonly billingContactName: string,
    readonly scope: string,
    readonly materialBeProvided: boolean,
    readonly imageUrls: string[],
    readonly videoUrls: string[],
    readonly fileUrls: string[],
    readonly quotesPrev: QuotePrev[],
    deadline?: Date,
    readonly squareFeet?: number,
  ) {
    super(
      id,
      title,
      companyName,
      typeOfWork,
      status,
      estimatedStartDate,
      estimatedEndDate,
      location,
      deadline,
    );
  }
}
