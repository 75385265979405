/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { CSSProperties } from "react";
import { StyleType } from "src/presentation/types";

import Icon from "./Icon";
import { faCircleDown } from "@fortawesome/pro-solid-svg-icons";
import { colors } from "src/presentation/theme";

type Props = {
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
  downloadUrl: string;
};

const VideoPrev = ({
  height = "115px",
  width = "160px",
  downloadUrl,
}: Props) => {
  const downloadVideoFromUrlHandler = () => {
    window.open(downloadUrl, "_blank");
  };

  return (
    <div
      onClick={downloadVideoFromUrlHandler}
      css={[styles.mainContainer, { height, width }]}
    >
      <Icon size="2xl" icon={faCircleDown} />
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "grid",
    placeItems: "center",
    borderRadius: "6px",
    overflow: "hidden",
    backgroundColor: colors.darkGrayTransparent50,
    cursor: "pointer",
  }),
};

export default VideoPrev;
