import React, { useState } from "react";
import { IUseAuth } from "./IUseAuth";

import { User } from "src/domain/entities";

import {useUserStore, useChatSupport, authInteractor, userInteractor} from 'src/presentation/di'

const useAuth: IUseAuth = () => {
  const [loadingUser, setLoadingUser] = useState(true);

  const { user, setUser, clearUser } = useUserStore();
  const {login: loginChatSupport, logout: logoutChatSupport} = useChatSupport();

  const loginHandler = async (email: string, password: string, saveData: boolean = true) => {
    try {
      setLoadingUser(true)
      const user = await authInteractor.login(email, password, saveData) as User;
      setUser(user)
    } catch (error) {
      throw error;
    } finally {
      setLoadingUser(false)
    }
  };

  const logoutHandler = async () => {
    await Promise.all([authInteractor.logout(), logoutChatSupport()]);
    clearUser();
  };

  const sendResetPasswordEmailHandler = async (email: string) => {
    await authInteractor.sendResetPasswordEmail(email);
  }

  const loadSavedUserHandler = async () => {
    setLoadingUser(true)
    const savedUser = await userInteractor.getSavedUser() as User | null;
    if(savedUser) {
      setUser(savedUser)
    }
    setLoadingUser(false);
  }

  const initHandler = async () => {
    if(user) {
      loginChatSupport(user.id, user.email);
    }
  }

  return {
    userLogged: user,
    isLogged: !!user,
    loadingUser,
    login: loginHandler,
    logout: logoutHandler,
    onInit: initHandler,
    loadSavedUser: loadSavedUserHandler,
    onSendResetPasswordEmail: sendResetPasswordEmailHandler,
  };
};

export default useAuth;
