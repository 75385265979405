import { ProjectType } from "src/domain/entities/ProjectType";

export class ApiProjectType {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly icon: string | null,
    ){}

    toDomain(): ProjectType {
        return new ProjectType(this.id, this.name);
    }

    static fromJSON(json: any) {
        return Object.setPrototypeOf(json, ApiProjectType.prototype);
    }
}