/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import { colors } from "src/presentation/theme";

type Props = {
  selected?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const SelectionButton = ({ selected, ...props }: Props) => {
  return (
    <button
      css={[styles.mainContainer, selected ? styles.mainContianerSelected : {":hover": { backgroundColor: colors.secondaryLight }}]}
      {...props}
    />
  );
};

const styles: StyleType = {
  mainContainer: css({
    height: "35px",
    width: "100px",
    boxShadow: "0px 4px 62px rgba(153, 171, 198, 0.18)",
    borderRadius: "5px",
    border: "1px solid rgba(209, 211, 211, 0.5)",
    transition: "all 0.2s ease-in-out",
  }),
  mainContianerSelected: css({
    backgroundColor: colors.secondary,
    border: "none",
    color: colors.pureWhite,
    pointerEvents: "none"
  }),
};

export default SelectionButton;
