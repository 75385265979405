/** @jsxImportSource @emotion/react */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";
import { css } from "@emotion/react";

type Props = {
  color: "primary" | "secondary";
  checked: boolean;
  onClick?: () => void;
};

const CheckBox = ({ color, checked, onClick }: Props) => {
  return (
    <div
      css={[
        styles.mainContainer,
        {
          backgroundColor:
            colors[(color + "Light") as "primaryLight" | "secondaryLight"],
        },
      ]}
      onClick={onClick}
    >
      {checked && <FontAwesomeIcon icon={faCheck} color={colors[color]} />}
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "grid",
    placeItems: "center",
    borderRadius: "5px",
    height: "24px",
    width: "24px",
    cursor: "pointer",
  }),
};

export default CheckBox;
