
import { FileData } from "src/domain/entities/FileData";
import { IProjectRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";
import { UploadFileUseCase } from "../file/UploadFileUseCase";
import { QuotePrev } from "src/domain/entities/QuotePrev";

export type UploadQuoteData = {
  price: number;
  startDate: Date;
  endDate: Date;
  paymentTerms: string;
  files: (File | string)[];
};

export class UploadProjectQuoteUseCase {
  constructor(
    private uploadFileUseCase: UploadFileUseCase,
    private projectRepository: IProjectRepository
  ) {}

  async execute(
    projectId: string,
    quoteData: UploadQuoteData
  ): DataResponse<QuotePrev> {
    const fileDataPromises = quoteData.files.map((file) => {
      if(typeof file === 'string') return file

      return this.uploadFileUseCase.execute(file)
    });
    const filesData = (await Promise.all(fileDataPromises)) as (FileData | string)[]
    const fileUrls = filesData.map((fileOrUrl) => typeof fileOrUrl === 'string' ? fileOrUrl : fileOrUrl.url);

    return await this.projectRepository.uploadProjectQuote(
      projectId,
      quoteData.price,
      quoteData.paymentTerms,
      quoteData.startDate,
      quoteData.endDate,
      fileUrls
    );
  }
}
