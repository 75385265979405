/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { TextField } from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";
import TabButton from "./TabButton";

type Tab = {
  title: string;
  content: React.ReactNode;
};

type Props = {
  tabs: Tab[];
  rightContent?: React.ReactNode;
  initialTab?: number;
};

const TabsContainer = ({ tabs, rightContent, initialTab }: Props) => {
  const [selectedTab, setSelectedTab] = useState((initialTab && initialTab < tabs.length) ? initialTab : 0);

  const selectTabHandler = (index: number) => {
    setSelectedTab(index);
  };

  if (tabs.length === 0) return null;

  return (
    <div css={styles.mainContainer}>
      <div css={styles.tabsContainer}>
        <div css={styles.tabsInnerContainer}>
          {tabs.map((tab, i) => (
            <TabButton
              key={i}
              title={tab.title.toUpperCase()}
              onClick={() => selectTabHandler(i)}
              selected={i === selectedTab}
            />
          ))}
        </div>

        {rightContent && <div>{rightContent}</div>}
      </div>
      <div css={styles.selectedTabContainer}>{tabs[selectedTab].content}</div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: "30px",
  }),
  tabsContainer: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    borderBottom: "3px solid #E5E5E5",

    [BREAKPOINTS.MEDIUM]: {
      flexDirection: "column-reverse",
      gap: "20px"
    }
  }),
  tabsInnerContainer: css({
    display: "flex",
    alignItems: "center",
  }),
  selectedTabContainer: css({
    display: "flex",
    flex: 1,
  }),
};

export default TabsContainer;
