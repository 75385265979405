/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { ProjectPrev } from "src/domain";
import { DateFormatter } from "src/domain/utils/DateFormatter";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";
import getStatusColor from "src/presentation/utils/getStatusColor";
import ActionButton from "./ActionButton";
import CircularImage from "./CircularImage";
import CardFieldContainer from "./CardFieldContainer";
import TextField from "./TextField";

type Props = {
  project: ProjectPrev;
  onGoToEditProject: () => void;
  onGoToViewQuotes: () => void;
};

const CreatedProject = ({
  project,
  onGoToEditProject,
  onGoToViewQuotes,
}: Props) => {
  return (
    <div css={styles.mainContainer}>
      <div css={[styles.centeredFlexContainer, styles.projectTypeContainer]}>
        <CircularImage size="40px" defaultBackgroundColor="lightGreen" />

        <div>
          <TextField fontWeight="regular" fontSize="small">
            {project.typeOfWork}
          </TextField>
        </div>
      </div>

      <CardFieldContainer label="start date" flex={2 / FLEX_TOTAL}>
        <TextField fontWeight="light" fontSize="small" color="darkGrayText">
          {DateFormatter.toCreatedProjectDateFormat(project.estimatedStartDate)}
        </TextField>
      </CardFieldContainer>

      <CardFieldContainer label="location" flex={2.5 / FLEX_TOTAL}>
        <TextField fontWeight="light" fontSize="small" color="darkGrayText">
          {project.location}
        </TextField>
      </CardFieldContainer>

      <CardFieldContainer
        label="status"
        flex={1 / FLEX_TOTAL}
        customCss={styles.statusContainer}
      >
        <TextField
          customColor={getStatusColor(project.status)}
          fontWeight="light"
          fontSize="small"
          color="darkGrayText"
        >
          {project.status}
        </TextField>
      </CardFieldContainer>

      <div css={[styles.centeredFlexContainer, styles.buttonsContainer]}>
        {/* <ActionButton onClick={onGoToEditProject} color="secondary">
          Edit project
        </ActionButton> */}
        <ActionButton onClick={onGoToViewQuotes} color="primary">
          View quotes
        </ActionButton>
      </div>
    </div>
  );
};

const FLEX_TOTAL = 10;

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    padding: "15px 20px",
    backgroundColor: colors.pureWhite,
    borderRadius: "8px",
    boxShadow: "0px 2px 1px rgba(80, 92, 117, 0.05);",
    width: "100%",
    gap: "10px",

    [BREAKPOINTS.MEDIUM_LARGE]: {
      flexDirection: "column",
    },
  }),
  centeredFlexContainer: css({
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  }),
  projectTypeContainer: css({
    flex: 2 / FLEX_TOTAL,
    gap: "28px",
    flexShrink: 1,
  }),
  buttonsContainer: css({
    flex: 2.5 / FLEX_TOTAL,
    gap: "12px",
    justifyContent: "end",
    [BREAKPOINTS.MEDIUM_LARGE]: {
      justifyContent: "center",
    },
  }),
  statusContainer: css({
    justifyContent: "center",
    [BREAKPOINTS.MEDIUM_LARGE]: {
      justifyContent: "start"
    }
  })
};

export default CreatedProject;
