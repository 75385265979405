/** @jsxImportSource @emotion/react */
import { Interpolation, Theme } from "@emotion/react";
import React from "react";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";

type Props = {
  shadow?: boolean;
  children?: React.ReactNode;
  customCss?: Interpolation<Theme>
};

const WhiteCard = ({ children, shadow, customCss }: Props) => {
  return (
    <div
      css={[
        styles.mainContainer,
        shadow ? { boxShadow: "0 4px 62px 0 rgba(153, 171, 198, 0.18)" } : {},
        customCss,
      ]}
    >
      {children}
    </div>
  );
};

const styles: StyleType = {
  mainContainer: {
    backgroundColor: colors.pureWhite,
    borderRadius: "10px",
    width: "fit-content",
  },
};

export default WhiteCard;
