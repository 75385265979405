/** @jsxImportSource @emotion/react */
import React, { CSSProperties } from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import { Margin, StyleType } from "src/presentation/types";
import { colors, fonts } from "src/presentation/theme";
import TextField from "./TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-regular-svg-icons";

type Props = {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  margin?: Margin;
  label?: string;
  customCss?: Interpolation<Theme>;
  errorLabel?: string;
  errorMatcher?: boolean;
  selectOnFocus?: boolean;
  error?: string;
  onChangeErrorMatcher?: (error: boolean) => void;
  expressionMatcher?: (value: any) => boolean;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const InputField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      width,
      height,
      margin,
      label,
      customCss,
      errorLabel,
      errorMatcher,
      selectOnFocus,
      error,
      onChangeErrorMatcher,
      expressionMatcher,
      type,
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = React.useState(type === "password");

    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const checkMathcingErrorHandler = () => {
      if (expressionMatcher && onChangeErrorMatcher) {
        if (!expressionMatcher(props.value)) {
          onChangeErrorMatcher(true);
        }
      }
    };

    const focusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
      if (selectOnFocus) {
        e.target.select();
      }
      if (onChangeErrorMatcher) {
        onChangeErrorMatcher(false);
      }
    };

    return (
      <div css={[styles.mainContainer, customCss]}>
        {label && (
          <div css={styles.labelsContainer}>
            <label htmlFor={props.id} css={styles.label}>
              {label}
            </label>
            {(error || (errorLabel && errorMatcher)) && (
              <TextField color="error" fontSize="extraSmall">
                {errorLabel ?? error}
              </TextField>
            )}
          </div>
        )}
        <div
          css={[
            styles.inputContainer,
            { width, height, margin },
            errorMatcher || error ? { borderColor: colors.error } : {},
            props.disabled ? {backgroundColor: colors.gray} : {}
          ]}
        >
          <input
            ref={ref}
            onFocus={focusHandler}
            onBlur={checkMathcingErrorHandler}
            type={
              type === "password" ? (showPassword ? "password" : "text") : type
            }
            css={styles.input}
            {...props}
          />
          {type === "password" && (
            <FontAwesomeIcon
              onClick={toggleShowPassword}
              size="xl"
              css={{
                marginLeft: "15px",
                ":hover": { cursor: "pointer" },
                userSelect: "none",
              }}
              icon={showPassword ? faEyeSlash : faEye}
            />
          )}
        </div>
      </div>
    );
  }
);

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  }),
  labelsContainer: css({
    display: "flex",
    alignItems: "center",
    gap: "20px",
  }),
  label: css({
    color: colors.darkGray,
    fontSize: fonts.sizes.small,
    fontWeight: fonts.weight.bold,
  }),
  inputContainer: css({
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    border: "1px solid rgba(209, 211, 211, 0.5);",
    color: colors.darkGray,
    padding: "0 15px",
    fontSize: fonts.sizes.extraSmall,
    ":focus-within": {
      borderColor: colors.primary,
    },
  }),
  input: css({
    outline: "none",
    border: "none",
    background: "none",
    width: "100%",
    height: "100%",
  }),
};

export default InputField;
