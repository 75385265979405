/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import HeaderContainer from "src/presentation/components/HeaderProfile/HeaderContainer";
import HeaderProfile from "src/presentation/components/HeaderProfile/HeaderProfile";
import { LoadingCover, TextField } from "src/presentation/components/UI";
import Card from "src/presentation/components/UI/Card";
import ModalContainer from "src/presentation/components/UI/ModalContainer";
import { StyleType } from "src/presentation/types";
import MediaAndFilesTab from "./components/MediaAndFilesTab";
import PersonInfoContainer from "./components/PersonInfoContainer";
import ProjectActionButtons from "./components/ProjectActionButtons";
import SummaryAndLocationTab from "./components/SummaryAndLocationTab";
import TabsContainer from "./components/TabsContainer";
import UploadQuoteModal from "./components/UploadQuoteModal";

import useViewModel from "./QuoteProjectScreenViewModel";
import { UploadQuoteData } from "src/domain/usecases/project/UploadProjectQuoteUseCase";

const QuoteProjectScreenView = () => {
  const { data, isLoading, error, isError, onOpenLocation, onUploadQuote } = useViewModel();
  const [uploadQuoteModalOpen, setUploadQuoteModalOpen] = useState(false);

  const openUploadQuoteModalHandler = () => {
    setUploadQuoteModalOpen(true);
  };

  const closeUploadQuoteModalHandler = () => {
    setUploadQuoteModalOpen(false);
  };

  const uploadQuoteHandler = async (data: UploadQuoteData) => {
    await onUploadQuote(data);
    closeUploadQuoteModalHandler();
    // see what happens when finish
  }

  if (isLoading) return <LoadingCover />;

  if (isError) return <div>Error: {error!.message}</div>;

  return (
    <ModalContainer opened={uploadQuoteModalOpen} onCloseModal={closeUploadQuoteModalHandler} modal={<UploadQuoteModal initialQuote={data.project?.vendorQuote} onUploadQuote={uploadQuoteHandler} onCloseModal={closeUploadQuoteModalHandler}/>}>
      <div css={styles.mainContainer}>
        <HeaderContainer>
          <div>
            <TextField fontWeight="bold" customFontSize={32} color="darkGray">
              {data.project!.title}
            </TextField>
            <TextField fontSize="mediumLarge" color="darkGray">
              {data.project!.typeOfWork}
            </TextField>
          </div>
        </HeaderContainer>

        <Card>
          <div css={styles.cardContainer}>
            <PersonInfoContainer
              title="Site supervisor"
              subtitle={data.project!.siteSupervisorName}
            />
            <PersonInfoContainer
              title="Billing contact"
              subtitle={data.project!.billingContactName}
            />
          </div>
        </Card>

        <TabsContainer
          tabs={[
            {
              title: "summary & location",
              content: (
                <SummaryAndLocationTab
                  scope={data.project!.scope}
                  materialsBeProvided={data.project!.materialBeProvided}
                  estimatedStartDate={data.project!.estimatedStartDate}
                  estimatedEndDate={data.project!.estimatedEndDate}
                  locationName={data.project!.location}
                  locationCoordinates={data.project!.locationCoordinates}
                  deadline={data.project!.deadline}
                  squareFeet={data.project!.squareFeet}
                  onOpenLocation={onOpenLocation}
                />
              ),
            },
            {
              title: "media & files",
              content: (
                <MediaAndFilesTab
                  imageUrls={data.project!.imageUrls}
                  videoUrls={data.project!.videoUrls}
                  fileUrls={data.project!.fileUrls}
                />
              ),
            },
          ]}
          rightContent={
            <ProjectActionButtons
              quoteUploaded={!!data.project?.vendorQuote}
              onClickMessage={() => {}}
              onClickUploadQuote={openUploadQuoteModalHandler}
            />
          }
        />
      </div>
    </ModalContainer>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    gap: "30px",
  }),
  cardContainer: css({
    display: "flex",
    alignItems: "center",
    padding: "20px 30px",
    gap: "100px",

    [BREAKPOINTS.SMALL_MEDIUM]: {
      flexDirection: "column",
      gap: "20px",
      alignItems: "start",
    },
  }),
};

export default QuoteProjectScreenView;
