import { CREATE_JOBISTE_URL, GET_JOBSITES_URL } from "src/data/apiUrls";
import { ApiJobSite } from "src/data/models/jobsite/ApiJobSite";
import { CustomError, DataResponse, errorCodeEnum, errorMessageEnum, IHttpClient } from "src/domain";
import { IJobSiteRemoteDataSource } from "./IJobSiteRemoteDataSource";

export class JobSiteRemoteDataSourceImp implements IJobSiteRemoteDataSource {
    constructor(
        private httpClient: IHttpClient
    ){}

    async createJobSite(address: string, postalCode: string, province: string, frontImgUrl: string): DataResponse<ApiJobSite> {
        try {
            const createdApiJobSiteJson = await this.httpClient.post(CREATE_JOBISTE_URL, {
                address,
                postalCode,
                city: province, //TODO: check this
                province: "9886", //TODO: check this
                streetview: frontImgUrl,
            })

            const createdApiJobSite = ApiJobSite.fromJson(createdApiJobSiteJson);

            return createdApiJobSite;
        } catch (error) {
            throw new CustomError(errorCodeEnum.SERVER_ERROR, errorMessageEnum.CREATE_JOBSITE_ERROR);
        }
    }
    
    async getJobSites(): DataResponse<ApiJobSite[]> {
        try {
            const apiJobSitesJson = await this.httpClient.get<any[]>(GET_JOBSITES_URL) as any[]

            const apiJobSites = apiJobSitesJson.map(apiJobSiteJson => ApiJobSite.fromJson(apiJobSiteJson));

            return apiJobSites;
        } catch (error) {
            throw new CustomError(errorCodeEnum.SERVER_ERROR, errorMessageEnum.GET_JOBSITES_ERROR);
        }
    }
}