import React, { useContext } from 'react'
import { FormContext } from './FormProvider'

const useFormContext = () => {
  const {data, fieldValues, onChangeValues, isLoadingForm, onSubmitForm} = useContext(FormContext)!;

  return {
    data,
    fieldValues,
    onChangeValues,
    isLoadingForm,
    onSubmitForm,
  }
}

export default useFormContext