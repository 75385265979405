import { DataResponse, IUserRepository, User } from "src/domain";
import { IUserLocalDataSource } from "./IUserLocalDataSource";
import { IUserRemoteDataSource } from "./IUserRemoteDataSource";
import { ApiUser } from "src/data/models/auth/ApiUser";

export class userRepositoryImp implements IUserRepository {
  constructor(private userRemoteDataSource: IUserRemoteDataSource, private userLocalDataSource: IUserLocalDataSource) {}

  async getSavedUser(): DataResponse<User | null> {
    return this.userLocalDataSource.getUser();
  }
  async setSavedUser(user: User): DataResponse<void> {
    return this.userLocalDataSource.setUser(user);
  }
  async deleteSavedUser(): DataResponse<void> {
    return this.userLocalDataSource.deleteUser();
  }

  async updateUserData(firstName?: string, lastName?: string, email?: string, phoneNumber?: string): DataResponse<User> {
    const {id} = await this.getSavedUser() as User;
    const apiUser = await this.userRemoteDataSource.updateUserData(id ,firstName, lastName, email, phoneNumber) as ApiUser;
    return apiUser.toDomain();
  }
}
