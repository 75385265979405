import { CustomError, DataResponse, IHttpClient, User, errorCodeEnum, errorMessageEnum } from "src/domain";
import { IUserRemoteDataSource } from "./IUserRemoteDataSource";
import { UPDATE_COMPANY_CONTACT } from "src/data/apiUrls";
import { ApiUser } from "src/data/models/auth/ApiUser";

export class userRemoteDataSourceImp implements IUserRemoteDataSource {
    constructor(
        private httpClient: IHttpClient
    ){}

    async updateUserData(userId: string, firstName?: string, lastName?: string, email?: string, phoneNumber?: string): DataResponse<ApiUser> {
        try {
            const updatedApiUserJson = await this.httpClient.put<ApiUser>(`${UPDATE_COMPANY_CONTACT}/${userId}`, {
                name: firstName,
                last_name: lastName,
                email,
                phone: phoneNumber
            })

            const updatedApiUser = ApiUser.fromJson(updatedApiUserJson);

            return updatedApiUser;
        } catch (error) {
            throw new CustomError(errorCodeEnum.SERVER_ERROR, errorMessageEnum.UPDATE_PROFILE_ERROR);
        }
    }
    
}