import { DataResponse, User } from "src/domain";
import { IUserLocalDataSource } from "./IUserLocalDataSource";

const USER_KEY = "user";

export class UserLocalDataSourceImp implements IUserLocalDataSource {
  async getUser(): DataResponse<User | null> {
    const jsonUser = localStorage.getItem(USER_KEY);
    if (jsonUser) {
      const user = Object.setPrototypeOf(
        JSON.parse(jsonUser),
        User.prototype
      ) as User;
      return user;
    }
    return null;
  }

  async setUser(user: User): DataResponse<void> {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  async deleteUser(): DataResponse<void> {
    localStorage.removeItem(USER_KEY);
  }
}
