import { CreatedProject } from "src/domain/entities/CreatedProject";
import { DateFormatter } from "src/domain/utils/DateFormatter";
import { ApiQuotePrev } from "./ApiQuotePrev";

export class ApiCreatedProject {
  constructor(
    readonly id: string,
    readonly title: string,
    readonly companyId: number,
    readonly companyName: string,
    readonly jobSiteId: number,
    readonly typeOfWork: string,
    readonly status: string,
    readonly estimatedStartDate: string,
    readonly estimatedEndDate: string,
    readonly location: string,
    readonly locationCoordinates: { lat?: string; lng?: string },
    readonly budget: { min: string; max: string },
    readonly billingContactId: number,
    readonly billingContactName: string,
    readonly siteSupervisorId: number,
    readonly siteSupervisorName: string,
    readonly scope: string,
    readonly materialBeProvided: boolean,
    readonly created_at: string,
    readonly relevantDocuments: string[],
    readonly blueprintDocuments: string[],
    readonly projectQuote?: ApiQuotePrev[],
    readonly deadline?: string,
    readonly squareFeet?: number,
    readonly vendorQuote?: any //TODO: check this
  ) {}

  toDomain(): CreatedProject {
    const quotesPrev = this.projectQuote ? this.projectQuote.map((apiQuote) => apiQuote.toDomain()) : [];
    return new CreatedProject(
      this.id,
      this.title,
      this.companyName,
      this.typeOfWork,
      this.status,
      DateFormatter.fromYYYYMMDD(this.estimatedStartDate),
      DateFormatter.fromYYYYMMDD(this.estimatedEndDate),
      this.location,
      { min: +this.budget.min, max: +this.budget.max },
      {
        lat: this.locationCoordinates?.lat
          ? +this.locationCoordinates.lat
          : undefined,
        lng: this.locationCoordinates?.lng
          ? +this.locationCoordinates.lng
          : undefined,
      },
      this.siteSupervisorId.toString(),
      this.siteSupervisorName,
      this.billingContactId.toString(),
      this.billingContactName,
      this.scope,
      Boolean(this.materialBeProvided),
      this.relevantDocuments,
      [
        "https://jsoncompare.org/LearningContainer/SampleFiles/Video/MP4/Sample-MP4-Video-File-for-Testing.mp4",
      ], //TODO: get video urls from backend
      this.blueprintDocuments,
      quotesPrev,
      this.deadline ? DateFormatter.fromYYYYMMDD(this.deadline) : undefined,
      this.squareFeet
    );
  }

  static fromJSON(json: any): ApiCreatedProject {
    json.projectQuote = Array.isArray(json.projectQuote) ? json.projectQuote.map((quote: any) =>
      ApiQuotePrev.fromJSON(quote)
    ) : [];
    return Object.setPrototypeOf(
      json,
      ApiCreatedProject.prototype
    ) as ApiCreatedProject;
  }
}
