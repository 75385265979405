/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from "@emotion/react";
import React from "react";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";

type Props = {
  customCss?: Interpolation<Theme>;
  children?: React.ReactNode;
};

const Card = ({ customCss, children }: Props) => {
  return <div css={[styles.mainContainer, customCss]}>{children}</div>;
};

const styles: StyleType = {
  mainContainer: css({
    backgroundColor: colors.pureWhite,
    borderRadius: "8px",
    boxShadow: "0px 2px 1px rgba(80, 92, 117, 0.05);",
  }),
};

export default Card;
