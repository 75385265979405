import { User } from "src/domain/entities";
import { AuthResponse } from "src/domain/entities/AuthResponse";
import { IAuthRepository } from "src/domain/repositories";
import { IUserRepository } from "src/domain/repositories/IUserRepository";
import { DataResponse } from "src/domain/utils";

export class LoginUseCase {
  constructor(
    private authRepository: IAuthRepository,
    private userRepository: IUserRepository
  ) {}

  async execute(email: string, password: string, saveData: boolean = true): DataResponse<User> {
    const { user, credentials } = (await this.authRepository.login(
      email,
      password
    )) as AuthResponse;

    if(saveData) {
      await this.userRepository.setSavedUser(user);
    }
    
    await this.authRepository.setAuthData(credentials, saveData);

    return user;
  }
}
