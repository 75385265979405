import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { SidebarContainer } from "src/presentation/components/Sidebar";
import { useAuth } from "src/presentation/di";

import { useChatSupport } from "src/presentation/di";

import {
  faHouse,
  faCircleDollar,
  faUserGroup,
  faMessage,
} from "@fortawesome/pro-regular-svg-icons";

import QuoteProjectNavigation from "./QuoteProjectNavigation";
import CreatedProjectNavigation from "./CreatedProjectNavigation";
import ProfileNavigation from "./ProfileNavigation";
import InvoiceListScreenView from "../pages/InvoiceListScreen/InvoiceListScreenView";

const routes = {
  MY_PROJECTS: "my-projects",
  QUOTE_PROJECTS: "quote-projects",
  PROFILE: "profile",
  INVOICES: "invoices"
};

const links = [
  { title: "My Projects", path: `/${routes.MY_PROJECTS}`, icon: faHouse },
  {
    title: "Projects To Quote",
    path: `/${routes.QUOTE_PROJECTS}`,
    icon: faUserGroup,
  },
  { title: "Invoices", path: "/invoices", icon: faCircleDollar },
  // { title: "Crew members", path: "/crew-members", icon: faUserGroup },
  // { title: "Messages", path: "/messages", icon: faMessage },
];

const HomeNavigation = () => {
  const { logout, onInit } = useAuth();
  const location = useLocation();

  useEffect(() => {
    onInit();
  }, [])

  return (
    <SidebarContainer
      onLogout={logout}
      currentPath={location.pathname}
      links={links}
    >
      <Routes>
        <Route
          path={`/${routes.MY_PROJECTS}/*`}
          element={<CreatedProjectNavigation />}
        />
        <Route
          path={`/${routes.QUOTE_PROJECTS}/*`}
          element={<QuoteProjectNavigation />}
        />
        <Route path={`/${routes.PROFILE}/*`} element={<ProfileNavigation />} />
        <Route path={`/${routes.INVOICES}/*`} element={<InvoiceListScreenView />} />
        {/* <Route path="/crew-members" element={<div>Crew members</div>} /> */}
        {/* <Route path="/messages" element={<div>Messages</div>} /> */}
        <Route path="*" element={<Navigate to="my-projects" />} />
      </Routes>
    </SidebarContainer>
  );
};

export default HomeNavigation;
