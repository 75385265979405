import React, { useEffect, useState } from 'react'
import { CustomError, Project, ProjectPrev } from 'src/domain'
import { projectInteractor } from 'src/presentation/di'

const QuoteProjectListScreenViewModel = () => {
    const [quoteProjects, setQuoteProjects] = useState<ProjectPrev[]>([])
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<CustomError | null>(null)

    useEffect(() => {
        getQuoteProjects()
    }, [])

    const getQuoteProjects = async () => {
        try {
            setIsLoading(true);
            const quoteProjects = await projectInteractor.getQuoteProjects() as Project[]
            setQuoteProjects(quoteProjects)
        } catch (error) {
            setError(error as CustomError);
        } finally {
            setIsLoading(false);
        }
    }

  return {
    quoteProjects,
    isLoading,
    error,
    isError: !!error,
  }
}

export default QuoteProjectListScreenViewModel