/** @jsxImportSource @emotion/react */
import React from 'react'
import TextField, { Props as TextFieldProps } from './TextField'
import { css } from '@emotion/react';
import { StyleType } from 'src/presentation/types';

type Props = {
  charLimit?: number;
  children: string;
} & Omit<TextFieldProps, "children">

const LimitedTextField = ({ charLimit = 20, children, ...props }: Props) => {
  if(children.length > charLimit) return (
    <div css={styles.text}>
      <TextField {...props}>
        {`${children.slice(0, charLimit)}...`}
      </TextField>
      <span css={styles.toolTipText}>{children}</span>
    </div>
  )

  return (
    <TextField {...props}>
      {children}
    </TextField>
  )
}

const styles: StyleType = {
  text: css({
    position: "relative",
    display: "inline-block",
    borderBottom: "1px dotted black",

    ":hover": {
      "span": {
        visibility: "visible"
      }
    }
  }),
  toolTipText: css({
    visibility: "hidden",
    backgroundColor: "#555",
    color: "#fff",
    textAlign: "center",
    padding: "5px",
    borderRadius: "6px",
    position: "absolute",
    zIndex: 1,
    bottom: "125%",
    right: "50%",
    transform: "translateX(50%)"
  })
}

export default LimitedTextField