import { ApiCompanyContactPrev } from "src/data/models/project/ApiCompanyContactPrev";
import { DataResponse, ICompanyRepository } from "src/domain";
import { CompanyContactPrev } from "src/domain/entities/CompanyContactPrev";
import { ICompanyRemoteDataSource } from "./ICompanyRemoteDataSource";

export class CompanyRepositoryImp implements ICompanyRepository {
    constructor(
        private companyRemoteDataSource: ICompanyRemoteDataSource
    ){}

    async getCompanyContactsPrev(): DataResponse<CompanyContactPrev[]> {
        const apiCompanyContacts = await this.companyRemoteDataSource.getCompanyContactsPrev() as ApiCompanyContactPrev[];
        const companycontactsPrev =  apiCompanyContacts.map(apiContact => apiContact.toDomain());
        return companycontactsPrev;
    }
}