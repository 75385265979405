import { ApiFileData } from "src/data/models/file/ApiFileData";
import { DataResponse, IFileRepository } from "src/domain";
import { FileData } from "src/domain/entities/FileData";
import { IFileRemoteDataSource } from "./IFileRemoteDataSource";
import { ApiFileDataWithName } from "src/data/models/file/ApiFileDataWithName";

export class FileRepositoryImp implements IFileRepository {
    constructor(
        private fileRemoteDataSource: IFileRemoteDataSource
    ){}

    async uploadFileInBase64(base64: string, fileName?: string): DataResponse<FileData> {
        const apiFileData = await this.fileRemoteDataSource.uploadFileInBase64(base64, fileName) as ApiFileDataWithName;
        return apiFileData.toDomain()
    }
}