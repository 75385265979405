import React from "react";
import { BrowserRouter } from "react-router-dom";
import MainNavigation from "src/presentation/navigation/MainNavigation";

import { UserProvider } from "src/presentation/di";
import { ChatSupportProvider } from "src/presentation/di";

const App = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <ChatSupportProvider>
          <MainNavigation />
        </ChatSupportProvider>
      </UserProvider>
    </BrowserRouter>
  );
};

export default App;
