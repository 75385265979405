import React from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import ProfileNavbarContainer from "../components/ProfileNavbarContainer/ProfileNavbarContainer";
import UserProfileScreenView from "../pages/UserProfileScreen/UserProfileScreenView";
import PageWithTitle from "../hoc/PageWithTitle";

const routes = {
  USER_PROFILE: "user",
  COMPANY_PROFILE: "company",
};

const links = [
  { path: routes.USER_PROFILE, title: "USER PROFILE" },
];

const ProfileNavigation = () => {
  const location = useLocation();

  return (
    <ProfileNavbarContainer links={links} currentPath={location.pathname.split("/").at(-1)}>
      <Routes>
        <Route
          path={`/${routes.USER_PROFILE}/*`}
          element={
            <PageWithTitle title="User profile - Ubild">
              <UserProfileScreenView/>
            </PageWithTitle>
        }
        />
        <Route
          path={`/${routes.COMPANY_PROFILE}/*`}
          element={
            <PageWithTitle title="Company profile - Ubild">
              <div>COMPANY PROFILE</div>
            </PageWithTitle>
          }
        />
        <Route path="*" element={<Navigate to="user" />} />
      </Routes>
    </ProfileNavbarContainer>
  );
};

export default ProfileNavigation;
