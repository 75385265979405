import React from 'react'
import { useParams } from 'react-router-dom'
import { useAuth } from 'src/presentation/di';

const CheckEmailScreenViewModel = () => {
  const { email } = useParams();

  const {onSendResetPasswordEmail} = useAuth();

  const resendEmailHandler = () => {
    try {
      onSendResetPasswordEmail(email!)
    } catch (error) {
      
    }
  }

  const openGmailHandler = () => {
    window.open(`https://mail.google.com/`);
  }

  return {
    email,
    onOpenEmail: openGmailHandler,
    onResendEmail: resendEmailHandler,
  }
}

export default CheckEmailScreenViewModel