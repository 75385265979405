import {
  CustomError,
  DataResponse,
  errorCodeEnum,
  errorMessageEnum,
  IHttpClient,
} from "src/domain/utils";
import { ApiAuthReponse, IApiAuthReponse, IApiResetPasswordResponse } from "src/data/models/auth";
import { IAuthRemoteDataSource } from "./IAuthRemoteDataSource";

import { LOGIN_URL, RESET_PASSWORD_URL } from "src/data/apiUrls";

export class AuthRemoteDataSourceImp implements IAuthRemoteDataSource {
  constructor(private httpClient: IHttpClient) {}

  async login(email: string, password: string): DataResponse<ApiAuthReponse> {
    try {
      const res = (await this.httpClient.post<IApiAuthReponse>(LOGIN_URL, {
        email,
        password,
      })) as IApiAuthReponse;
      const authReponse = new ApiAuthReponse(res);

      return authReponse;
    } catch (error: any) {
      if (error.response.status === 401) {
        throw new CustomError(
          errorCodeEnum.UNAUTHORIZED,
          errorMessageEnum.INVALID_CREDENTIALS
        );
      }
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.SERVER_ERROR
      );
    }
  }

  async sendResetPasswordEmail(email: string): DataResponse<void> {
    try {
      const res = (await this.httpClient.post<IApiResetPasswordResponse>(RESET_PASSWORD_URL, {
        email,
      })) as IApiResetPasswordResponse;
      
      if(res.message === "Invalid user.") throw new CustomError(errorCodeEnum.NOT_FOUND, errorMessageEnum.INVALID_EMAIL);

      return;
    } catch (error: any) {
      if (error instanceof CustomError) {
        throw error;
      }

      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.SERVER_ERROR
      );
    }
  }
}
