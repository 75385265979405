import React, { useState } from "react";
import { DateFormatter } from "src/domain";
import { FileUtils } from "src/presentation/utils/FileUtils";

const useInputs = () => {
  //LOCATION
  const [selectedJobSiteId, setSelectedJobSiteId] = useState<null | string>(
    null
  );
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeMatchError, setPostalCodeMatchError] = useState(false);
  const [province, setProvince] = useState("");
  const [streetView, setStreetView] = useState<File | undefined>(undefined);
  const [typeOfWork, setTypeOfWork] = useState("");
  //SCOPE OF WORK
  const [projectType, setProjectType] = useState("");
  const [scopeOfWork, setScopeOfWork] = useState("");
  const [budget, setBudget] = useState({ min: 0, max: 9999 });
  const [squareFeet, setSquareFeet] = useState(0);
  const [materialsBeProvided, setMaterialsBeProvided] = useState<
    boolean | undefined
  >(undefined);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [quoteDeadline, setQuoteDeadline] = useState(new Date());
  //UPLOADS
  const [uploadDocuments, setUploadDocuments] = useState<File[]>([]);
  const [uploadMedia, setUploadMedia] = useState<File[]>([]);
  //ASSIGN CONTACT
  const [onSiteContact, setOnSiteContact] = useState("");
  const [billingContact, setBillingContact] = useState("");


  //LOCATION
  const changeAddressHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const changePostalCodeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCode(e.target.value);
  };

  const changeProvinceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProvince(e.target.value);
  };

  const changeStreetViewHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = FileUtils.getFilesFromInput(e);

    if (files.length > 0) {
      const file = files[0];
      const fileType = file.type.split("/")[0];

      // Allow only images
      if (fileType !== "image") {
        alert("Only images are allowed");
        return;
      }

      setStreetView(file);
    }
  };

  const changeSelectedJobSite = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedJobSiteId(e?.target.value ?? null);
  };

  const removeStreetViewFileHandler = (_fileName: string) => {
    setStreetView(undefined);
  };

  const changeTypeOfWorkHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTypeOfWork(e.target.value);
  };

  const clearLocationInputsHandler = () => {
    setAddress("");
    setPostalCode("");
    setProvince("");
    setStreetView(undefined);
    setSelectedJobSiteId(null);
    setPostalCodeMatchError(false);
  };

  //SCOPE OF WORK
  const changeStartDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const today = new Date();
    const newStartDate = DateFormatter.fromYYYYMMDD(e.target.value);

    if (newStartDate < today)
      return alert("Start date must be grater than today");

    setStartDate(newStartDate);
  };

  const changeEndDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const today = new Date();
    const newEndDate = DateFormatter.fromYYYYMMDD(e.target.value);

    if (newEndDate < today) return alert("End date must be grater than today");

    setEndDate(newEndDate);
  };

  const changeDeadlineDateHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const today = new Date();
    const newDeadlineDate = DateFormatter.fromYYYYMMDD(e.target.value);

    if (newDeadlineDate < today)
      return alert("Deadline date must be grater than today");

    setQuoteDeadline(newDeadlineDate);
  };
  const changeBudgetHandler = (
    field: "min" | "max",
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBudget((prev) => ({ ...prev, [field]: +e.target.value }));
  };
  const changeSquareFeetHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSquareFeet(+e.target.value);
  };
  const changeMaterialsBeProvidedHandler = (provided: boolean) => {
    setMaterialsBeProvided(provided);
  };
  const changeProjectTypeHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setProjectType(e.target.value);
  };
  const changeScopeOfWorkHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setScopeOfWork(e.target.value);
  };

  //UPLOADS
  const changeDocumentsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = FileUtils.getFilesFromInput(e);

    if (
      files.findIndex((newFile) =>
        uploadDocuments.some((file) => file.name === newFile.name)
      ) !== -1
    ) {
      alert("File with same name already exists");
      return;
    }

    setUploadDocuments((prev) => [...prev, ...files]);
  };

  const changeMediaHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = FileUtils.getFilesFromInput(e);

    if (
      files.findIndex((newFile) =>
        uploadMedia.some((file) => file.name === newFile.name)
      ) !== -1
    ) {
      alert("File with same name already exists");
      return;
    }

    setUploadMedia((prev) => [...prev, ...files]);
  };

  const removeDocumentHandler = (fileName: string) => {
    setUploadDocuments((prev) => prev.filter((file) => file.name !== fileName));
  };

  const removeMediaHandler = (fileName: string) => {
    setUploadMedia((prev) => prev.filter((file) => file.name !== fileName));
  };

  //ASSIGN CONTACT
  const changeOnSiteContactHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setOnSiteContact(e.target.value);
  };
  const changeBillingContactHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBillingContact(e.target.value);
  };
  return {
    values: {
      //LOCATION
      selectedJobSiteId,
      address,
      postalCode,
      postalCodeMatchError,
      province,
      streetView,
      typeOfWork,

      //SCOPE OF WORK
      projectType,
      scopeOfWork,
      budget,
      squareFeet,
      materialsBeProvided,
      startDate,
      endDate,
      quoteDeadline,

      //UPLOADS
      uploadDocuments,
      uploadMedia,

      //ASSIGN CONTACT
      onSiteContact,
      billingContact,
    },
    onChange: {
      //LOCATION
      onChangeSelectedJobSite: changeSelectedJobSite,
      onChangeAddress: changeAddressHandler,
      onChangePostalCode: changePostalCodeHandler,
      onChangePostalCodeMatchError: setPostalCodeMatchError,
      onChangeProvince: changeProvinceHandler,
      onChangeStreetViewFile: changeStreetViewHandler,
      onRemoveStreetViewFile: removeStreetViewFileHandler,
      onChangeTypeOfWork: changeTypeOfWorkHandler,
      onClearLocationInputs: clearLocationInputsHandler,

      //SCOPE OF WORK
      onChangeDeadlineDate: changeDeadlineDateHandler,
      onChangeEndDate: changeEndDateHandler,
      onChangeBudget: changeBudgetHandler,
      onChangeSquareFeet: changeSquareFeetHandler,
      onChangeMaterialsBeProvided: changeMaterialsBeProvidedHandler,
      onChangeProjectType: changeProjectTypeHandler,
      onChangeScopeOfWork: changeScopeOfWorkHandler,
      onChangeStartDate: changeStartDateHandler,

      //UPLOADS
      onChangeDocuments: changeDocumentsHandler,
      onChangeMedia: changeMediaHandler,
      onRemoveDocument: removeDocumentHandler,
      onRemoveMedia: removeMediaHandler,

      //ASSIGN CONTACT
      onChangeOnSiteContact: changeOnSiteContactHandler,
      onChangeBillingContact: changeBillingContactHandler,
    },
  };
};

export default useInputs;
