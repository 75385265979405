import { GetCreatedProjectsPrevUseCase } from "../usecases/project/GetCreatedProjectsPrevUseCase";
import { GetQuoteProjectsPrevUseCase } from "../usecases/project/GetQuoteProjectsPrevUseCase";
import { GetQuoteProjectByIdUseCase } from "../usecases/project/GetQuoteProjectByIdUseCase";
import {
  UploadProjectQuoteUseCase,
  UploadQuoteData,
} from "../usecases/project/UploadProjectQuoteUseCase";
import { GetCreatedProjectByIdUseCase } from "../usecases/project/GetCreatedProjectByIdUseCase";
import { GetProyectTypesUseCase } from "../usecases/project/GetProyectTypesUseCase";
import { CreateProjectUseCase } from "../usecases/project/CreateProjectUseCase";
import { UpdateProjectUseCase } from "../usecases/project/UpdateProjectUseCase";
import { UpdateProjectQuoteUseCase } from "../usecases/project/UpdateProjectQuoteUseCase";

export class ProjectInteractor {
  constructor(
    private getCreatedProjectsPrevUseCase: GetCreatedProjectsPrevUseCase,
    private getCreatedProjectByIdUseCase: GetCreatedProjectByIdUseCase,
    private getQuoteProjectsPrevUseCase: GetQuoteProjectsPrevUseCase,
    private getQuoteProjectByIdUseCase: GetQuoteProjectByIdUseCase,
    private uploadProjectQuoteUseCase: UploadProjectQuoteUseCase,
    private getProyectTypesUseCase: GetProyectTypesUseCase,
    private createProjectUseCase: CreateProjectUseCase,
    private updateProjectUseCase: UpdateProjectUseCase,
    private updateProjectQuoteUseCase: UpdateProjectQuoteUseCase,
  ) {}

  getCreatedProjects() {
    return this.getCreatedProjectsPrevUseCase.execute();
  }

  getCreatedProjectById(id: string) {
    return this.getCreatedProjectByIdUseCase.execute(id);
  }

  getQuoteProjects() {
    return this.getQuoteProjectsPrevUseCase.execute();
  }

  getQuoteProjectById(id: string) {
    return this.getQuoteProjectByIdUseCase.execute(id);
  }

  uploadProjectQuote(projectId: string, quoteData: UploadQuoteData) {
    return this.uploadProjectQuoteUseCase.execute(projectId, quoteData);
  }

  updateProjectQuote(quoteId: string, quoteData: UploadQuoteData) {
    return this.updateProjectQuoteUseCase.execute(quoteId, quoteData);
  }

  getProyectTypes() {
    return this.getProyectTypesUseCase.execute();
  }

  createProject(
    jobSiteId: string,
    projectType: string,
    scopeOfWork: string,
    budget: { min: number, max: number },
    materialsBeProvided: boolean,
    startDate: Date,
    endDate: Date,
    deadline: Date,
    squareFeet: number
  ) {
    return this.createProjectUseCase.execute(
      jobSiteId,
      projectType,
      scopeOfWork,
      budget,
      materialsBeProvided,
      startDate,
      endDate,
      deadline,
      squareFeet
    );
  }

  updateProject(
    projectId: string,
    onSiteContactId?: string,
    billingContactId?: string,
    uploadDocuments?: File[],
    uploadMedia?: File[],
    typeOfWork?: string,
    scope?: string,
    budget?: { min: number, max: number },
    materialBeProvided?: boolean,
    estimatedStartDate?: Date,
    estimatedEndDate?: Date,
    quoteDeadline?: Date,
    jobSiteId?: number,
    title?: string,
    squareFeet?: number
  ) {
    return this.updateProjectUseCase.execute(
      projectId,
      onSiteContactId,
      billingContactId,
      uploadDocuments,
      uploadMedia,
      typeOfWork,
      scope,
      budget,
      materialBeProvided,
      estimatedStartDate,
      estimatedEndDate,
      quoteDeadline,
      jobSiteId,
      title,
      squareFeet,
    );
  }
}
