import { CompanyContactPrev } from "src/domain/entities/CompanyContactPrev";
import { ICompanyRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";

export class GetCompanyContactsPrevUseCase {
    constructor(
        private companyRepository: ICompanyRepository
    ){}

    async execute(): DataResponse<CompanyContactPrev[]> {
        return this.companyRepository.getCompanyContactsPrev();
    } 
}