import { ApiJobSite } from "src/data/models/jobsite/ApiJobSite";
import { DataResponse } from "src/domain";
import { JobSite } from "src/domain/entities/JobSite";
import { IJobSiteRepository } from "src/domain/repositories/IJobSiteRepository";
import { IJobSiteRemoteDataSource } from "./IJobSiteRemoteDataSource";

export class JobSiteRepositoryImp implements IJobSiteRepository {
    constructor(
        private jobSiteRemoteDataSource: IJobSiteRemoteDataSource
    ){}

    async createJobSite(address: string, postalCode: string, province: string, frontImgUrl: string): DataResponse<JobSite> {
        const createdApiJobSite = await this.jobSiteRemoteDataSource.createJobSite(address, postalCode, province, frontImgUrl) as ApiJobSite;
        return createdApiJobSite.toDomain();
    }

    async getJobSites(): DataResponse<JobSite[]> {
        const apiJobSites = await this.jobSiteRemoteDataSource.getJobSites() as ApiJobSite[];
        const jobSites = apiJobSites.map(apiJobSite => apiJobSite.toDomain());
        return jobSites;
    }
} 