import { FileData } from "src/domain/entities/FileData";

export class ApiFileData {
  constructor(
    readonly id: number,
    readonly real_name: string,
    readonly storage_name: string,
    readonly updated_at: string,
    readonly created_at: string
  ) {}

  toDomain(): FileData {
    return new FileData(this.id.toString(), this.real_name, this.storage_name, new Date(this.updated_at), new Date(this.created_at))
  }

  static fromJSON(json: any) {
    return Object.setPrototypeOf(json, ApiFileData.prototype) as ApiFileData;
  }
}
