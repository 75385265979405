/** @jsxImportSource @emotion/react */
import React from "react";
import Icon from "./Icon";
import TextField from "./TextField";

import { StyleType } from "src/presentation/types";
import { css } from "@emotion/react";

import {colors} from 'src/presentation/theme'
import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

type Props = {
  icon: FontAwesomeIconProps['icon'],
  children: React.ReactNode;
  selected?: boolean;
} & React.DetailedHTMLProps<
React.ButtonHTMLAttributes<HTMLButtonElement>,
HTMLButtonElement
>;

const IconWithText = ({ icon, children, selected = false, ...props }: Props) => {
  return (
    <button css={[styles.mainContainer, selected? {backgroundColor: colors.pureBlack} : {}, !selected ? {":hover": {
      backgroundColor: colors.darkGrayTransparent50,
    }} : {}]} {...props}>
      <div css={styles.iconContainer}>
        <Icon icon={icon} size="xl" color={selected ? "white" : "darkGray"} />
      </div>
      <TextField fontWeight="light" fontSize="medium" color={selected ? "white" : "darkGray"}>
        {children}
      </TextField>
    </button>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    gap: "15px",
    alignItems: "center",
    padding: "13px 25px",
    transition: "all 0.2s ease-in-out",
    borderRadius: "8px",
    cursor: "pointer",
    width: "100%",
  }),
  iconContainer: css({
    width: "25px",
  }),
};

export default IconWithText;
