/** @jsxImportSource @emotion/react */
import React from "react";
import useViewModel from "./QuoteProjectListScreenViewModel";

import { StyleType } from "src/presentation/types";
import { css } from "@emotion/react";
import QuoteProjecFieldstInfo from "./components/QuoteProjectFieldsInfo";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import {
  QuoteProjectPrev,
  LoadingCover,
  TextField,
} from "src/presentation/components/UI";
import HeaderContainer from "src/presentation/components/HeaderProfile/HeaderContainer";

type Props = {
  onGoToPreview: (projectId: string) => void;
  onGoToViewProject: (projectId: string) => void;
};

const QuoteProjectListScreenView = ({ onGoToPreview, onGoToViewProject }: Props) => {
  const { quoteProjects, isLoading, error, isError } = useViewModel();

  if (isLoading) return <LoadingCover />;

  if (isError) return <div>{error?.message}</div>;

  return (
    <div css={styles.mainContainer}>
      <HeaderContainer customCss={styles.headerContainer}>
        <TextField fontWeight="bold" customFontSize={32} color="darkGray">
          Available projects
        </TextField>
      </HeaderContainer>

      <div css={styles.projectFieldsInfoContainer}>
        <QuoteProjecFieldstInfo />
      </div>

      <div css={styles.projectsContainer}>
        {quoteProjects.map((project) => (
          <QuoteProjectPrev
            key={project.id}
            project={project}
            onGoToPreview={() => onGoToPreview(project.id)}
            onGoToViewProject={() => onGoToViewProject(project.id)}
          />
        ))}
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    width: "100%",
    height: "max-content",
    minHeight: "100%",
    padding: "2rem",
  }),
  headerContainer: css({
    marginBottom: "50px",
    alignItems: "center",
    [BREAKPOINTS.MEDIUM]: {
      justifyContent: "center",
      marginBottom: "25px",
    },
  }),
  projectsContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  }),
  projectFieldsInfoContainer: css({
    marginBottom: "25px",
    width: "100%",

    [BREAKPOINTS.MEDIUM_LARGE]: {
      display: "none",
    },
  }),
};

export default QuoteProjectListScreenView;
