/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { DateFormatter } from "src/domain/utils/DateFormatter";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";
import ActionButton from "./ActionButton";
import CircularImage from "./CircularImage";
import CardFieldContainer from "./CardFieldContainer";
import TextField from "./TextField";
import { QuotePrev as Quote } from "src/domain/entities/QuotePrev";
import { StringFormatter } from "src/presentation/utils/StringFormatter";
import { faCircleCheck, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import Icon from "./Icon";
import LimitedTextField from "./LimitedTextField";

type Props = {
  quote: Quote;
  onMessage: () => void;
};

const QuotePrev = ({ quote, onMessage }: Props) => {
  return (
    <div css={styles.mainContainer}>
      <div css={[styles.centeredFlexContainer, styles.projectTypeContainer]}>
        <CircularImage size="40px" defaultBackgroundColor="lightGreen" />

        <div>
          <TextField fontWeight="regular" fontSize="small">
            {quote.title}
          </TextField>

          {/* TODO: Get this data from backend */}
          {/* <TextField fontWeight="light" fontSize="small">
            <Icon color="darkGray" icon={faThumbsUp}/> 95% &nbsp; <Icon color="darkGray" icon={faCircleCheck}/> 70 Jobs
          </TextField> */}
        </div>
      </div>

      <CardFieldContainer label="price" flex={1.5 / FLEX_TOTAL}>
        <TextField fontWeight="light" fontSize="small" color="darkGrayText">
          {`$${StringFormatter.toNumberWithCommas(quote.price)} CAD`}
        </TextField>
      </CardFieldContainer>

      <CardFieldContainer label="estimated start" flex={2 / FLEX_TOTAL}>
        <TextField fontWeight="light" fontSize="small" color="darkGrayText">
          {DateFormatter.toMMMdoYYYY(quote.projectStartDate)}
        </TextField>
      </CardFieldContainer>

      <CardFieldContainer label="estimated end" flex={2 / FLEX_TOTAL}>
        <TextField fontWeight="light" fontSize="small" color="darkGrayText">
          {DateFormatter.toMMMdoYYYY(quote.projectEndDate)}
        </TextField>
      </CardFieldContainer>

      <CardFieldContainer label="payment terms" flex={2 / FLEX_TOTAL}>
        <LimitedTextField charLimit={15} fontWeight="light" fontSize="small" color="darkGrayText">
          {quote.paymentTerms + "asdasdsad sdfsdfs sdfsdf sd sdf sfsfdf sadad"}
        </LimitedTextField>
      </CardFieldContainer>

      <div css={[styles.centeredFlexContainer, styles.buttonsContainer]}>
        {/*<ActionButton onClick={onMessage} color="secondary">
          Message
        </ActionButton> */}
        <a href={quote.documents[0] ?? undefined} target="_blank">
          <ActionButton color="primary">
            View
          </ActionButton>
        </a>
      </div>
    </div>
  );
};

const FLEX_TOTAL = 12;

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    padding: "15px 20px",
    backgroundColor: colors.pureWhite,
    borderRadius: "8px",
    boxShadow: "0px 2px 1px rgba(80, 92, 117, 0.05);",
    width: "100%",
    gap: "10px",

    [BREAKPOINTS.MEDIUM_LARGE]: {
      flexDirection: "column",
    },
  }),
  centeredFlexContainer: css({
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  }),
  projectTypeContainer: css({
    flex: 2.5 / FLEX_TOTAL,
    gap: "28px",
    flexShrink: 1,
  }),
  buttonsContainer: css({
    flex: 2 / FLEX_TOTAL,
    gap: "12px",
    justifyContent: "end",
    [BREAKPOINTS.MEDIUM_LARGE]: {
      justifyContent: "center",
    },
  }),
};

export default QuotePrev;
