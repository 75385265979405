/** @jsxImportSource @emotion/react */
import React, { CSSProperties } from "react";
import { colors, fonts } from "src/presentation/theme";

export type Props = {
  color?: keyof typeof colors;
  customColor?: string,
  alignCenter?: boolean;
  fontSize?: keyof typeof fonts.sizes;
  fontWeight?: keyof typeof fonts.weight;
  customFontSize?: CSSProperties["fontSize"];
  children?: React.ReactNode;
};

const TextField = ({
  color = "pureBlack",
  customColor,
  alignCenter,
  fontSize = "medium",
  fontWeight = "regular",
  customFontSize,
  children,
}: Props) => {
  const textColor = customColor ?? colors[color];
  const textSize = customFontSize ? customFontSize : fonts.sizes[fontSize];
  const textWeight = fontWeight ? fonts.weight[fontWeight] : undefined;
  return (
    <p
      css={{
        color: textColor,
        textAlign: alignCenter ? "center" : "start",
        fontSize: textSize,
        fontWeight: textWeight,
        transition: "all 0.2s ease-in-out",
      }}
    >
      {children}
    </p>
  );
};

export default TextField;
