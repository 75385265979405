/** @jsxImportSource @emotion/react */
import React from "react";
import { ActionButtonWithIcon, InputField } from "src/presentation/components/UI";
import DeleteableItemList from "src/presentation/components/UI/DeleteableItemList";
import InputFile from "src/presentation/components/UI/InputFile";
import { ExpressionMatcher } from "src/presentation/utils/ExpressionMatcher";

import FormStepContainer from "./FormStepContainer";
import { StyleType } from "src/presentation/types";
import { css } from "@emotion/react";

import { faRotateLeft } from "@fortawesome/pro-solid-svg-icons";
import useFormContext from "../context/useFormContext";

type Props = {
  onGoToSelectJobSite: () => void;
};

const CreateJobSiteForm = ({
  onGoToSelectJobSite,
}: Props) => {
  const {fieldValues, onChangeValues} = useFormContext();

  return (
    <FormStepContainer>
      <InputField
        value={fieldValues.address}
        onChange={onChangeValues.onChangeAddress}
        height={40}
        label="Address"
      />
      <InputField
        value={fieldValues.postalCode}
        onChange={onChangeValues.onChangePostalCode}
        height={40}
        label="Postal / Zip Code"
        errorLabel="Invalid postal code"
        errorMatcher={fieldValues.postalCodeMatchError}
        onChangeErrorMatcher={onChangeValues.onChangePostalCodeMatchError}
        expressionMatcher={ExpressionMatcher.matchCanadaZipCode}
      />
      <InputField
        value={fieldValues.province}
        onChange={onChangeValues.onChangeProvince}
        height={40}
        label="Province / State"
      />
      <InputFile
        accept="image/png, image/jpg, image/jpeg, image/webp"
        label="Streetview"
        innerLabel="Image"
        onChange={onChangeValues.onChangeStreetViewFile}
      />
      <DeleteableItemList
        items={fieldValues.streetView ? [fieldValues.streetView.name] : []}
        onRemoveItem={onChangeValues.onRemoveStreetViewFile}
      />

      <div css={styles.buttonContainer}>
        <ActionButtonWithIcon icon={faRotateLeft} onClick={onGoToSelectJobSite} color="secondary">
          Back to select Job Site
        </ActionButtonWithIcon>
      </div>
    </FormStepContainer>
  );
};

const styles: StyleType = {
  buttonContainer: css({
    width: "fit-content",
    // margin: "0 auto",
  }),
};

export default CreateJobSiteForm;
