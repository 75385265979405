/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import {
  Button,
  Card,
  LoadingCover,
} from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";
import useFormContext from "../context/useFormContext";
import ScopeOfWorkForm from "src/presentation/components/CreateProjectForm/ScopeOfWorkForm";

type Props = {
  onClose: () => void;
};

const UpdateProjectInfoModal = ({ onClose }: Props) => {
  const {
    formData,
    isLoadingForm,
    isLoadingUpdateInfo,
    fieldValues,
    onChangeValues,
    onSubmitInfoForm,
  } = useFormContext();

  const submitHandler = async () => {
    await onSubmitInfoForm();
    onClose();
  }

  if (isLoadingForm) return <Card customCss={styles.loadingCoverContainer}><LoadingCover /></Card>

  return (
    <div css={styles.mainContainer}>
      <Card customCss={styles.formContainer}>
        <ScopeOfWorkForm
          availableProjectTypes={formData.availableProjectTypes}
          fieldValues={fieldValues}
          onChangeValues={onChangeValues}
        />
      </Card>
      <div css={styles.buttonsContainer}>
        <Button
          onClick={onClose}
          customCss={styles.actionButton}
          height={50}
          disabled={isLoadingForm || isLoadingUpdateInfo}
          color="error"
        >
          CANCEL
        </Button>
        <Button
          loading={isLoadingUpdateInfo}
          onClick={submitHandler}
          customCss={styles.actionButton}
          height={50}
          disabled={isLoadingForm || isLoadingUpdateInfo}
        >
          UPDATE
        </Button>
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    height: "90%",
    width: "90%",
    maxWidth: "600px",
    gap: "10px",
    marginBottom: "50px",
  }),
  formContainer: css({
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "40px",
  }),
  buttonsContainer: css({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
  }),
  actionButton: css({
    width: "50%",
    maxWidth: "215px",
  }),
  loadingCoverContainer: css({
    padding: "20%",
  })
};

export default UpdateProjectInfoModal;
