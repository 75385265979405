import { FileData } from "src/domain/entities/FileData";
import { IFileRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";
import { FileUtils } from "src/domain/utils";

export class UploadFileUseCase {
  constructor(
    private fileRepository: IFileRepository
  ) {}

  async execute(file: File): DataResponse<FileData> {
    try {
      const base64 = await FileUtils.fileToBase64(file) as string;
      const fileBase64Array = base64.split("/");
      fileBase64Array[0] = "data:file"
      const fileBase64 = fileBase64Array.join("/");
  
      const fileData = await this.fileRepository.uploadFileInBase64(fileBase64, file.name) as FileData;
      return fileData;
    } catch (error) {
      console.log({error})
      throw error;
    }
  }
}
