/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import {
  Button,
  Card,
  InputField,
  LoadingCover,
  TextField,
} from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";

import useViewModel from "./UserProfileScreenViewModel";

const UserProfileScreenView = () => {
  const { inputs, inputErrors, loading, loadingSubmit, error, onSubmit } =
    useViewModel();

  return (
    <form css={styles.mainContainer} onSubmit={onSubmit}>
      <Card customCss={styles.cardContainer}>
        {loading ? (
          <LoadingCover/>
        ) : (
          <>
            <TextField color="darkGrayText" fontSize="medium">
              Edit Profile
            </TextField>
            <InputField
              {...inputs.firstName}
              error={inputErrors.firstName}
              id="firstName"
              label="First name"
              height={40}
            />
            <InputField
              {...inputs.lastName}
              error={inputErrors.lastName}
              id="lastName"
              label="Last name"
              height={40}
            />
            <InputField
              {...inputs.email}
              error={inputErrors.email}
              id="email"
              label="Email address"
              height={40}
              type="email"
            />
            <InputField
              {...inputs.phoneNumber}
              error={inputErrors.phoneNumber}
              id="phoneNumber"
              label="Phone number"
              height={40}
              type="tel"
            />
          </>
        )}
      </Card>
      <Button loading={loadingSubmit} disabled={loading || loadingSubmit} margin="0 0 0 auto" height={50} width={215}>
        UPDATE
      </Button>
    </form>
  );
};

const styles: StyleType = {
  mainContainer: css({
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  }),
  cardContainer: css({
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "30px 40px",
    gap: "20px",
  }),
};

export default UserProfileScreenView;
