export class StringFormatter {
    static limitedString(str: string, limit: number): string {
        if (str.length > limit) {
            return str.substring(0, limit) + "...";
        }
        return str;
    }

    static toNumberWithCommas(number: number): string {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}