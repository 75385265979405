export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const postalCodeRegex = /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ -]?\d[ABCEGHJKLMNPRSTVXY]\d$/i;

export class ExpressionMatcher {
    static matchEmail(email: string): boolean {
        return emailRegex.test(email);
    }
    
    static matchCanadaZipCode(zipCode: string): boolean {
        return postalCodeRegex.test(zipCode);
    }
}