import { Project } from "src/domain/entities";
import { QuoteProject } from "src/domain/entities/QuoteProject";
import { IProjectRepository } from "src/domain/repositories/IProjectRepository";
import { DataResponse } from "src/domain/utils";

export class GetQuoteProjectByIdUseCase {
    constructor(
        private projectRepository: IProjectRepository,
    ) {}

    async execute(id: string): DataResponse<QuoteProject> {
        const project = await this.projectRepository.getQuoteProjectById(id) as QuoteProject;
        return project;
    }
}