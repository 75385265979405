import { User } from "src/domain/entities";
import { IUserRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";

export class GetSavedUserUseCase {
    constructor(
        private userRepository: IUserRepository,
    ){}

    async execute(): DataResponse<User | null> {
        const user = await this.userRepository.getSavedUser();
        return user;
    }
}