/** @jsxImportSource @emotion/react */
import React from "react";
import FormStepContainer from "../../pages/CreateProjectScreen/components/FormStepContainer";
import { DropDownMenu } from "src/presentation/components/UI";
import useFormContext from "../../pages/CreateProjectScreen/context/useFormContext";
import { OptionType } from "../UI/DropDownMenu";

type Props = {
  availableOnSiteContacts: OptionType[],
  availableBillingContact: OptionType[],
  fieldValues: {
    onSiteContact: string,
    billingContact: string,
  },
  onChangeValues: {
    onChangeOnSiteContact: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    onChangeBillingContact: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  }
}

const AssignContactsForm = ({availableBillingContact, availableOnSiteContacts, fieldValues, onChangeValues}: Props) => {

  return (
    <FormStepContainer>
      <DropDownMenu
        label="On-site contact"
        defaultOptionLabel="Select on-site contact..."
        value={fieldValues.onSiteContact}
        onChange={onChangeValues.onChangeOnSiteContact}
        options={availableOnSiteContacts}
      />
      <DropDownMenu
        label="Billing contact"
        defaultOptionLabel="Select billing contact..."
        value={fieldValues.billingContact}
        onChange={onChangeValues.onChangeBillingContact}
        options={availableBillingContact}
      />
    </FormStepContainer>
  );
};

export default AssignContactsForm;
