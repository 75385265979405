import React from "react";
import useFormContext from "../context/useFormContext";
import ScopeOfWork from "src/presentation/components/CreateProjectForm/ScopeOfWorkForm";

const ScopeOfWorkStep = () => {
  const { data, fieldValues, onChangeValues } = useFormContext();

  return (
    <ScopeOfWork
      availableProjectTypes={data.availableProjectTypes}
      fieldValues={fieldValues}
      onChangeValues={onChangeValues}
    />
  );
};

export default ScopeOfWorkStep;
