/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";

import { ReactComponent as CheckIcon } from "src/presentation/assets/icons/check-icon.svg";
import { Button, TextField } from "src/presentation/components/UI";

type Props = {
  onBackToDashboard: () => void;
}

const ProjectCreatedSuccesfully = ({onBackToDashboard}: Props) => {
  return (
    <div css={styles.mainContainer}>
      <div css={styles.checkContainer}>
        <CheckIcon height={185} width={185} />
      </div>
      <div css={styles.textContainer}>
        <TextField
          alignCenter
          fontWeight="bold"
          fontSize="mediumLarge"
          color="darkGrayText"
        >
          We are notifying verified subtrades to quote this project.
        </TextField>
        <TextField
          alignCenter
          fontWeight="light"
          fontSize="small"
          color="darkGrayText"
        >
          A UBILD team member will be in touch to work with you through the
          bidding process.
        </TextField>
      </div>
      <Button onClick={onBackToDashboard} margin="auto 0 0 0" width={215} height={50}>BACK TO DASHBOARD</Button>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    height: "100%",
    width: "100%",
    display: "flex",
    padding: "50px",
    gap: "40px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }),
  checkContainer: css({
    display: "grid",
    placeItems: "center",
    marginTop: "auto",
  }),
  textContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  }),
};

export default ProjectCreatedSuccesfully;
