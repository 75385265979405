import React, { useState, useEffect } from "react";
import useForm from "./useForm";
import { CustomError, User } from "src/domain";
import { useAuth, useUserStore, userInteractor } from "src/presentation/di";

const UserProfileScreenViewModel = () => {
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState<CustomError | null>(null);

  const { inputs, errors, onSetFormValues, onSubmit } = useForm();

  const { user, setUser } = useUserStore();

  const getInitialFormValuesHandler = async () => {
    try {
        setLoading(true);

        const formValues = {
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
            phoneNumber: user?.phone
        }

        onSetFormValues(formValues);
    } catch (error) {
        setError(error as CustomError);      
    } finally {
        setLoading(false);
    }
  }

  useEffect(() => {
    getInitialFormValuesHandler();
  }, [])

  const submitHandler = onSubmit(async values => {
    try {
        setLoadingSubmit(true);
        const updatedUser = await userInteractor.updateUserData(values.firstName, values.lastName, values.email, values.phoneNumber) as User;
        setUser(updatedUser);
        alert("Profile updated succesfully.")
    } catch (error) {
        alert("Error updating user data");
    } finally {
        setLoadingSubmit(false);
    }
  })

  return {
    inputs,
    inputErrors: errors,
    loading,
    loadingSubmit,
    error,
    onSubmit: submitHandler,
  };
};

export default UserProfileScreenViewModel;
