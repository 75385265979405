import { IAuthRepository, IUserRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";

export class LogoutUseCase {
    constructor(
        private authRepository: IAuthRepository,
        private userRepository: IUserRepository,
    ){}

    async execute(): DataResponse<void> {
        await this.authRepository.deleteAuthData();
        await this.userRepository.deleteSavedUser();
    }
}