/** @jsxImportSource @emotion/react */
import React from "react";
import HeaderProfile from "./HeaderProfile";
import { StyleType } from "src/presentation/types";
import { css, Interpolation, Theme } from "@emotion/react";
import { BREAKPOINTS } from "src/presentation/breakpoints";

type Props = {
  children?: React.ReactNode;
  customCss?: Interpolation<Theme>
}

const HeaderContainer = ({children, customCss}: Props) => {
  return (
    <div css={[styles.mainContainer, customCss]}>
      {children}

      <HeaderProfile customCss={styles.header} />
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  }),
  header: css({
    [BREAKPOINTS.MEDIUM]: {
      display: "none",
    }
  }),
}

export default HeaderContainer;
