/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState, useEffect } from "react";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";

import { BREAKPOINTS } from "src/presentation/breakpoints";
import Logo from "./components/Logo";
import Links, { LinkType } from "./components/Links";
import Logout from "./components/Logout";

import { faBars, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { CircularImage, Icon, TextField } from "../UI";
import { Link } from "react-router-dom";
import { useAuth } from "src/presentation/di";
import { faBell } from "@fortawesome/pro-regular-svg-icons";

type Props = {
  children?: React.ReactNode;
  links: LinkType[];
  currentPath?: string;
  onLogout?: () => void;
};

const SidebarContainer = ({
  children,
  links,
  currentPath,
  onLogout,
}: Props) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const { userLogged } = useAuth();

  useEffect(() => {
    setMenuOpened(false);
  }, [currentPath]);

  return (
    <div css={styles.mainContainer}>
      <div
        css={[
          styles.sidebarContainer,
          menuOpened ? { maxHeight: "100vh !important" } : {},
        ]}
      >
        <div css={[styles.navbarContainer]}>
          <div css={[styles.sideNavbarContainer, { marginRight: "auto" }]}>
            {/* TODO: see how to pass the link without coupling the component */}
            <Link to="/profile">
              <CircularImage
                size="40px"
                defaultBackgroundColor="primaryLight"
                src={userLogged?.image}
                alt="profile image"
              />
              <TextField alignCenter fontSize="minimal" fontWeight="bold" color="darkGray">Profile</TextField>
            </Link>

            <Link to="/notifications">
              <Icon size="xl" icon={faBell} />
            </Link>
          </div>

          <div css={styles.logoContainer}>
            <Logo />
          </div>

          <div
            onClick={() => setMenuOpened((prev) => !prev)}
            css={[styles.sideNavbarContainer, { marginLeft: "auto" }]}
          >
            <Icon size="2xl" icon={menuOpened ? faXmark : faBars} />
          </div>
        </div>

        <div
          css={[
            styles.menuContainer,
            menuOpened ? { maxHeight: "100% !important" } : {},
          ]}
        >
          <Links links={links} currentPath={currentPath} />

          {onLogout && <Logout onPress={onLogout} />}
        </div>
      </div>

      <div css={styles.innerContainer}>{children}</div>
    </div>
  );
};

const NAVBAR_HEIGHT = "70px";

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    minHeight: "100vh",
    backgroundColor: colors.white,
    [BREAKPOINTS.MEDIUM]: {
      flexDirection: "column",
    },
  }),
  sidebarContainer: css({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "20%",
    gap: "45px",
    backgroundColor: colors.pureWhite,
    padding: "45px 32px",
    alignItems: "center",
    transition: "max-height 0.3s ease-in-out",
    [BREAKPOINTS.LARGE]: {
      width: "25%",
    },
    [BREAKPOINTS.MEDIUM_LARGE]: {
      width: "30%",
    },
    [BREAKPOINTS.MEDIUM]: {
      position: "fixed",
      justifyContent: "center",
      gap: "0px",
      maxHeight: NAVBAR_HEIGHT,
      width: "100%",
      padding: "0px",
      zIndex: 1,
    },
  }),
  navbarContainer: css({
    display: "flex",
    width: "100%",
    [BREAKPOINTS.MEDIUM]: {
      height: NAVBAR_HEIGHT,
      minHeight: NAVBAR_HEIGHT,
      padding: "0 20px",
    },
  }),
  logoContainer: css({
    display: "flex",
    alignItems: "center",
  }),
  sideNavbarContainer: css({
    display: "none",
    width: "15%",
    [BREAKPOINTS.MEDIUM]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "20px",
      zIndex: 1,
    },
  }),
  menuContainer: css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "100%",
    justifyContent: "space-between",
    overflow: "hidden",
    [BREAKPOINTS.MEDIUM]: {
      gap: "50px",
      padding: "0 50px",
    },
  }),
  innerContainer: css({
    width: "100%",
    height: "100vh",
    overflow: "auto",
    [BREAKPOINTS.MEDIUM]: {
      paddingTop: NAVBAR_HEIGHT,
    },
  }),
};

export default SidebarContainer;
