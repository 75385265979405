import { FileData } from "src/domain/entities";
import { JobSite } from "src/domain/entities/JobSite";
import { IJobSiteRepository } from "src/domain/repositories/IJobSiteRepository";
import { UploadFileUseCase } from "../file/UploadFileUseCase";

export class CreateJobSiteUseCase {
    constructor(
        private jobSiteRepository: IJobSiteRepository,
        private uploadFileUseCase: UploadFileUseCase,
    ){}

    async execute(address: string, postalCode: string, province: string, frontImg: File) {
        const frontImgData = await this.uploadFileUseCase.execute(frontImg) as FileData;

        const jobSite = await this.jobSiteRepository.createJobSite(address, postalCode, province, frontImgData.url) as JobSite;
        return jobSite;
    }
}