import React, { useState } from "react";
import { DateFormatter } from "src/domain";

const useInputs = () => {
  //SCOPE OF WORK
  const [projectType, setProjectType] = useState("");
  const [scopeOfWork, setScopeOfWork] = useState("");
  const [budget, setBudget] = useState({ min: 0, max: 9999 });
  const [squareFeet, setSquareFeet] = useState(0);
  const [materialsBeProvided, setMaterialsBeProvided] = useState<
    boolean | undefined
  >(undefined);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [quoteDeadline, setQuoteDeadline] = useState(new Date());

  //ASSIGN CONTACT
  const [onSiteContact, setOnSiteContact] = useState("");
  const [billingContact, setBillingContact] = useState("");

  //SCOPE OF WORK
  const changeStartDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const today = new Date();
    const newStartDate = DateFormatter.fromYYYYMMDD(e.target.value);

    if (newStartDate < today)
      return alert("Start date must be grater than today");

    setStartDate(newStartDate);
  };

  const changeEndDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const today = new Date();
    const newEndDate = DateFormatter.fromYYYYMMDD(e.target.value);

    if (newEndDate < today) return alert("End date must be grater than today");

    setEndDate(newEndDate);
  };

  const changeDeadlineDateHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const today = new Date();
    const newDeadlineDate = DateFormatter.fromYYYYMMDD(e.target.value);

    if (newDeadlineDate < today)
      return alert("Deadline date must be grater than today");

    setQuoteDeadline(newDeadlineDate);
  };
  const changeBudgetHandler = (
    field: "min" | "max",
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBudget((prev) => ({ ...prev, [field]: +e.target.value }));
  };
  const changeSquareFeetHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSquareFeet(+e.target.value);
  };
  const changeMaterialsBeProvidedHandler = (provided: boolean) => {
    setMaterialsBeProvided(provided);
  };
  const changeProjectTypeHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setProjectType(e.target.value);
  };
  const changeScopeOfWorkHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setScopeOfWork(e.target.value);
  };

  //ASSIGN CONTACT
  const changeOnSiteContactHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setOnSiteContact(e.target.value);
  };
  const changeBillingContactHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBillingContact(e.target.value);
  };

  return {
    values: {
      //SCOPE OF WORK
      projectType,
      scopeOfWork,
      budget,
      squareFeet,
      materialsBeProvided,
      startDate,
      endDate,
      quoteDeadline,

      //ASSIGN CONTACT
      onSiteContact,
      billingContact,
    },
    onChange: {
      //SCOPE OF WORK
      onChangeDeadlineDate: changeDeadlineDateHandler,
      onChangeEndDate: changeEndDateHandler,
      onChangeBudget: changeBudgetHandler,
      onChangeSquareFeet: changeSquareFeetHandler,
      onChangeMaterialsBeProvided: changeMaterialsBeProvidedHandler,
      onChangeProjectType: changeProjectTypeHandler,
      onChangeStartDate: changeStartDateHandler,
      onChangeScopeOfWork: changeScopeOfWorkHandler,

      //ASSIGN CONTACT
      onChangeOnSiteContact: changeOnSiteContactHandler,
      onChangeBillingContact: changeBillingContactHandler,
    },
    onReset: {
      onResetDeadlineDate: (value: Date) => setQuoteDeadline(value),
      onResetStartDate: (value: Date) => setStartDate(value),
      onResetEndDate: (value: Date) => setEndDate(value),
      onResetBudget: (value: {min: number, max: number}) => setBudget(value),
      onResetSquareFeet: (value: number) => setSquareFeet(value),
      onResetMaterialsBeProvided: (value: boolean) => setMaterialsBeProvided(value),
      onResetProjectType: (value: string) => setProjectType(value),
      onResetScopeOfWork: (value: string) => setScopeOfWork(value),

      onResetOnSiteContact: (value: string) => setOnSiteContact(value),
      onResetBillingContact: (value: string) => setBillingContact(value),
    }
  };
};

export default useInputs;
