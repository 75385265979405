/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import SelectionButton from "./SelectionButton";
import TextField from "./TextField";

type Props = {
  label?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
};

const BooleanSelector = ({ label, value, onChange }: Props) => {
  const selectValueHandler = (value: boolean) => {
    if(onChange) {
      onChange(value);
    }
  }
  
  return (
    <div css={styles.mainContainer}>
      {label && (
        <TextField color="darkGray" fontSize="small" fontWeight="bold">
          {label}
        </TextField>
      )}
      <div css={styles.buttonsContainer}>
        <SelectionButton onClick={() => selectValueHandler(true)} selected={value === true}>Yes</SelectionButton>
        <SelectionButton onClick={() => selectValueHandler(false)} selected={value === false}>No</SelectionButton>
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  }),
  buttonsContainer: css({
    display: "flex",
    gap: "15px",
  }),
};

export default BooleanSelector;
