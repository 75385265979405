import React, { useState, useEffect } from "react";
import { CustomError } from "src/domain";
import { CompanyContactPrev } from "src/domain/entities/CompanyContactPrev";
import { CreatedProject } from "src/domain/entities/CreatedProject";
import { JobSite } from "src/domain/entities/JobSite";
import { OptionType } from "src/presentation/components/UI/DropDownMenu";
import {
  companyInteractor,
  jobSiteInteractor,
  projectInteractor,
  useAuth,
} from "src/presentation/di";
import useInputs from "./hooks/useInputs";
import { ProjectType } from "src/domain/entities/ProjectType";

const CreateProjectScreenViewModel = () => {
  const { userLogged } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCreateProject, setIsLoadingCreateProject] = useState(false);
  const [error, setError] = useState<CustomError | null>(null);
  const [projectCreated, setProjectCreated] = useState<Boolean>(false);

  //LOCATION
  const [availableJobSites, setAvailableJobSites] = useState<OptionType[]>([]);
  const [availableTypeOfWork, setAvailableTypeOfWork] = useState<OptionType[]>(
    []
  );

  //SCOPE OF WORK
  const [availableProjectTypes, setAvailableProjectTypes] = useState<
    OptionType[]
  >([]);

  //ASSIGN CONTACT
  const [availableOnSiteContacts, setAvailableOnSiteContacts] = useState<
    OptionType[]
  >([]);
  const [availableBillingContact, setAvailableBillingContact] = useState<
    OptionType[]
  >([]);

  const {values, onChange} = useInputs();

  //FETCH DATA
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const [projectTypes, contacts, jobSites] = (await Promise.all([
          projectInteractor.getProyectTypes(),
          companyInteractor.getCompanyContactsPrev(),
          jobSiteInteractor.getJobSites(),
        ])) as [ProjectType[], CompanyContactPrev[], JobSite[]];

        setAvailableProjectTypes(
          projectTypes.map(projectType => ({ label: projectType.name, value: projectType.id }))
        );
        setAvailableOnSiteContacts(
          contacts.map((contact) => ({
            label: contact.fullName,
            value: contact.id,
          }))
        );
        setAvailableBillingContact(
          contacts.map((contact) => ({
            label: contact.fullName,
            value: contact.id,
          }))
        );
        setAvailableJobSites(
          jobSites.map((jobSite) => ({
            label: jobSite.address,
            value: jobSite.id,
          }))
        );
      } catch (error) {
        setError(error as CustomError);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  //CREATE JOBSITE
  const selectOrCreateJobSiteHandler = async () => {
    let jobSiteId = values.selectedJobSiteId;

    if (!jobSiteId) {
      //Create new jobsite if not selected a existing one
      const newJobSite = await jobSiteInteractor.createJobSite(
        values.address,
        values.postalCode,
        values.province,
        values.streetView!
      );
      jobSiteId = newJobSite.id;
    }

    return jobSiteId;
  };

  //CREATE PROJECT
  const createProjectHandler = async (jobSiteId: string) => {
    const createdProject = (await projectInteractor.createProject(
      jobSiteId,
      values.projectType,
      values.scopeOfWork,
      values.budget,
      values.materialsBeProvided!,
      values.startDate,
      values.endDate,
      values.quoteDeadline,
      values.squareFeet
    )) as CreatedProject;

    return createdProject;
  };

  //UPDATE PROJECT
  const updateProjectHandler = async (projectId: string) => {
    const updatedCreatedProject = await projectInteractor.updateProject(
      projectId,
      values.onSiteContact,
      values.billingContact,
      values.uploadDocuments,
      values.uploadMedia,
    );

    return updatedCreatedProject;
  };

  const submitFormHandler = async () => {
    setIsLoadingCreateProject(true);
    let jobSiteId = values.selectedJobSiteId;

    if (!jobSiteId) {
      try {
        jobSiteId = await selectOrCreateJobSiteHandler();
      } catch (error) {
        alert("Error creating Job Site");
        setIsLoadingCreateProject(false);
        return;
      }
    }

    let CreatedProject: CreatedProject | undefined;
    try {
      CreatedProject = await createProjectHandler(jobSiteId);
    } catch (error) {
      alert("Error creating Project, but Job Site was created successfully");
      setIsLoadingCreateProject(false);
      return;
    }

    try {
      await updateProjectHandler(CreatedProject.id);
    } catch (error) {
      alert(
        "Error adding files to the project, but Project and Job Site were created successfully"
      );
      setIsLoadingCreateProject(false);
      return;
    }

    setIsLoadingCreateProject(false);
    setProjectCreated(true);
  };

  return {
    data: {
      userLogged,
      availableJobSites,
      availableTypeOfWork,
      availableProjectTypes,
      availableOnSiteContacts,
      availableBillingContact,
    },
    isLoading,
    isLoadingCreateProject,
    error,
    isError: !!error,
    projectCreated,

    fieldValues: values,
    onChangeValues: onChange,

    //CREATE PROJECT
    onCreateProject: submitFormHandler,
  };
};

export default CreateProjectScreenViewModel;
