/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { CSSProperties } from "react";
import { StyleType } from "src/presentation/types";

type Props = {
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
  src?: React.ImgHTMLAttributes<HTMLImageElement>["src"];
  alt?: React.ImgHTMLAttributes<HTMLImageElement>["alt"];
};

const ImagePrev = ({ height = "115px", width = "160px", src, alt }: Props) => {
  const openImage = () => {
    window.open(src);
  };

  return (
    <div onClick={openImage} css={[styles.mainContainer, { height, width }]}>
      <img css={styles.image} src={src} alt={alt} />
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    borderRadius: "6px",
    overflow: "hidden",
    ":hover": {
      cursor: "pointer",
    },
  }),
  image: css({
    height: "100%",
    width: "100%",
    objectFit: "cover",
  }),
};

export default ImagePrev;
