/** @jsxImportSource @emotion/react */
import React from "react";

import useViewModel from './InvoiceListScreenViewModel'

import { StyleType } from "src/presentation/types";
import { css } from "@emotion/react";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { LoadingCover, TextField } from "src/presentation/components/UI";
import HeaderContainer from "src/presentation/components/HeaderProfile/HeaderContainer";
import InvoicePrev from "src/presentation/components/UI/InvoicePrev";
import InvoiceFieldsInfo from "./components/InvoiceFieldsInfo";

export type Props = {};

const InvoiceListScreenView = ({}: Props) => {
  const { data, isLoading, error, isError } = useViewModel();

  if (isLoading) return <LoadingCover />;

  if (isError) return <div>{error?.message}</div>;

  return (
    <div css={styles.mainContainer}>
      <HeaderContainer customCss={styles.headerContainer}>
        <TextField fontWeight="bold" customFontSize={32} color="darkGray">
          Invoices
        </TextField>
      </HeaderContainer>

      <div css={styles.invoiceFieldsInfoContainer}>
        <InvoiceFieldsInfo />
      </div>

      <div css={styles.invoiceListContainer}>
        {data.invoices.map((invoice) => (
          <InvoicePrev invoice={invoice} key={invoice.id} />
        ))}
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    width: "100%",
    height: "max-content",
    minHeight: "100%",
    padding: "2rem",
  }),
  headerContainer: css({
    marginBottom: "50px",
    alignItems: "center",
    [BREAKPOINTS.MEDIUM]: {
      justifyContent: "center",
      marginBottom: "25px",
    },
  }),
  invoiceListContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  }),
  invoiceFieldsInfoContainer: css({
    marginBottom: "25px",
    width: "100%",

    [BREAKPOINTS.MEDIUM_LARGE]: {
      display: "none",
    },
  }),
};

export default InvoiceListScreenView;
