import { DataResponse } from "src/domain/utils/DataResponse";
import { IHttpClient } from "src/domain/utils/IHttpClient";
import { IAuthLocalDataSource } from "./repositories/auth/IAuthLocalDataSource";

import axios, { AxiosInstance } from 'axios';
import { AuthData } from "src/domain/entities/AuthData";

export class AxiosHttpClientImp implements IHttpClient {
    private axiosClient: AxiosInstance;

    constructor(
        private baseUrl: string,
        private authLocalDataSource: IAuthLocalDataSource,
    ){
        this.axiosClient = axios.create({
            baseURL: this.baseUrl,
        })

        this.axiosClient.interceptors.request.use(
            async (config) => {
                const authData = await this.authLocalDataSource.getAuthData() as AuthData | null;
                if(authData) {
                    config.headers.Authorization = `Bearer ${authData.token}`;
                }

                return config;
            },
            async (error) => {
                Promise.reject(error);
            }
        )
    }

    async get<T>(url: string): DataResponse<T> {
        try {
            const {data} = await this.axiosClient.get(url);
            return data;
        } catch (error) {
            throw error
        }
    }

    async post<T>(url: string, body?: object | undefined): DataResponse<T> {
        try {
            const {data} = await this.axiosClient.post(url, body);
            return data;
        } catch (error) {
            throw error
        }
    }

    async put<T>(url: string, body?: object | undefined): DataResponse<T> {
        try {
            const {data} = await this.axiosClient.put(url, body);
            return data;
        } catch (error) {
            throw error
        }
    }
}