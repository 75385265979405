/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";

type Props = {
  opened: boolean;
  modal: React.ReactNode;
  children: React.ReactNode;
  onCloseModal: () => void;
};

const ModalContainer = ({ opened, modal, children, onCloseModal }: Props) => {
  return (
    <div css={[styles.mainContainer, opened ? {overflow: "hidden"} : {}]}>
      {children}

      {opened && (
        <div css={styles.modalContainer}>
          {modal}
        </div>
      )}
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    position: "relative",
    width: "100%",
    height: "100%",
  }),
  modalContainer: css({
    position: "absolute",
    display: "grid",
    placeItems: "center",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: colors.darkGray,
    overflow: "auto"
  }),
};

export default ModalContainer;
