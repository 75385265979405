import { ProjectType } from "src/domain/entities/ProjectType";
import { IProjectRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";

export class GetProyectTypesUseCase {
    constructor(
        private projectRepository: IProjectRepository
    ){}

    async execute(): DataResponse<ProjectType[]> {
        return await this.projectRepository.getProjectTypes() as ProjectType[];
    }
}