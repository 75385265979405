import { CreatedProject, FileData } from "src/domain/entities";
import { IProjectRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";
import { UploadFileUseCase } from "../file/UploadFileUseCase";

export class UpdateProjectUseCase {
  constructor(
    private projectRepository: IProjectRepository,
    private uploadFile: UploadFileUseCase
  ) {}

  async execute(
    projectId: string,
    onSiteContactId?: string,
    billingContactId?: string,
    uploadDocuments?: File[],
    uploadMedia?: File[],
    typeOfWork?: string,
    scope?: string,
    budget?: { min: number; max: number },
    materialBeProvided?: boolean,
    estimatedStartDate?: Date,
    estimatedEndDate?: Date,
    quoteDeadline?: Date,
    jobSiteId?: number,
    title?: string,
    squareFeet?: number
  ): DataResponse<CreatedProject> {
    let documentUrls: string[] | undefined = undefined;
    let mediaUrls: string[] | undefined = undefined;

    if (uploadDocuments && uploadMedia) {
      const uploadedDocumentsPromise = Promise.all(
        uploadDocuments.map((document) => this.uploadFile.execute(document))
      );
      const uploadedMediaPromise = Promise.all(
        uploadMedia.map((mediaFile) => this.uploadFile.execute(mediaFile))
      );

      const [uploadedDocuments, uploadedMedia] = (await Promise.all([
        uploadedDocumentsPromise,
        uploadedMediaPromise,
      ])) as [FileData[], FileData[]];

      documentUrls = uploadedDocuments.map((document) => document.url);
      mediaUrls = uploadedMedia.map((media) => media.url);
    }

    await this.projectRepository.updateProject(
      projectId,
      billingContactId,
      onSiteContactId,
      documentUrls,
      mediaUrls,
      typeOfWork,
      scope,
      budget,
      materialBeProvided,
      estimatedStartDate,
      estimatedEndDate,
      quoteDeadline,
      jobSiteId,
      title,
      squareFeet
    );

    // We have to update the project two times in order to change its status
    const updatedCreatedProject = await this.projectRepository.updateProject(projectId);

    return updatedCreatedProject;
  }
}
