// AUTH IMPORTS
import {
  AxiosHttpClientImp,
  AuthRepositoryImp,
  AuthRemoteDataSourceImp,
  AuthLocalDataSourceImp,
} from "src/data";
import {
  LoginUseCase,
  LogoutUseCase,
  AuthInteractor,
  GetSavedUserUseCase,
} from "src/domain";
import { SendResetPasswordEmailUseCase } from "src/domain/usecases/auth/sendResetPasswordEmailUseCase";

// CHAT SUPPORT IMPORTS
import { useChatSupportIntercomImp, IntercomChatSupportProvider } from "src/presentation/hooks";

// USER IMPORTS
import { userRepositoryImp, UserLocalDataSourceImp } from "src/data";

// CONFIG IMPORTS
import { BASE_URL } from "src/config";

// USER STORE IMPORTS
import {
  UserProvider as UserContextProvider,
  useUserStore as useUserContextStore,
} from "src/presentation/store/user";
import { IUseAuth, useAuthImp } from "src/presentation/hooks";
import { UserInteractor } from "src/domain/interactors/userInteractor";

// PROJECT IMPORTS
import { ProjectRemoteDataSourceImp } from "src/data/repositories/project/ProjectRemoteDataSourceImp";
import { ProjectRepositoryImp } from "src/data/repositories/project/ProjectRepositoryImp";
import { GetCreatedProjectsPrevUseCase } from "src/domain/usecases/project/GetCreatedProjectsPrevUseCase";
import { GetQuoteProjectsPrevUseCase } from "src/domain/usecases/project/GetQuoteProjectsPrevUseCase";
import { GetQuoteProjectByIdUseCase } from "src/domain/usecases/project/GetQuoteProjectByIdUseCase";
import { ProjectInteractor } from "src/domain/interactors/projectInteractor";

// INVOICE IMPORTS
import { GetInvoicesPrevUseCase, InvoiceInteractor } from "src/domain";
import { InvoiceRemoteDataSourceImp, InvoiceRepositoryImp } from "src/data";

// FILE IMPORTS
import {
  FileRemoteDataSourceImp,
  FileRepositoryImp,
} from "src/data/repositories/file";
import { UploadFileUseCase } from "src/domain/usecases/file/UploadFileUseCase";
import { UploadProjectQuoteUseCase } from "src/domain/usecases/project/UploadProjectQuoteUseCase";
import { GetCreatedProjectByIdUseCase } from "src/domain/usecases/project/GetCreatedProjectByIdUseCase";
import { GetProyectTypesUseCase } from "src/domain/usecases/project/GetProyectTypesUseCase";
import { GetCompanyContactsPrevUseCase } from "src/domain/usecases/company/GetCompanyContactsPrevUseCase";
import { CreateProjectUseCase } from "src/domain/usecases/project/CreateProjectUseCase";
import { JobSiteRemoteDataSourceImp } from "src/data/repositories/jobsite/JobSiteRemoteDataSourceImp";
import { JobSiteRepositoryImp } from "src/data/repositories/jobsite/JobSiteRepositoryImp";
import { CreateJobSiteUseCase } from "src/domain/usecases/jobsite/CreateJobSiteUseCase";
import { JobSiteInteractor } from "src/domain/interactors/jobSiteInteractor";
import { CompanyInteractor } from "src/domain/interactors/companyInteractor";
import { CompanyRepositoryImp } from "src/data/repositories/company/CompanyRepositoryImp";
import { CompanyRemoteDataSourceImp } from "src/data/repositories/company/CompanyRemoteDataSourceImp";
import { UpdateProjectUseCase } from "src/domain/usecases/project/UpdateProjectUseCase";
import { GetJobSitesUseCase } from "src/domain/usecases/jobsite/GetJobSitesUseCase";
import { userRemoteDataSourceImp } from "src/data/repositories/user/UserRemoteDataSourceImp";
import { UpdateUserDataUseCase } from "src/domain/usecases/user/UpdateUserDataUseCase";
import { UpdateProjectQuoteUseCase } from "src/domain/usecases/project/UpdateProjectQuoteUseCase";

/* ------------------------------------------------------------------------------------------------------------------------ */

const authLocalDataSource = new AuthLocalDataSourceImp();

// UTILS
const httpClient = new AxiosHttpClientImp(BASE_URL, authLocalDataSource);

// FILE IMPLEMENTATIONS
const fileRemoteDataSource = new FileRemoteDataSourceImp(httpClient);
const fileRepository = new FileRepositoryImp(fileRemoteDataSource);
export const uploadFileUseCase = new UploadFileUseCase(fileRepository);

// USER IMPLEMENTATIONS
const userLocalDataSource = new UserLocalDataSourceImp();
const userRemoteDataSource = new userRemoteDataSourceImp(httpClient);
const userRepository = new userRepositoryImp(userRemoteDataSource, userLocalDataSource);
const getSavedUserUseCase = new GetSavedUserUseCase(userRepository);
const updateUserDataUseCase = new UpdateUserDataUseCase(userRepository);

export const userInteractor = new UserInteractor(getSavedUserUseCase, updateUserDataUseCase);

// AUTH IMPLEMENTATIONS
const authRemoteDataSource = new AuthRemoteDataSourceImp(httpClient);
const authRepository = new AuthRepositoryImp(
  authLocalDataSource,
  authRemoteDataSource
);
const loginUseCase = new LoginUseCase(authRepository, userRepository);
const logoutUseCase = new LogoutUseCase(authRepository, userRepository);
const sendResetPasswordEmailUseCase = new SendResetPasswordEmailUseCase(
  authRepository
);

export const authInteractor = new AuthInteractor(
  loginUseCase,
  logoutUseCase,
  sendResetPasswordEmailUseCase
);

//JOBSITE IMPLEMENTATIONS
const jobsiteRemoteDataSourceImp = new JobSiteRemoteDataSourceImp(httpClient);
const jobSiteRepository = new JobSiteRepositoryImp(jobsiteRemoteDataSourceImp);
const getJobSitesUseCase = new GetJobSitesUseCase(jobSiteRepository);
const createJobSiteUseCase = new CreateJobSiteUseCase(jobSiteRepository, uploadFileUseCase);
export const jobSiteInteractor = new JobSiteInteractor(getJobSitesUseCase, createJobSiteUseCase);

// PROJECT IMPLEMENTATIONS
const projectRemoteDataSourceImp = new ProjectRemoteDataSourceImp(httpClient);
const projectRepository = new ProjectRepositoryImp(projectRemoteDataSourceImp);
const getCreatedProjectsPrevUseCase = new GetCreatedProjectsPrevUseCase(
  projectRepository
);
const getCreatedProjectByIdUseCase = new GetCreatedProjectByIdUseCase(
  projectRepository
);
const getQuoteProjectsPrevUseCase = new GetQuoteProjectsPrevUseCase(
  projectRepository
);
const getQuoteProjectByIdUseCase = new GetQuoteProjectByIdUseCase(
  projectRepository
);
const uploadProjectQuoteUseCase = new UploadProjectQuoteUseCase(
  uploadFileUseCase,
  projectRepository
);
const getProyectTypesUseCase = new GetProyectTypesUseCase(projectRepository);
const createProjectUseCase = new CreateProjectUseCase(projectRepository);
const updateProjectUseCase = new UpdateProjectUseCase(projectRepository, uploadFileUseCase);
const updateProjectQuoteUseCase = new UpdateProjectQuoteUseCase(uploadFileUseCase, projectRepository)

export const projectInteractor = new ProjectInteractor(
  getCreatedProjectsPrevUseCase,
  getCreatedProjectByIdUseCase,
  getQuoteProjectsPrevUseCase,
  getQuoteProjectByIdUseCase,
  uploadProjectQuoteUseCase,
  getProyectTypesUseCase,
  createProjectUseCase,
  updateProjectUseCase,
  updateProjectQuoteUseCase,
);

// INVOICE IMPLEMENTATIONS
const invoiceRemoteDataSource = new InvoiceRemoteDataSourceImp(httpClient);
const invoiceRepository = new InvoiceRepositoryImp(invoiceRemoteDataSource);
const getInvoicesPrevUseCase = new GetInvoicesPrevUseCase(invoiceRepository);
export const invoiceInteractor = new InvoiceInteractor(getInvoicesPrevUseCase);

// COMPANY
const companyRemoteDataSource = new CompanyRemoteDataSourceImp(httpClient)
const CompanyRepository = new CompanyRepositoryImp(companyRemoteDataSource)
const getCompanyContactsPrevUseCase = new GetCompanyContactsPrevUseCase(CompanyRepository);
export const companyInteractor = new CompanyInteractor(getCompanyContactsPrevUseCase)

// USER STORE IMPLEMENTATIONS
export const UserProvider = UserContextProvider;
export const useUserStore = useUserContextStore;

// USE AUTH IMPLEMENTATION
export const useAuth: IUseAuth = useAuthImp;

// CHAT SUPPORT IMPLEMENTATIONS
export const ChatSupportProvider = IntercomChatSupportProvider;
export const useChatSupport = useChatSupportIntercomImp;
