import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import PageWithTitle from "../hoc/PageWithTitle";

import QuoteProjectListScreenView from "../pages/QuoteProjectListScreen/QuoteProjectListScreenView";
import QuoteProjectScreen from "../pages/QuoteProjectScreen/QuoteProjectScreenView";

const routes = {
  PROJECTS_LIST: "/",
  QUOTE_PROJECT: "project",
};

const QuoteProjectsNavigation = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path={routes.PROJECTS_LIST}
        element={
          <PageWithTitle title="Projects to quote - Ubild">
            <QuoteProjectListScreenView
              onGoToPreview={() => {}}
              onGoToViewProject={(id: string) => navigate(`project/${id}`)}
            />
          </PageWithTitle>
        }
      />
      <Route
        path={`/${routes.QUOTE_PROJECT}/:id`}
        element={
          <PageWithTitle title="Quote Project - Ubild">
            <QuoteProjectScreen />
          </PageWithTitle>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default QuoteProjectsNavigation;
