import React, { createContext } from 'react';
import viewModel from '../CreateProjectScreenViewModel'

type DataType = ReturnType<typeof viewModel>["data"]
type FieldValuesType = ReturnType<typeof viewModel>["fieldValues"]
type OnChangeValuesType = ReturnType<typeof viewModel>["onChangeValues"]
type IsLoadingFormType = ReturnType<typeof viewModel>["isLoadingCreateProject"]
type SubmitFormType = ReturnType<typeof viewModel>["onCreateProject"]

export type FormContextType = {
  data: DataType,
  fieldValues: FieldValuesType,
  onChangeValues: OnChangeValuesType,
  isLoadingForm: IsLoadingFormType,
  onSubmitForm: SubmitFormType,
} | null

export const FormContext = createContext<FormContextType>(null)

const FormProvider = ({children, value}: {children: JSX.Element, value: FormContextType}) => {
  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  )
}

export default FormProvider