import { QuoteProject } from "src/domain/entities/QuoteProject";
import { DateFormatter } from "src/domain/utils/DateFormatter";
import { ApiQuotePrev } from "./ApiQuotePrev";

export class ApiQuoteProject {
  constructor(
    readonly id: string,
    readonly title: string,
    readonly companyId: number,
    readonly companyName: string,
    readonly jobSiteId: number,
    readonly typeOfWork: string,
    readonly status: string,
    readonly estimatedStartDate: string,
    readonly estimatedEndDate: string,
    readonly location: string,
    readonly locationCoordinates: { lat?: string; lng?: string },
    readonly billingContactId: number,
    readonly billingContactName: string,
    readonly siteSupervisorId: number,
    readonly siteSupervisorName: string,
    readonly scope: string,
    readonly materialBeProvided: boolean,
    readonly created_at: string,
    readonly relevantDocuments: string[],
    readonly blueprintDocuments: string[],
    readonly deadline?: string,
    readonly squareFeet?: number,
    readonly projectQuote?: any, //TODO: check this
    readonly vendorQuote?: any //TODO: check this
  ) {}

  toDomain(): QuoteProject {
    return new QuoteProject(
      this.id,
      this.title,
      this.companyName,
      this.typeOfWork,
      this.status,
      DateFormatter.fromYYYYMMDD(this.estimatedStartDate),
      DateFormatter.fromYYYYMMDD(this.estimatedEndDate),
      this.location,
      {
        lat: this.locationCoordinates?.lat
          ? +this.locationCoordinates.lat
          : undefined,
        lng: this.locationCoordinates?.lng
          ? +this.locationCoordinates.lng
          : undefined,
      },
      this.siteSupervisorName,
      this.billingContactName,
      this.scope,
      Boolean(this.materialBeProvided),
      this.relevantDocuments,
      [
        "https://jsoncompare.org/LearningContainer/SampleFiles/Video/MP4/Sample-MP4-Video-File-for-Testing.mp4",
      ], //TODO: get video urls from backend
      this.blueprintDocuments,
      this.deadline ? DateFormatter.fromYYYYMMDD(this.deadline) : undefined,
      this.squareFeet,
      this.vendorQuote ? ApiQuotePrev.fromJSON(this.vendorQuote).toDomain() : undefined,
    );
  }

  static fromJSON(json: any): ApiQuoteProject {
    return Object.setPrototypeOf(
      json,
      ApiQuoteProject.prototype
    ) as ApiQuoteProject;
  }
}
