/** @jsxImportSource @emotion/react */
import React from "react";

import { css, Interpolation, Theme } from "@emotion/react";
import { useAuth } from "src/presentation/di";
import { StyleType } from "src/presentation/types";
import { CircularImage, Icon, TextField, WhiteCard } from "../UI";

import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { colors } from "src/presentation/theme";

type Props = {
  customCss?: Interpolation<Theme>;
}

const HeaderProfile = ({customCss}: Props) => {
  const { userLogged } = useAuth();

  return (
    <div css={[styles.mainContainer, customCss]}>
      {/* <Link to="/notifications">
        <WhiteCard customCss={styles.alertButton}>
          <Icon color="darkGray" size="xl" icon={faBell} />
        </WhiteCard>
      </Link> */}

      <WhiteCard customCss={styles.profileButton}>
        <CircularImage
          src={userLogged!.image}
          alt="profile image"
          size="42px"
          defaultBackgroundColor="primaryLight"
        />

        <div>
          <TextField fontWeight="bold" fontSize="medium" color="darkGray">
            {userLogged!.firstName}
          </TextField>

          <Link css={styles.linkContainer} to="/profile">
            {/* TODO: check how to pass the profile route without coupling the component */}
            <TextField fontWeight="light" fontSize="extraSmall" color="primary">
              Edit profile
            </TextField>
          </Link>
        </div>
      </WhiteCard>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    gap: "14px",
  }),
  alertButton: css({
    display: "grid",
    placeItems: "center",
    width: "65px",
    height: "65px",
  }),
  profileButton: css({
    display: "flex",
    alignItems: "center",
    gap: "15px",
    minWidth: "217px",
    height: "65px",
    padding: "0 15px",
  }),
  linkContainer: css({
    ":hover": {
      textDecoration: `underline ${colors.primary}`,
    },
  }),
};

export default HeaderProfile;
