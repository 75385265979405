export class InvoicePrev {
    constructor (
        readonly id: string,
        readonly number: string,
        readonly name: string,
        readonly address: string,
        readonly date: Date,
        readonly total: number,
        readonly status: string,
        readonly isCompleted: boolean,
        readonly isCancelled: boolean,
        readonly payLink?: string,
        readonly downloadLink?: string,
    ){}
}