/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { faArrowRightFromBracket } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";
import { IconWithText } from "../../UI";

type Props = {
  onPress: () => void;
};

const Logout = ({ onPress }: Props) => {
  return (
    <div css={styles.mainContainer}>
      <IconWithText onClick={onPress} icon={faArrowRightFromBracket}>
        Log out
      </IconWithText>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    width: "100%",
    marginTop: "auto",
  }),
};

export default Logout;
