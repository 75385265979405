import { CustomError, DataResponse, IHttpClient, errorCodeEnum, errorMessageEnum } from "src/domain";
import { IInvoiceRemoteDataSource } from "./IInvoiceRemoteDataSource";
import { ApiInvoice } from "src/data/models/invoice/ApiInvoice";
import { IApiInvoicesResp } from "src/data/models/invoice/IApiInvoicesResp";
import { GET_INVOICES_PREV } from "src/data/apiUrls";

export class InvoiceRemoteDataSourceImp implements IInvoiceRemoteDataSource {
    constructor (
        private httpClient: IHttpClient
    ){}

    async getInvoicesPrev(): DataResponse<ApiInvoice[]> {
        try {
            const res = await this.httpClient.get<IApiInvoicesResp>(GET_INVOICES_PREV) as IApiInvoicesResp
            const apiInvoices = res.Invoices.map(jsonApiInvoice => ApiInvoice.fromJSON(jsonApiInvoice));
            return apiInvoices;
        } catch (error) {
            throw new CustomError(errorCodeEnum.SERVER_ERROR, errorMessageEnum.GET_INVOICES_PREV_ERROR)
        }
    }
}