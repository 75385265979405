import { JobSite } from "src/domain/entities/JobSite";

export class ApiJobSite {
    constructor(
        readonly id: number,
        readonly vonigo_id: string,
        readonly created_by: number | null,
        readonly company_id: number,
        readonly address: string,
        readonly zip_code: string,
        readonly city: string,
        readonly type: string[],
        readonly created_at: string, //JSON date format
        readonly updated_at: string, //JSON date format
        readonly streetview?: string,
        readonly archived?: number,
        readonly latitude?: number | null,
        readonly longitude?: number | null,
    ){}

    toDomain(): JobSite {
        return new JobSite(this.id.toString(), this.address, this.zip_code, this.city);
    }

    static fromJson(json: any) {
      return Object.setPrototypeOf(
        json,
        ApiJobSite.prototype
      ) as ApiJobSite;
    }
}