import { QuotePrev } from "./QuotePrev";
import { QuoteProjectPrev } from "./QuoteProjectPrev";

export class QuoteProject extends QuoteProjectPrev {
  constructor(
    id: string,
    title: string,
    companyName: string,
    typeOfWork: string,
    status: string,
    estimatedStartDate: Date,
    estimatedEndDate: Date,
    location: string,

    readonly locationCoordinates: {lat?: number, lng?: number},
    readonly siteSupervisorName: string,
    readonly billingContactName: string,
    readonly scope: string,
    readonly materialBeProvided: boolean,
    readonly imageUrls: string[],
    readonly videoUrls: string[],
    readonly fileUrls: string[],
    deadline?: Date,
    readonly squareFeet?: number,
    readonly vendorQuote?: QuotePrev,
  ) {
    super(
      id,
      title,
      companyName,
      typeOfWork,
      status,
      estimatedStartDate,
      estimatedEndDate,
      location,
      deadline,
    );
  }
}
