import { CreatedProject } from "src/domain/entities";
import { IProjectRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";

export class CreateProjectUseCase {
  constructor(
    private projectRepository: IProjectRepository,
  ) {}

  async execute(
    jobSiteId: string,
    projectType: string,
    scopeOfWork: string,
    budget: {min: number, max: number},
    materialsBeProvided: boolean,
    startDate: Date,
    endDate: Date,
    deadline: Date,
    squareFeet: number,
  ): DataResponse<CreatedProject> {
    const createdProject = (await this.projectRepository.createProject(
      jobSiteId,
      projectType,
      scopeOfWork,
      budget,
      materialsBeProvided,
      startDate,
      endDate,
      deadline,
      squareFeet,
    )) as CreatedProject;

    return createdProject;
  }
}
