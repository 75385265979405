/** @jsxImportSource @emotion/react */
import React from "react";
import useFormContext from "../context/useFormContext";
import AssignContactsForm from "src/presentation/components/CreateProjectForm/AssignContactsForm";

const AssignContactsStep = () => {
  const {data, fieldValues, onChangeValues} = useFormContext();

  return (
    <AssignContactsForm
      availableBillingContact={data.availableBillingContact}
      availableOnSiteContacts={data.availableOnSiteContacts}
      fieldValues={fieldValues}
      onChangeValues={onChangeValues}
    />
  );
};

export default AssignContactsStep;
