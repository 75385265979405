import { Project, ProjectPrev } from "src/domain";
import { DateFormatter } from "src/domain/utils/DateFormatter";

export class ApiCreatedProjectPrev {
  constructor(
    readonly id: string,
    readonly title: string,
    readonly companyId: number,
    readonly companyName: string,
    readonly jobSiteId: number,
    readonly typeOfWork: string,
    readonly status: string,
    readonly estimatedStartDate: string,
    readonly estimatedEndDate: string,
    readonly location: string,
    readonly locationCoordinates: { lat: string; lng: string },
    readonly squareFeet: number,
    readonly budget: { min: string; max: string },
    readonly billingContactId: number,
    readonly billingContactName: string,
    readonly siteSupervisorId: number,
    readonly siteSupervisorName: string,
    readonly scope: string,
    readonly materialBeProvided: boolean,
    readonly created_at: string,
    readonly relevantDocuments: string[],
    readonly blueprintDocuments: string[],
    readonly deadline?: string,
  ) {}

  toDomain(): ProjectPrev {
    return new ProjectPrev(
      this.id,
      this.title,
      this.companyName,
      this.typeOfWork,
      this.status,
      DateFormatter.fromYYYYMMDD(this.estimatedStartDate),
      DateFormatter.fromYYYYMMDD(this.estimatedEndDate),
      this.location,
      this.deadline ? DateFormatter.fromYYYYMMDD(this.deadline) : undefined,
    );
  }

  static fromJSON(json: any): ApiCreatedProjectPrev {
    return Object.setPrototypeOf(
      json,
      ApiCreatedProjectPrev.prototype
    ) as ApiCreatedProjectPrev;
  }
}
