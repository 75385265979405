import React, { useState } from "react";
import { OptionType } from "src/presentation/components/UI/DropDownMenu";

import CreateJobSiteForm from "./CreateJobSiteForm";
import SelectJobSiteForm from "./SelectJobSiteForm";
import useFormContext from "../context/useFormContext";

enum FormModeEnum {
  CREATE_JOBSITE,
  SELECT_JOBSITE,
}

const LocationStep = () => {
  const {onChangeValues} = useFormContext();

  const [formMode, setFormMode] = useState<FormModeEnum>(
    FormModeEnum.SELECT_JOBSITE
  );

  const changeFormMode = (selectedFormMode: FormModeEnum) => {
    onChangeValues.onClearLocationInputs();
    setFormMode(selectedFormMode)
  }

  if(formMode === FormModeEnum.SELECT_JOBSITE) {
    return (
      <SelectJobSiteForm
        onGoToCreateJobSite={() => changeFormMode(FormModeEnum.CREATE_JOBSITE)}
      />
    )
  }

  if(formMode === FormModeEnum.CREATE_JOBSITE) {
    return (
      <CreateJobSiteForm
        onGoToSelectJobSite={() => changeFormMode(FormModeEnum.SELECT_JOBSITE)}
      />
    )
  }

  return <></>
};

export default LocationStep;
