import { DateFormatter } from "src/domain";
import { QuotePrev } from "src/domain/entities/QuotePrev";

export class ApiQuotePrev {
  constructor(
    readonly id: number,
    readonly project_id: string,
    readonly company_id: number,
    readonly company_name: string,
    readonly price: number,
    readonly payment_terms: string,
    readonly project_start_date: string, //example: "2023-02-28"
    readonly project_end_date: string, //example" "2023-03-04"
    readonly documents: string[],
    readonly created_at: string, //example: "2023-03-21T17:38:49.000000Z"
    readonly updated_at: string // example "2023-03-21T17:38:54.000000Z"
  ) {}

  toDomain(): QuotePrev {
    return new QuotePrev(
      this.id.toString(),
      this.company_name,
      this.project_id,
      this.company_id.toString(),
      this.price,
      this.payment_terms,
      DateFormatter.fromYYYYMMDD(this.project_start_date),
      DateFormatter.fromYYYYMMDD(this.project_end_date),
      this.documents,
      DateFormatter.fromJSONDate(this.created_at),
      DateFormatter.fromJSONDate(this.updated_at)
    );
  }

  static fromJSON(json: any): ApiQuotePrev {
    return Object.setPrototypeOf(json, ApiQuotePrev.prototype) as ApiQuotePrev;
  }
}
