/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import FormStepContainer from "./FormStepContainer";
import InputFile from "src/presentation/components/UI/InputFile";
import { TextField } from "src/presentation/components/UI";
import DeleteableItemList from "src/presentation/components/UI/DeleteableItemList";
import useFormContext from "../context/useFormContext";

const UploadsStep = () => {
  const {fieldValues, onChangeValues} = useFormContext();

  return (
    <FormStepContainer>
      <div css={styles.innerContainer}>
        <InputFile
          multiple
          label="Upload Documents"
          innerLabel="PDF"
          onChange={onChangeValues.onChangeDocuments}
        />
        <DeleteableItemList
          items={fieldValues.uploadDocuments.map((file) => file.name)}
          onRemoveItem={onChangeValues.onRemoveDocument}
        />
      </div>
      <div css={styles.innerContainer}>
        <InputFile
          multiple
          label="Upload Media"
          innerLabel="Image"
          onChange={onChangeValues.onChangeMedia}
        />
        <DeleteableItemList
          items={fieldValues.uploadMedia.map((file) => file.name)}
          onRemoveItem={onChangeValues.onRemoveMedia}
        />
      </div>
    </FormStepContainer>
  );
};

const styles: StyleType = {
  innerContainer: css({
    flex: 1 / 2,
  }),
};

export default UploadsStep;
