/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { DateFormatter } from "src/domain/utils/DateFormatter";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";
import getStatusColor from "src/presentation/utils/getStatusColor";
import ActionButton from "./ActionButton";
import CardFieldContainer from "./CardFieldContainer";
import TextField from "./TextField";
import ActionButtonWithIcon from "./ActionButtonWithIcon";

import { faDownToBracket } from "@fortawesome/pro-regular-svg-icons";
import { InvoicePrev as InvoicePrevEntity } from "src/domain/entities/InvoicePrev";

type Props = {
  invoice: InvoicePrevEntity;
};

const InvoicePrev = ({ invoice }: Props) => {
  return (
    <div css={styles.mainContainer}>
      <CardFieldContainer label="number" flex={1 / FLEX_TOTAL}>
        <TextField fontWeight="light" fontSize="small" color="darkGrayText">
          {invoice.number}
        </TextField>
      </CardFieldContainer>

      <CardFieldContainer label="name" flex={2 / FLEX_TOTAL}>
        <TextField fontWeight="light" fontSize="small" color="darkGrayText">
          {invoice.name}
        </TextField>
      </CardFieldContainer>

      <CardFieldContainer label="address" flex={2.5 / FLEX_TOTAL}>
        <TextField fontWeight="light" fontSize="small" color="darkGrayText">
          {invoice.address}
        </TextField>
      </CardFieldContainer>

      <CardFieldContainer label="date" flex={1.5 / FLEX_TOTAL}>
        <TextField fontWeight="light" fontSize="small" color="darkGrayText">
          {DateFormatter.toMMMdoYYYY(invoice.date)}
        </TextField>
      </CardFieldContainer>

      <CardFieldContainer label="total" flex={1 / FLEX_TOTAL}>
        <TextField fontWeight="light" fontSize="small" color="darkGrayText">
          {`$${invoice.total}`}
        </TextField>
      </CardFieldContainer>

      <CardFieldContainer label="status" flex={1.5 / FLEX_TOTAL}>
        <TextField
          customColor={getStatusColor(invoice.status)}
          fontWeight="light"
          fontSize="small"
          color="darkGrayText"
        >
          {invoice.status}
        </TextField>
      </CardFieldContainer>

      <div css={[styles.centeredFlexContainer, styles.buttonsContainer]}>
        {invoice.payLink && !(invoice.isCompleted || invoice.isCancelled) && (
          <a href={invoice.payLink} target="_blank">
            <ActionButton color="primary">Pay Invoice</ActionButton>
          </a>
        )}

        {invoice.downloadLink && (
          <a href={invoice.downloadLink} target="_blank">
            <ActionButtonWithIcon
              size="xl"
              icon={faDownToBracket}
              color="secondary"
            />
          </a>
        )}
      </div>
    </div>
  );
};

const FLEX_TOTAL = 12;

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    padding: "15px 20px",
    backgroundColor: colors.pureWhite,
    borderRadius: "8px",
    boxShadow: "0px 2px 1px rgba(80, 92, 117, 0.05);",
    width: "100%",
    gap: "10px",

    [BREAKPOINTS.MEDIUM_LARGE]: {
      flexDirection: "column",
    },
  }),
  centeredFlexContainer: css({
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  }),
  invoiceNumberContainer: css({
    flex: 2 / FLEX_TOTAL,
    gap: "28px",
    flexShrink: 1,
  }),
  buttonsContainer: css({
    flex: 2.5 / FLEX_TOTAL,
    gap: "12px",
    justifyContent: "end",
    [BREAKPOINTS.MEDIUM_LARGE]: {
      justifyContent: "center",
    },
  }),
};

export default InvoicePrev;
