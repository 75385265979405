/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import CircleWithLabel from "src/presentation/components/UI/CircleWithLabel";

export type Props = {
  steps: string[];
  currentStep: number;
};

const FormProgressBar = ({ steps, currentStep }: Props) => {
  return (
    <div css={styles.mainContainer}>
      {steps.map((step, i) => (
        <CircleWithLabel key={i} selected={currentStep >= i} label={step}>{(i + 1).toString()}</CircleWithLabel>
      ))}
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  }),
}

export default FormProgressBar;
