/** @jsxImportSource @emotion/react */
import React, { CSSProperties, useRef } from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import { faFolderArrowUp } from "@fortawesome/pro-regular-svg-icons";
import Icon from "./Icon";
import TextField from "./TextField";
import { colors } from "src/presentation/theme";

type Props = {
  label?: string;
  innerLabel: string;
  height?: CSSProperties["height"];
} & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>;

const InputFile = ({
  label,
  innerLabel,
  height = "75px",
  ...props
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const clickUploadFileContainer = () => {
    fileInputRef.current?.click();
  };

  return (
    <div css={styles.mainContainer}>
      {label && (
        <TextField color="darkGray" fontSize="small" fontWeight="bold">
          {label}
        </TextField>
      )}
      <div
        onClick={clickUploadFileContainer}
        css={[styles.uploadFileContainer, { height }]}
      >
        <Icon color="darkGrayText" size="lg" icon={faFolderArrowUp} />
        <TextField color="darkGray">{innerLabel}</TextField>

        <input
          css={styles.htpmInput}
          ref={fileInputRef}
          height="40px"
          type="file"
          {...props}
        />
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  }),
  uploadFileContainer: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    border: `1px dashed ${colors.darkGrayTransparent50}`,
    borderRadius: "6px",
    gap: "5px",
    cursor: "pointer",
  }),
  htpmInput: css({
    display: "none",
  }),
};

export default InputFile;
