import { ProjectPrev } from "src/domain/entities";
import { CreatedProjectPrev } from "src/domain/entities/CreatedProjectPrev";
import { IProjectRepository } from "src/domain/repositories/IProjectRepository";
import { DataResponse } from "src/domain/utils";

export class GetCreatedProjectsPrevUseCase {
    constructor(
        private projectRepository: IProjectRepository,
    ) {}

    async execute(): DataResponse<CreatedProjectPrev[]> {
        const projects = await this.projectRepository.getCreatedProjectsPrev() as CreatedProjectPrev[];
        return projects;
    }
}