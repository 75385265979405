import { GET_COMPANY_CONTACTS_URL } from "src/data/apiUrls";
import { ApiCompanyContactPrev } from "src/data/models/project/ApiCompanyContactPrev";
import { CustomError, DataResponse, errorCodeEnum, errorMessageEnum, IHttpClient } from "src/domain";
import { ICompanyRemoteDataSource } from "./ICompanyRemoteDataSource";

export class CompanyRemoteDataSourceImp implements ICompanyRemoteDataSource {
    constructor(
        private httpClient: IHttpClient
    ){}

    async getCompanyContactsPrev(): DataResponse<ApiCompanyContactPrev[]> {
        try {
            const createdApiCompanyContactPrevJson = await this.httpClient.get(GET_COMPANY_CONTACTS_URL) as any[]

            const createdApiCompanyContactPrev = createdApiCompanyContactPrevJson.map((apiCompanyJson: any) => ApiCompanyContactPrev.fromJSON(apiCompanyJson))

            return createdApiCompanyContactPrev;
        } catch (error) {
            throw new CustomError(errorCodeEnum.SERVER_ERROR, errorMessageEnum.GET_COMPANY_CONTACTS_ERROR);
        }
    }
}