import { QuoteProjectPrev } from "src/domain/entities/QuoteProjectPrev";
import { IProjectRepository } from "src/domain/repositories/IProjectRepository";
import { DataResponse } from "src/domain/utils";

export class GetQuoteProjectsPrevUseCase {
    constructor(
        private projectRepository: IProjectRepository,
    ) {}

    async execute(): DataResponse<QuoteProjectPrev[]> {
        const projects = await this.projectRepository.getQuoteProjectsPrev() as QuoteProjectPrev[];
        return projects;
    }
}