/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import FieldsInfo from "src/presentation/components/UI/FieldsInfo";
import { StyleType } from "src/presentation/types";

const FLEX_TOTAL = 12;

const fields = [
  {name: "type", flex: 2.5 / FLEX_TOTAL},
  {name: "price", flex: 1.5 / FLEX_TOTAL},
  {name: "estimated start", flex: 2 / FLEX_TOTAL},
  {name: "estimated end", flex: 2 / FLEX_TOTAL},
  {name: "payment terms", flex: 2 / FLEX_TOTAL},
  {name: "", flex: 2 / FLEX_TOTAL}, // empty field to fill the buttons space
]

const QuotePrevFieldsInfo = () => {
  return (
    <FieldsInfo fields={fields} css={styles.mainContainer}/>
  );
};

const styles: StyleType = {
  mainContainer: css({
    gap: "10px",
    padding: "0 20px",
  }),
};

export default QuotePrevFieldsInfo;
