/** @jsxImportSource @emotion/react */
import React from "react";

import { Button } from "src/presentation/components/UI";

import { css } from "@emotion/react";

import { ReactComponent as LoginTitle } from "src/presentation/assets/images/login-title.svg";
import { ReactComponent as KeyIcon } from "src/presentation/assets/icons/key-icon.svg";
import TextField from "src/presentation/components/UI/TextField";
import InputField from "src/presentation/components/UI/InputField";

import { colors } from "src/presentation/theme";
import { BREAKPOINTS } from "src/presentation/breakpoints";

import useViewModel from "./ForgotPasswordScreenViewModel";

const authImage = require("src/presentation/assets/images/login-background.png");

type Props = {
  onGoToLogin: () => void;
  onResetPassword: (email: string) => void;
};

const ForgotPasswordScreenView = ({
  onGoToLogin,
  onResetPassword: onFinishResetPassword,
}: Props) => {
  const {
    loading,
    error,
    isError,
    email,
    onResetPassword,
    onResetError,
    onChangeEmail,
  } = useViewModel();

  const emptyFields = email.length === 0;

  const resetPasswordHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onResetPassword().then(() => onFinishResetPassword(email)).catch();
  };

  return (
    <div css={styles.mainContainer}>
      <div css={styles.imageContainer}>
        <img css={styles.image} src={authImage} alt="Auth Background" />
      </div>

      <div css={styles.forgotPasswordContainer}>
        <div css={styles.forgotPasswordInnerContainer}>
          <div css={styles.titleContainer}>
            <LoginTitle css={{ width: "100%" }} />
          </div>

          <div css={styles.titleContainer}>
            <TextField
              customFontSize="30px"
              alignCenter
              color="darkGray"
              fontWeight="bold"
            >
              Forgot password?
            </TextField>
            <TextField fontSize="extraSmall" alignCenter color="darkGray">
              To reset your password, you need your email or mobile number that
              can be authenticated
            </TextField>
          </div>

          <div css={styles.keyIconContainer}>
            <KeyIcon />
          </div>

          <form css={styles.inputsContainer} onSubmit={resetPasswordHandler}>
            <InputField
              required
              value={email}
              onChange={(e) => onChangeEmail(e.target.value)}
              type="email"
              label="Email"
              height="50px"
              width="100%"
              onFocus={onResetError}
            />

            <Button
              disabled={emptyFields}
              loading={loading}
              height="50px"
              width="100%"
              color="primary"
              type="submit"
            >
              RESET PASSWORD
            </Button>
          </form>

          <Button
            height="50px"
            width="100%"
            color="primaryLight"
            onClick={onGoToLogin}
          >
            BACK TO LOGIN
          </Button>

          {isError && (
            <TextField fontSize="extraSmall" color="error">
              {error?.message}
            </TextField>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainContainer: css({
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: colors.white,
  }),
  imageContainer: css({
    flex: 3 / 7,
    [BREAKPOINTS.MEDIUM]: {
      display: "none",
    },
  }),
  forgotPasswordContainer: css({
    flex: 4 / 7,
    display: "grid",
    placeItems: "center",
    [BREAKPOINTS.MEDIUM]: {
      flex: 1,
    },
  }),
  forgotPasswordInnerContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "60%",
    maxWidth: "350px",
    gap: "30px",
  }),
  titleContainer: css({
    display: "grid",
    placeItems: "center",
    gap: "10px",
  }),
  keyIconContainer: css({
    display: "grid",
    placeItems: "center",
  }),
  inputsContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  }),
  image: css({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }),
};

export default ForgotPasswordScreenView;
