import { IAuthRepository } from "src/domain/repositories";

export class SendResetPasswordEmailUseCase {
    constructor(
        private authRepository: IAuthRepository,
    ){}

    async execute(email: string) {
        return await this.authRepository.sendResetPasswordEmail(email);
    }
}