/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { TextField } from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";
import { colors } from "src/presentation/theme";

type Props = {
  title: string;
  selected?: boolean;
  onClick?: () => void;
};

const TabButton = ({ title, selected, onClick }: Props) => {
  return (
    <div
      css={[styles.mainContainer, selected ? styles.mainContainerSelected : {}]}
      onClick={onClick}
    >
      <TextField
        fontSize="small"
        fontWeight="bold"
        color={selected ? "secondary" : "darkGrayText"}
      >
        {title.toUpperCase()}
      </TextField>
    </div>
  );
};

const bottomBorderWidth = 2;

const styles: StyleType = {
  mainContainer: css({
    padding: "15px",
    cursor: "pointer",

    borderBottom: `${bottomBorderWidth}px solid transparent`,
    marginBottom: `-${bottomBorderWidth}px`,

    transition: "all 0.2s ease-in-out",
  }),
  mainContainerSelected: css({
    borderBottom: `${bottomBorderWidth}px solid ${colors.secondary}`,
  }),
};

export default TabButton;
