/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import HeaderContainer from "src/presentation/components/HeaderProfile/HeaderContainer";
import {
  ActionButtonWithIcon,
  LoadingCover,
  ModalContainer,
  TextField,
} from "src/presentation/components/UI";
import Card from "src/presentation/components/UI/Card";
import { StyleType } from "src/presentation/types";
import MediaAndFilesTab from "./components/MediaAndFilesTab";
import PersonInfoContainer from "./components/PersonInfoContainer";
import SummaryAndLocationTab from "./components/SummaryAndLocationTab";
import { TabsContainer } from "src/presentation/components/UI";

import useViewModel from "./CreatedProjectScreenViewModel";
import QuoteList from "./components/QuoteList";
import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons";
import ScopeOfWorkForm from "src/presentation/components/CreateProjectForm/ScopeOfWorkForm";
import AssignContactModal from "./components/AssignContactModal";
import FormProvider from "./context/FormProvider";
import UpdateProjectInfoModal from "./components/UpdateProjectInfoModal";

const CreatedProjectScreenView = () => {
  const {
    data,
    isLoading,
    error,
    isError,
    onOpenLocation,
    formData,
    isLoadingForm,
    isLoadingUpdateInfo,
    isLoadingUpdateContacts,
    fieldValues,
    onChangeValues,
    onUpdateInfo,
    onUpdateContacts,
    onLoadInfoForm,
    onLoadContactsForm,
  } = useViewModel();
  const [modalOpened, setModalOpened] = useState<number | null>(null);

  if (isLoading) return <LoadingCover />;

  if (isError) return <div>Error: {error!.message}</div>;

  const openEditContactsModalHandler = () => {
    onLoadContactsForm();
    setModalOpened(0);
  };

  const openEditInfoModalHandler = () => {
    onLoadInfoForm();
    setModalOpened(1);
  };

  const closeModalHandler = () => {
    setModalOpened(null);
  };

  const modals = [
    <AssignContactModal onClose={closeModalHandler} />,
    <UpdateProjectInfoModal onClose={closeModalHandler} />,
  ];

  return (
    <FormProvider
      value={{
        formData,
        isLoadingForm,
        isLoadingUpdateInfo,
        isLoadingUpdateContacts,
        fieldValues,
        onChangeValues,
        onSubmitInfoForm: onUpdateInfo,
        onSubmitContactsForm: onUpdateContacts,
      }}
    >
      <ModalContainer
        opened={modalOpened != null}
        modal={modalOpened != null ? modals[modalOpened] : <></>}
        onCloseModal={closeModalHandler}
      >
        <div css={styles.mainContainer}>
          <HeaderContainer>
            <div>
              <TextField fontWeight="bold" customFontSize={32} color="darkGray">
                {data.project!.title}
              </TextField>
              <TextField fontSize="mediumLarge" color="darkGray">
                {data.project!.typeOfWork}
              </TextField>
            </div>
          </HeaderContainer>

          <Card>
            <div css={styles.cardContainer}>
              <div css={styles.contactCardContainer}>
                <PersonInfoContainer
                  title="Site supervisor"
                  subtitle={data.project!.siteSupervisorName}
                />
              </div>
              <div css={styles.contactCardContainer}>
                <PersonInfoContainer
                  title="Billing contact"
                  subtitle={data.project!.billingContactName}
                />
              </div>
              <div css={styles.editContactsContainer}>
                <ActionButtonWithIcon
                  onClick={openEditContactsModalHandler}
                  icon={faPenToSquare}
                >
                  Edit Contacts
                </ActionButtonWithIcon>
              </div>
            </div>
          </Card>

          <TabsContainer
            tabs={[
              {
                title: "quotes",
                content: <QuoteList quotes={data.project?.quotesPrev || []} />,
              },
              {
                title: "summary & location",
                content: (
                  <SummaryAndLocationTab
                    scope={data.project!.scope}
                    materialsBeProvided={data.project!.materialBeProvided}
                    estimatedStartDate={data.project!.estimatedStartDate}
                    estimatedEndDate={data.project!.estimatedEndDate}
                    locationName={data.project!.location}
                    locationCoordinates={data.project!.locationCoordinates}
                    deadline={data.project!.deadline}
                    squareFeet={data.project!.squareFeet}
                    budget={data.project!.budget}
                    onOpenLocation={onOpenLocation}
                  />
                ),
              },
              {
                title: "media & files",
                content: (
                  <MediaAndFilesTab
                    imageUrls={data.project!.imageUrls}
                    videoUrls={data.project!.videoUrls}
                    fileUrls={data.project!.fileUrls}
                  />
                ),
              },
            ]}
            rightContent={
              <ActionButtonWithIcon
                onClick={openEditInfoModalHandler}
                icon={faPenToSquare}
              >
                Edit Info
              </ActionButtonWithIcon>
            }
            showRightContentOnlyInTabs={[1]}
          />
        </div>
      </ModalContainer>
    </FormProvider>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    gap: "30px",
  }),
  cardContainer: css({
    display: "flex",
    alignItems: "center",
    padding: "20px 30px",
    gap: "100px",

    [BREAKPOINTS.MEDIUM_LARGE]: {
      flexDirection: "column",
      gap: "20px",
      alignItems: "center",
    },
  }),
  contactCardContainer: css({
    [BREAKPOINTS.MEDIUM_LARGE]: {
      marginRight: "auto",
    },
  }),
  editContactsContainer: css({
    marginLeft: "auto",
    [BREAKPOINTS.MEDIUM_LARGE]: {
      marginLeft: 0,
    },
  }),
};

export default CreatedProjectScreenView;
