/** @jsxImportSource @emotion/react */
import React from "react";

import { Button } from "src/presentation/components/UI";

import { css } from "@emotion/react";

import { ReactComponent as LoginTitle } from "src/presentation/assets/images/login-title.svg";
import TextField from "src/presentation/components/UI/TextField";
import InputField from "src/presentation/components/UI/InputField";

import useViewModel from "./LoginScreenViewModel";
import { colors, fonts } from "src/presentation/theme";
import { Link } from "react-router-dom";
import CheckBox from "src/presentation/components/UI/CheckBox";
import { BREAKPOINTS } from "src/presentation/breakpoints";

const loginImage = require("src/presentation/assets/images/login-background.png");

const LoginScreenView = () => {
  const {
    onLogin,
    loading,
    error,
    isError,
    email,
    password,
    rememberMe,
    onSwitchRememberMe,
    onResetError,
    onChangeEmail,
    onChangePassword,
  } = useViewModel();

  const emptyFields = email.length === 0 || password.length === 0;

  const loginHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onLogin();
  };

  return (
    <div css={styles.mainContainer}>
      <div css={styles.imageContainer}>
        <img css={styles.image} src={loginImage} alt="Login Background" />
      </div>

      <div css={styles.loginContainer}>
        <div css={styles.loginInnerContainer}>
          <div css={styles.titleContainer}>
            <LoginTitle css={{ width: "100%" }} />
          </div>

          <div css={styles.titleContainer}>
            <TextField customFontSize="30px" alignCenter color="darkGray" fontWeight="bold">
              Welcome Back
            </TextField>
            <TextField fontSize="extraSmall" alignCenter color="darkGray">
              Welcome to UBILD. Your virtual project assistant and new best friend
            </TextField>
          </div>

          <form css={styles.inputsContainer} onSubmit={loginHandler}>
            <InputField
              required
              value={email}
              onChange={(e) => onChangeEmail(e.target.value)}
              type="email"
              label="Email"
              height="50px"
              width="100%"
              onFocus={onResetError}
            />

            <InputField
              required
              value={password}
              onChange={(e) => onChangePassword(e.target.value)}
              type="password"
              label="Password"
              height="50px"
              width="100%"
              onFocus={onResetError}
            />

            <div css={styles.rememberButtonContainer}>
              <div css={styles.rememberMeContainer}>
                <CheckBox onClick={onSwitchRememberMe} checked={rememberMe} color="primary" />
                <TextField fontSize="extraSmall" fontWeight="light" color="darkGray">Remember me</TextField>
              </div>

              <Link css={styles.forgotPasswordLink} to="/forgot-password">Forgot password?</Link>
            </div>

            <Button
              disabled={emptyFields}
              loading={loading}
              height="50px"
              width="100%"
              color="primary"
              onClick={onLogin}
            >
              LOGIN
            </Button>
          </form>

          <TextField fontSize="extraSmall" fontWeight="light">
            You don't have an account yet?
            <Link css={styles.registerLink} to="/sign-up">
              &nbsp;Sign up
            </Link>
          </TextField>

          {isError && (
            <TextField fontSize="extraSmall" color="error">
              {error?.message}
            </TextField>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainContainer: css({
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: colors.white,
  }),
  imageContainer: css({
    flex: 3 / 7,
    [BREAKPOINTS.MEDIUM]: {
      display: "none",
    },
  }),
  loginContainer: css({
    flex: 4 / 7,
    display: "grid",
    placeItems: "center",
    [BREAKPOINTS.MEDIUM]: {
      flex: 1,
    },
  }),
  loginInnerContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "60%",
    maxWidth: "350px",
    gap: "30px",
  }),
  titleContainer: css({
    display: "grid",
    placeItems: "center",
    gap: "10px",
  }),
  inputsContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  }),
  rememberButtonContainer: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  rememberMeContainer: css({
    display: "flex",
    alignItems: "center",
    gap: "15px",
  }),
  forgotPasswordLink: css({
    fontSize: fonts.sizes.extraSmall,
    color: colors.darkGray,
  }),
  buttonsContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  }),
  registerLink: css({
    color: colors.primary,
    fontSize: fonts.sizes.extraSmall,
  }),
  image: css({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }),
};

export default LoginScreenView;
