import { InvoicePrev } from "src/domain/entities/InvoicePrev";

export class ApiInvoice {
  constructor(
    readonly objectID: string,
    readonly name: string,
    readonly title: string,
    readonly dateCreated: number, //timestamp
    readonly dateLastEdited: number, //timestamp
    readonly dateIssued: number, //timestamp
    readonly dateDue: number, //timestamp

    /// There are more fields that now are unnecessary
    
    readonly Fields: {
      fieldID: number;
      fieldValue: string;
      optionID: number;
    }[],
    readonly Relations: {
      objectTypeID: number;
      objectID: number;
      name: string;
      relationType: string;
      isActive: boolean;
    }[]
  ) {}

  getFieldById(id: number) {
    const searchedField = this.Fields.find(
      (field: any) => field.fieldID === id
    );
    return searchedField;
  }

  getRelationByType(type: string) {
    const linkRelation = this.Relations.find(
      (r: any) => r.relationType === type,
    );

    return linkRelation?.name
      ? linkRelation.name?.replace(/&amp;/, '&')
      : null;
  }

  toDomain(): InvoicePrev {
    const address = this.getFieldById(9988) ? this.getFieldById(9988)!.fieldValue : "";
    const totalAmount = this.getFieldById(947) ? +this.getFieldById(947)!.fieldValue : 0;
    const status = this.getFieldById(958) ? this.getFieldById(958)!.fieldValue : "";
    const paymentLink = this.getRelationByType("paymentlink") ?? undefined;
    const downloadLink = this.getRelationByType("pdflink") ?? undefined;
    const isCompleted = status === "Completed"
    const isCancelled = status === "Cancelled"

    return new InvoicePrev(
        this.objectID,
        this.objectID,
        this.name,
        address,
        new Date(this.dateIssued * 1000),
        totalAmount,
        status,
        isCompleted,
        isCancelled,
        paymentLink,
        downloadLink
    );
  }

  static fromJSON(json: any): ApiInvoice {
    return Object.setPrototypeOf(json, ApiInvoice.prototype) as ApiInvoice;
  }
}
