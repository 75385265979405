/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from "@emotion/react";
import React from "react";
import { TextField } from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";
import TitleWithContent from "./TitleWithContent";

type Props = {
  title: string;
  text: string;
  customCss?: Interpolation<Theme>;
};

const TitleWithText = ({ title, text, customCss }: Props) => {
  return (
    <TitleWithContent title={title} customCss={customCss}>
      <TextField color="darkGrayText" fontSize="small" fontWeight="light">
        {text}
      </TextField>
    </TitleWithContent>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }),
};

export default TitleWithText;
