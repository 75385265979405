/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import { Button, Card } from "src/presentation/components/UI";
import { OptionType } from "src/presentation/components/UI/DropDownMenu";
import { StyleType } from "src/presentation/types";
import AssignContactsStep from "./AssignContactsStep";
import FormProgressBar from "./FormProgressBar";
import LocationStep from "./LocationStep";
import ScopeOfWorkStep from "./ScopeOfWorkStep";
import UploadsStep from "./UploadsStep";
import useFormContext from "../context/useFormContext";

const CreateProjectForm = () => {
  const {fieldValues, isLoadingForm, onSubmitForm} = useFormContext();

  const [formStep, setFormStep] = useState(0);
  const stepOneCompleted =
    (!!fieldValues.address &&
    !!fieldValues.postalCode &&
    !fieldValues.postalCodeMatchError &&
    !!fieldValues.province &&
    !!fieldValues.streetView) || (fieldValues.selectedJobSiteId !== null);

  const stepTwoCompleted =
    !!fieldValues.projectType &&
    !!fieldValues.scopeOfWork &&
    fieldValues.materialsBeProvided !== undefined &&
    !!fieldValues.startDate &&
    !!fieldValues.endDate &&
    !!fieldValues.quoteDeadline;

  const stepFourCompleted = !!fieldValues.onSiteContact && !!fieldValues.billingContact;

  const nextStepDisabled =
    (formStep === 0 && !stepOneCompleted) ||
    (formStep === 1 && !stepTwoCompleted) ||
    (formStep === 3 && !stepFourCompleted);

  const steps = [
    <LocationStep/>,
    <ScopeOfWorkStep/>,
    <UploadsStep/>,
    <AssignContactsStep/>,
  ];

  const nextStepHandler = () => {
    if(formStep === steps.length - 1) {
      onSubmitForm();
    } else {
      setFormStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
    }
  };

  const prevStepHandler = () => {
    setFormStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  return (
    <div css={styles.mainContainer}>
      <div css={styles.formProgressBarContainer}>
        <FormProgressBar
          currentStep={formStep}
          steps={["Location", "Scope of work", "Uploads", "Assign contacts"]}
        />
      </div>

      <Card customCss={styles.formContainer}>{steps[formStep]}</Card>

      <div css={styles.buttonsContainer}>
        {formStep > 0 && (
          <Button
            onClick={prevStepHandler}
            color="error"
            customCss={[styles.actionButton, { marginRight: "auto" }]}
            height={50}
          >
            BACK
          </Button>
        )}
        {formStep < steps.length && (
          <Button
            loading={isLoadingForm}
            onClick={nextStepHandler}
            customCss={[styles.actionButton, { marginLeft: "auto" }]}
            height={50}
            disabled={nextStepDisabled || isLoadingForm}
          >
            {formStep === steps.length - 1 ? "UPLOAD" : "CONTINUE"}
          </Button>
        )}
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    width: "100%",
    height: "100%",
  }),
  formProgressBarContainer: css({
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  }),
  formContainer: css({
    display: "flex",
    height: "100%",
    minHeight: "580px",
    padding: "40px",
  }),
  buttonsContainer: css({
    display: "flex",
    alignItems: "center",
    gap: "20px",
  }),
  actionButton: css({
    width: "50%",
    maxWidth: "215px",
  }),
};

export default CreateProjectForm;
