/** @jsxImportSource @emotion/react */
import { Interpolation, Theme } from "@emotion/react";
import { IconName, IconPrefix, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

import React from "react";
import { colors } from "src/presentation/theme";

type Props = {
  weight?: IconPrefix;
  icon: FontAwesomeIconProps['icon'],
  size?: SizeProp;
  color?: keyof typeof colors;
} & React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & {
    css?: Interpolation<Theme>;
  };

const Icon = ({weight = "far", size="sm", icon, color = "pureBlack", ...props}: Props) => {
  return <FontAwesomeIcon size={size} icon={icon} color={colors[color]} />;
};

export default Icon;
