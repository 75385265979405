/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import Card from "./Card";
import TextField from "./TextField";

import { ReactComponent as FileIcon } from "src/presentation/assets/icons/file-icon.svg";
import { ReactComponent as FileDocIcon } from "src/presentation/assets/icons/file-doc-icon.svg";
import { ReactComponent as FileExcelIcon } from "src/presentation/assets/icons/file-excel-icon.svg";
import { ReactComponent as FileImgIcon } from "src/presentation/assets/icons/file-img-icon.svg";
import { ReactComponent as FilePdfIcon } from "src/presentation/assets/icons/file-pdf-icon.svg";
import { ReactComponent as FileVideoIcon } from "src/presentation/assets/icons/file-video-icon.svg";

import { faCircleDown } from "@fortawesome/pro-regular-svg-icons";

import { BREAKPOINTS } from "src/presentation/breakpoints";
import Icon from "./Icon";
import { colors } from "src/presentation/theme";

const fileIconFromFileUrl = (fileUrl: string) => {
  const splittedFileUrl = fileUrl.split(".");
  const fileType: string | undefined =
    splittedFileUrl[splittedFileUrl.length - 1] ?? undefined;

  switch (fileType) {
    case "doc": {
      return <FileDocIcon />;
    }
    case "xlsx": {
      return <FileExcelIcon />;
    }
    case "jpg": 
    case "jpeg": 
    case "png": {
      return <FileImgIcon />;
    }
    case "pdf": {
      return <FilePdfIcon />;
    }
    case ".mp4": {
      return <FileVideoIcon />;
    }
    default:
      return <FileIcon />;
  }
};

type Props = {
  title: string;
  fileUrl: string;
};

const FilePrev = ({ title, fileUrl }: Props) => {
  const downloadFileHandler = () => {
    window.open(fileUrl);
  };

  return (
    <Card>
      <div css={styles.mainContainer}>
        {fileIconFromFileUrl(fileUrl)}

        <div css={styles.innerContainer}>
          <TextField fontSize="small" color="darkGrayText">
            {title}
          </TextField>

          <TextField fontWeight="light" fontSize="small" color="darkGrayText">
            {/* TODO: add file size */}
          </TextField>

          <TextField fontWeight="light" fontSize="small" color="darkGrayText">
            {/* TODO: add file date */}
          </TextField>
        </div>

        <div onClick={downloadFileHandler} css={styles.iconContainer}>
          <Icon
            
            icon={faCircleDown}
            size="xl"
            color="mediumGray"
          />
        </div>
      </div>
    </Card>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    padding: "12px 22px",
    width: "100%",
    alignItems: "center",
  }),
  innerContainer: css({
    display: "flex",
    flex: 1,
    margin: "0 30px",
    alignItems: "center",
    justifyContent: "space-between",

    [BREAKPOINTS.SMALL]: {
      margin: "0 15px",
    },
  }),
  iconContainer: css({
    cursor: "pointer",

    svg: {
      transition: "color 0.2s ease-in-out",
    },

    ":hover svg": {
      color: colors.primary,
    },
  }),
};

export default FilePrev;
