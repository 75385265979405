/** @jsxImportSource @emotion/react */
import React from "react";

import { Button } from "src/presentation/components/UI";

import { css } from "@emotion/react";

import { ReactComponent as LoginTitle } from "src/presentation/assets/images/login-title.svg";
import { ReactComponent as EmailIcon } from "src/presentation/assets/icons/email-icon.svg";
import TextField from "src/presentation/components/UI/TextField";

import { colors } from "src/presentation/theme";
import { BREAKPOINTS } from "src/presentation/breakpoints";

import useViewModel from "./CheckEmailScreenViewModel";

const authImage = require("src/presentation/assets/images/login-background.png");

type Props = {
  onGoToLogin: () => void;
};

const CheckEmailScreenView = ({ onGoToLogin }: Props) => {
  const { email, onOpenEmail, onResendEmail } = useViewModel();

  return (
    <div css={styles.mainContainer}>
      <div css={styles.imageContainer}>
        <img css={styles.image} src={authImage} alt="Auth Background" />
      </div>

      <div css={styles.checkEmailContainer}>
        <div css={styles.checkEmailInnerContainer}>
          <div css={styles.titleContainer}>
            <LoginTitle css={{ width: "100%" }} />
          </div>

          <div css={styles.titleContainer}>
            <TextField
              customFontSize="30px"
              alignCenter
              color="darkGray"
              fontWeight="bold"
            >
              Check Your Email
            </TextField>
            <TextField fontSize="extraSmall" alignCenter color="darkGray">
              {`We have sent the reset password to the email address ${email}`}
            </TextField>
          </div>

          <div css={styles.emailIconContainer}>
            <EmailIcon />
          </div>

          <div css={styles.buttonsContainer}>
            <Button
              height="50px"
              width="100%"
              color="primary"
              onClick={onOpenEmail}
            >
              OPEN YOUR EMAIL
            </Button>

            <Button
              height="50px"
              width="100%"
              color="primaryLight"
              onClick={onGoToLogin}
            >
              BACK TO LOGIN
            </Button>
          </div>

          <TextField alignCenter fontSize="extraSmall" fontWeight="light">
            You have not received the email?
            <button onClick={onResendEmail}>
              <TextField
                fontSize="extraSmall"
                fontWeight="light"
                color="secondary"
              >
                &nbsp;Resend
              </TextField>
            </button>
          </TextField>
        </div>
      </div>
    </div>
  );
};

const styles = {
  mainContainer: css({
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: colors.white,
  }),
  imageContainer: css({
    flex: 3 / 7,
    [BREAKPOINTS.MEDIUM]: {
      display: "none",
    },
  }),
  checkEmailContainer: css({
    flex: 4 / 7,
    display: "grid",
    placeItems: "center",
    [BREAKPOINTS.MEDIUM]: {
      flex: 1,
    },
  }),
  checkEmailInnerContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "60%",
    maxWidth: "350px",
    gap: "50px",
  }),
  titleContainer: css({
    display: "grid",
    placeItems: "center",
    gap: "10px",
  }),
  emailIconContainer: css({
    display: "grid",
    placeItems: "center",
  }),
  buttonsContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  }),
  image: css({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }),
};

export default CheckEmailScreenView;
