import { CompanyContactPrev } from "src/domain/entities/CompanyContactPrev";

export class ApiCompanyContactPrev {
  constructor(
    readonly id: number,
    readonly vonigo_id: string,
    readonly company_id: number,
    readonly name: string,
    readonly last_name: string,
    readonly email: string,
    readonly address: string | null,
    readonly phone: string,
    readonly type: string | null,
    readonly payment_method: string | null,
    readonly roles: string | null,
    readonly send_push_notification: number,
    readonly created_at: string, // JSON date format
    readonly updated_at: string, // JSON date format
    readonly vonigo_token: string,
    readonly job_count: number,
    readonly sendbird_id: string,
    readonly full_name: string
  ) {}

  toDomain(): CompanyContactPrev {
    return new CompanyContactPrev(this.id.toString(), this.full_name);
  }

  static fromJSON(json: any): ApiCompanyContactPrev {
    return Object.setPrototypeOf(
      json,
      ApiCompanyContactPrev.prototype
    ) as ApiCompanyContactPrev;
  }
}
