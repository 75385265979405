import { CreateJobSiteUseCase } from "../usecases/jobsite/CreateJobSiteUseCase";
import { GetJobSitesUseCase } from "../usecases/jobsite/GetJobSitesUseCase";

export class JobSiteInteractor {
    constructor(
        private getJobSitesUseCase: GetJobSitesUseCase,
        private createJobSiteUseCase: CreateJobSiteUseCase,
    ){}

    getJobSites() {
        return this.getJobSitesUseCase.execute();
    }

    createJobSite(address: string, postalCode: string, province: string, frontImg: File) {
        return this.createJobSiteUseCase.execute(address, postalCode, province, frontImg)
    }
}