/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import FieldsInfo from "src/presentation/components/UI/FieldsInfo";
import { StyleType } from "src/presentation/types";

const FLEX_TOTAL = 12;

const fields = [
  {name: "number", flex: 1 / FLEX_TOTAL},
  {name: "name", flex: 2 / FLEX_TOTAL},
  {name: "address", flex: 2.5 / FLEX_TOTAL},
  {name: "date", flex: 1.5 / FLEX_TOTAL},
  {name: "total", flex: 1 / FLEX_TOTAL},
  {name: "status", flex: 1.5 / FLEX_TOTAL},
  {name: "", flex: 2.5 / FLEX_TOTAL}, // empty field to fill the buttons space
]

const InvoiceFieldsInfo = () => {
  return (
    <FieldsInfo fields={fields} css={styles.mainContainer}/>
  );
};

const styles: StyleType = {
  mainContainer: css({
    gap: "10px",
    padding: "0 20px",
  }),
};

export default InvoiceFieldsInfo;
