/** @jsxImportSource @emotion/react */
import React from "react";
import { css, Interpolation, Theme } from "@emotion/react";
import TextField from "./TextField";
import { StyleType } from "src/presentation/types";
import { colors } from "src/presentation/theme";
import { BREAKPOINTS } from "src/presentation/breakpoints";

type Props = {
  flex: number;
  label: string;
  customCss?: Interpolation<Theme>
  children: React.ReactNode;
};

const CardFieldContainer = ({ label, flex, customCss, children }: Props) => {
  return (
    <div css={[styles.centeredFlexContainer, { flex }, customCss]}>
      <div css={styles.mobileVisible}>
        <TextField fontWeight="bold" fontSize="small" color="darkGrayText">
          {label.toUpperCase()}:&nbsp;
        </TextField>
      </div>

      {children}
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    padding: "15px 20px",
    backgroundColor: colors.pureWhite,
    borderRadius: "8px",
    boxShadow: "0px 2px 1px rgba(80, 92, 117, 0.05);",
    width: "100%",
    gap: "10px",

    [BREAKPOINTS.MEDIUM_LARGE]: {
      flexDirection: "column",
    },
  }),
  centeredFlexContainer: css({
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  }),
  mobileVisible: {
    display: "none",
    [BREAKPOINTS.MEDIUM_LARGE]: {
      display: "flex",
    },
  },
};

export default CardFieldContainer;
