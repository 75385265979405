import { GetInvoicesPrevUseCase } from "../usecases/invoice/GetInvoicesPrevUseCase";

export class InvoiceInteractor {
    constructor (
        private getInvoicesPrevUseCase: GetInvoicesPrevUseCase
    ){}

    getInvoicesPrev() {
        return this.getInvoicesPrevUseCase.execute();
    }
}