/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from "@emotion/react";
import React from "react";
import { TextField } from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";

type FieldInfo = {
  name: string;
  flex: number;
  customCss?: Interpolation<Theme>;
};

type Props = {
  fields: FieldInfo[];
} & React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement> & {
    css?: Interpolation<Theme>;
  };

const FieldsInfo = ({fields, css, ...props}: Props) => {
  return (
    <div css={[styles.mainContainer, css]} {...props}>
      {fields.map((field, i) => (
        <div key={i} css={[[styles.flexContainer, {flex: field.flex}, field.customCss]]}>
          <TextField fontWeight="bold" fontSize="small">
            {field.name.toUpperCase()}
          </TextField>
        </div>
      ))}
    </div>
  );
};

const FLEX_TOTAL = 10;

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
  }),
  flexContainer: css({
    display: "flex",
    alignItems: "center",
  }),
  typeOfWorkContainer: css({
    flex: 2 / FLEX_TOTAL,
  }),
};

export default FieldsInfo;
