import React, { useEffect, useState } from "react";
import { CustomError, CreatedProject, CreatedProjectPrev } from "src/domain";
import { projectInteractor, useAuth } from "src/presentation/di";

const QuoteProjectListScreenViewModel = () => {
  const [createdProjects, setCreatedProjects] = useState<CreatedProjectPrev[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<CustomError | null>(null);
  const {userLogged} = useAuth();

  useEffect(() => {
    getCreatedProjects();
  }, []);

  const getCreatedProjects = async () => {
    try {
      setIsLoading(true);
      const createdProjects =
        (await projectInteractor.getCreatedProjects()) as CreatedProject[];
      setCreatedProjects(createdProjects);
    } catch (error) {
      setError(error as CustomError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data: {
      createdProjects,
      userLogged,
    },
    isLoading,
    error,
    isError: !!error,
  };
};

export default QuoteProjectListScreenViewModel;
