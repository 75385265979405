import React from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import PageWithTitle from "../hoc/PageWithTitle";

import CreatedProjectListScreenView from "../pages/CreatedProjectListScreen/CreatedProjectListScreenView";
import CreateProjectScreen from "../pages/CreateProjectScreen/CreateProjectScreenView";
import CreatedProjectScreen from "../pages/CreatedProjectScreen/CreatedProjectScreenView";

const routes = {
  PROJECTS_LIST: "/",
  CREATED_PROJECT: "project",
  CREATE_PROJECT: "create"
};

const CreatedProjectNavigation = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path={routes.PROJECTS_LIST}
        element={
          <PageWithTitle title="My projects - Ubild">
            <CreatedProjectListScreenView
              onGoToEditProject={() => {}}
              onGoToViewQuotes={(projectId: string) => navigate(`${routes.CREATED_PROJECT}/${projectId}`)}
              onGoToCreateProject={() => navigate(`${routes.CREATE_PROJECT}`)}
            />
          </PageWithTitle>
        }
      />
      <Route
        path={`/${routes.CREATED_PROJECT}/:id`}
        element={
          <PageWithTitle title="Created Project - Ubild">
            <CreatedProjectScreen />
          </PageWithTitle>
        }
      />
      <Route
        path={`/${routes.CREATE_PROJECT}`}
        element={
          <PageWithTitle title="Create Project - Ubild">
            <CreateProjectScreen onGoBackToDashboard={() => navigate(`/`)}/>
          </PageWithTitle>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default CreatedProjectNavigation;
