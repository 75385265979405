import { User } from "src/domain";

export class ApiUser {
    constructor(
        readonly id: number,
        readonly name: string,
        readonly email: string,
        readonly phone: string,
        readonly image: string,
        readonly sendbird_id: string,
        readonly sendbird_token: string,
        readonly company: {
          id: number,
          vonigo_id: number,
          name: string,
          address: string,
          how_you_know_us: string,
          created_at: string,
          updated_at: string,
          deleted_at: null | string,
        },
        readonly job_sites: [any],
        readonly token: string,
        readonly last_name?: string,
    ){}

    toDomain(): User {
        const company = this.company ? {
            id: this.company.id,
            name: this.company.name,
            address: this.company.address,
        } : undefined
        return new User(
            this.id.toString(),
            this.name,
            this.last_name ?? "",
            this.email,
            this.phone,
            this.image,
            this.sendbird_id,
            this.sendbird_token,
            company
          )
    }

    static fromJson(json: any): ApiUser {
        return Object.setPrototypeOf(
            json,
            ApiUser.prototype
          ) as ApiUser;
    }
}