/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";

import {
  Button,
  InputField,
  TextField,
  WhiteCard,
} from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";
import { colors } from "src/presentation/theme";

import { BREAKPOINTS } from "src/presentation/breakpoints";
import { UploadQuoteData } from "src/domain/usecases/project/UploadProjectQuoteUseCase";
import { CustomError, DateFormatter, QuotePrev } from "src/domain";
import InputFile from "src/presentation/components/UI/InputFile";
import DeleteableItemList from "src/presentation/components/UI/DeleteableItemList";

type Props = {
  initialQuote?: QuotePrev,
  onUploadQuote: (data: UploadQuoteData) => Promise<void>;
  onCloseModal: () => void;
};

const UploadQuoteModal = ({ initialQuote, onUploadQuote, onCloseModal }: Props) => {
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [price, setPrice] = useState<number>(initialQuote?.price ?? 0);
  const [startDate, setStartDate] = useState<Date>(initialQuote?.projectStartDate ?? new Date());
  const [endDate, setEndDate] = useState<Date>(initialQuote?.projectEndDate ?? new Date());
  const [paymentTerms, setPaymentTerms] = useState<string>(initialQuote?.paymentTerms ?? "");
  const [files, setFiles] = useState<(File | string)[]>(initialQuote?.documents ?? []);

  const changeStartDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const today = new Date();
    const newStartDate = DateFormatter.fromYYYYMMDD(e.target.value);

    if (newStartDate < today)
      return alert("Start date must be grater than today");

    setStartDate(newStartDate);
  };

  const changeEndDateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const today = new Date();
    const newEndDate = DateFormatter.fromYYYYMMDD(e.target.value);

    if (newEndDate < today) return alert("End date must be grater than today");

    setEndDate(newEndDate);
  };

  const filedsCompleted = price && startDate && endDate && paymentTerms && files.length > 0;

  const uploadQuoteHandler = async () => {
    setLoadingUpload(true);
    try {
      if (endDate < startDate) return alert("End date must be greater than start date");

      await onUploadQuote({
        price: price!,
        startDate: startDate!,
        endDate: endDate!,
        paymentTerms: paymentTerms!,
        files,
      });

      alert("Quote uploaded succesfully!");
    } catch (error) {
      alert((error as CustomError).message);
    } finally {
      setLoadingUpload(false);
    }
  };

  const addNewFileHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const newFilesQty = e.target.files?.length;

    if(newFilesQty && newFilesQty > 1) return alert("You can upload max to 1 file")

    const newFiles = e.target.files;

    //Delete previous file before add the new one
    setFiles([]);

    if (newFilesQty && newFilesQty > 0) {
      for (let i = 0; i < newFilesQty; i++) {
        const file = newFiles?.item(i);

        if(file?.type !== "application/pdf") {
          alert("Only pdf files are allowed");
          break;
        }

        const existingFile = files.find(
          (existingFile) => (existingFile instanceof File) && existingFile.name === file?.name
        );
        if (file && !existingFile) {
          setFiles((prev) => [...prev, file]);
        } else if(existingFile instanceof File) {
          alert("File with same name already exists: " + existingFile!.name);
        }
      }
    }
  };

  const removeFile = (fileNameOrUrl: string) => {
    setFiles((prev) => prev.filter((file) => file instanceof File ? file.name !== fileNameOrUrl : file !== fileNameOrUrl));
  };

  return (
    <WhiteCard customCss={styles.mainContainer}>
      <TextField fontSize="medium" fontWeight="regular" color="mainTitles">
        {initialQuote ? "Update quote" : "Upload quote"}
      </TextField>
      <InputField
        label="Price"
        height="40px"
        type="number"
        value={price}
        onChange={(e) => setPrice(+e.target.value)}
        onFocus={(e) => e.target.select()}
      />
      <div css={styles.dateInputsContainer}>
        <InputField
          customCss={styles.input}
          label="Start date"
          height="40px"
          type="date"
          value={DateFormatter.toYYYYMMDD(startDate)}
          onChange={changeStartDateHandler}
        />
        <InputField
          customCss={styles.input}
          label="End date"
          height="40px"
          type="date"
          value={DateFormatter.toYYYYMMDD(endDate)}
          onChange={changeEndDateHandler}
        />
      </div>
      <InputField
        label="Payment Terms"
        height="40px"
        value={paymentTerms}
        onChange={(e) => setPaymentTerms(e.target.value)}
      />

      <InputFile accept="application/pdf" label="Upload files" innerLabel="PDF" onChange={addNewFileHandler}/>

      <DeleteableItemList requiredLabel="You must upload at least one file" items={files.map(file => file instanceof File ? file.name : file)} onRemoveItem={removeFile}/>

      <div css={styles.buttonsContainer}>
        <Button disabled={loadingUpload} onClick={onCloseModal} height="50px" width="50%" color="error">
          CANCEL
        </Button>
        <Button
          disabled={!filedsCompleted}
          loading={loadingUpload}
          height="50px"
          width="50%"
          color="primary"
          onClick={uploadQuoteHandler}
        >
          {initialQuote ? "UPDATE" : "UPLOAD"}
        </Button>
      </div>
    </WhiteCard>
  );
};

const styles: StyleType = {
  mainContainer: css({
    width: "50%",
    padding: "20px 30px",
    display: "flex",
    flexDirection: "column",
    gap: "25px",

    [BREAKPOINTS.MEDIUM_LARGE]: {
      width: "80%",
    },
  }),
  dateInputsContainer: css({
    display: "flex",
    justifyContent: "space-between",
    gap: "30px",

    [BREAKPOINTS.SMALL_MEDIUM]: {
      flexDirection: "column",
    },
  }),
  input: css({
    flex: 1,
    maxWidth: "250px",
  }),
  uploadFileContainer: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    width: "100%",
    border: `1px dashed ${colors.darkGrayTransparent50}`,
    borderRadius: "6px",
    gap: "5px",
    cursor: "pointer",
  }),
  htpmInput: css({
    display: "none",
  }),
  buttonsContainer: css({
    display: "flex",
    gap: "20px",
  }),
};

export default UploadQuoteModal;
