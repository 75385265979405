export class QuotePrev {
    constructor(
        readonly id: string,
        readonly title: string,
        readonly projectId: string,
        readonly companyId: string,
        readonly price: number,
        readonly paymentTerms: string,
        readonly projectStartDate: Date,
        readonly projectEndDate: Date,
        readonly documents: string[],
        readonly createdAt: Date,
        readonly updateAt: Date,
    ){}
}