import { CustomError, errorCodeEnum, errorMessageEnum } from "./CustomError";
import { DataResponse } from "./DataResponse";

export class FileUtils {
  /**
   * It receives a File and returns the base64 string of that file
   * @param file the file to be converted to base64
   * @returns the file in base64
   */
  static async fileToBase64(file: File): DataResponse<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(new CustomError(errorCodeEnum.SERVER_ERROR, errorMessageEnum.SERVER_ERROR));
    });
  }
}
