import { DataResponse, AuthData } from "src/domain";
import { IAuthLocalDataSource } from "./IAuthLocalDataSource";

const AUTH_DATA_KEY = "authData";

export class AuthLocalDataSourceImp implements IAuthLocalDataSource {
  
  /**
   * Get auth data from local storage or session storage
   */
  async getAuthData(): DataResponse<AuthData | null> {
    const jsonAuthData = localStorage.getItem(AUTH_DATA_KEY);
    if (jsonAuthData) {
      const authData = Object.setPrototypeOf(
        JSON.parse(jsonAuthData),
        AuthData.prototype
      ) as AuthData;
      return authData;
    } else {
      const jsonSessionAuthData = sessionStorage.getItem(AUTH_DATA_KEY);
      if (jsonSessionAuthData) {
        const authData = Object.setPrototypeOf(
          JSON.parse(jsonSessionAuthData),
          AuthData.prototype
        ) as AuthData;
        return authData;
      }
    }
    return null;
  }

  async setAuthData(authData: AuthData, remember: boolean): DataResponse<void> {
    if(remember) {
      localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(authData));
    } else {
      sessionStorage.setItem(AUTH_DATA_KEY, JSON.stringify(authData));
    }
  }

  async deleteAuthData(): DataResponse<void> {
    localStorage.removeItem(AUTH_DATA_KEY);
  }
}
