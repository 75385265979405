import { Project } from "src/domain/entities";
import { CreatedProject } from "src/domain/entities/CreatedProject";
import { IProjectRepository } from "src/domain/repositories/IProjectRepository";
import { DataResponse } from "src/domain/utils";

export class GetCreatedProjectByIdUseCase {
    constructor(
        private projectRepository: IProjectRepository,
    ) {}

    async execute(id: string): DataResponse<CreatedProject> {
        const project = await this.projectRepository.getCreatedProjectById(id) as CreatedProject;
        return project;
    }
}