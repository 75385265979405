import { InvoicePrev } from "src/domain/entities/InvoicePrev";
import { IInvoiceRepository } from "src/domain/repositories/IInvoiceRepository";
import { DataResponse } from "src/domain/utils";

export class GetInvoicesPrevUseCase {
    constructor (
        private invoiceRepository: IInvoiceRepository
    ){}

    async execute(): DataResponse<InvoicePrev[]> {
        return this.invoiceRepository.getInvoicesPrev();
    }
}