import { IInvoiceRepository } from "src/domain/repositories/IInvoiceRepository";
import { IInvoiceRemoteDataSource } from "./IInvoiceRemoteDataSource";
import { DataResponse } from "src/domain";
import { InvoicePrev } from "src/domain/entities/InvoicePrev";
import { ApiInvoice } from "src/data/models/invoice/ApiInvoice";

export class InvoiceRepositoryImp implements IInvoiceRepository {
    constructor (
        private invoiceRemoteDataSource: IInvoiceRemoteDataSource
    ){}

    async getInvoicesPrev(): DataResponse<InvoicePrev[]> {
        const apiInvoices = await this.invoiceRemoteDataSource.getInvoicesPrev() as ApiInvoice[];
        const invoices = apiInvoices.map(apiInvoice => apiInvoice.toDomain());
        return invoices;
    }
}