import { User } from "src/domain/entities";
import { IUserRepository } from "src/domain/repositories";
import { DataResponse } from "src/domain/utils";

export class UpdateUserDataUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(
    firstName?: string,
    lastName?: string,
    email?: string,
    phoneNumber?: string
  ): DataResponse<User> {
    const updatedUser = await this.userRepository.updateUserData(
      firstName,
      lastName,
      email,
      phoneNumber
    ) as User;

    await this.userRepository.setSavedUser(updatedUser);

    return updatedUser;
  }
}
