import { ApiCreatedProject } from "src/data/models/project/ApiCreatedProject";
import { ApiCreatedProjectPrev } from "src/data/models/project/ApiCreatedProjectPrev";
import { ApiQuoteProject } from "src/data/models/project/ApiQuoteProject";
import {
  CreatedProject,
  DataResponse,
  DateFormatter,
  ProjectPrev,
} from "src/domain";
import { CreatedProjectPrev } from "src/domain/entities/CreatedProjectPrev";
import { QuoteProject } from "src/domain/entities/QuoteProject";
import { QuoteProjectPrev } from "src/domain/entities/QuoteProjectPrev";
import { IProjectRepository } from "src/domain/repositories/IProjectRepository";
import { IProjectRemoteDataSource } from "./IProjectRemoteDataSource";
import { ApiQuoteProjectPrev } from "src/data/models/project/ApiQuoteProjectPrev";
import { QuotePrev } from "src/domain/entities/QuotePrev";
import { ApiQuotePrev } from "src/data/models/project/ApiQuotePrev";
import { ProjectType } from "src/domain/entities/ProjectType";
import { ApiProjectType } from "src/data/models/project/ApiProjectType";

export class ProjectRepositoryImp implements IProjectRepository {
  private quoteProjectsPrevCache: QuoteProjectPrev[] = [];
  private quoteProjectsCache: QuoteProject[] = [];

  private createdProjectsPrevCache: CreatedProjectPrev[] = [];
  private createdProjectsCache: CreatedProject[] = [];

  constructor(private projectRemoteDataSource: IProjectRemoteDataSource) {}

  async getCreatedProjectsPrev(): DataResponse<CreatedProjectPrev[]> {
    let projectsPrev: ProjectPrev[] = this.createdProjectsPrevCache;

    if (projectsPrev.length === 0) {
      const apiProjectsPrev =
        (await this.projectRemoteDataSource.getCreatedProjectsPrev()) as ApiCreatedProjectPrev[];
      projectsPrev = apiProjectsPrev.map((apiProject) => apiProject.toDomain());

      // Update cache
      this.createdProjectsPrevCache = projectsPrev;
    }

    return projectsPrev;
  }

  async getCreatedProjectById(id: string): DataResponse<CreatedProject> {
    let project: CreatedProject | undefined = this.createdProjectsCache.find(
      (project) => project.id === id
    );

    if (!project) {
      const apiProject =
        (await this.projectRemoteDataSource.getCreatedProjectById(
          id
        )) as ApiCreatedProject;
      project = apiProject.toDomain();

      // Add to cache
      this.createdProjectsCache.push(project);
    }

    return project;
  }

  async getQuoteProjectsPrev(): DataResponse<QuoteProjectPrev[]> {
    let projectsPrev: ProjectPrev[] = this.quoteProjectsPrevCache;

    if (projectsPrev.length === 0) {
      const apiProjectsPrev =
        (await this.projectRemoteDataSource.getQuoteProjectsPrev()) as ApiQuoteProjectPrev[];
      projectsPrev = apiProjectsPrev.map((apiProject) => apiProject.toDomain());

      // Update cache
      this.quoteProjectsPrevCache = projectsPrev;
    }

    return projectsPrev;
  }

  async getQuoteProjectById(id: string): DataResponse<QuoteProject> {
    let project: QuoteProject | undefined = this.quoteProjectsCache.find(
      (project) => project.id === id
    );

    if (!project) {
      const apiProject =
        (await this.projectRemoteDataSource.getQuoteProjectById(
          id
        )) as ApiQuoteProject;
      project = apiProject.toDomain();

      // Add to cache
      this.quoteProjectsCache.push(project);
    }

    return project;
  }

  async uploadProjectQuote(
    projectId: string,
    price: number,
    paymentTerms: string,
    projectStartDate: Date,
    projectEndDate: Date,
    fileUrls: string[]
  ): DataResponse<QuotePrev> {
    const apiQuotePrev = await this.projectRemoteDataSource.uploadProjectQuote(
      projectId,
      price,
      paymentTerms,
      projectStartDate,
      projectEndDate,
      fileUrls
    ) as ApiQuotePrev;

    this.quoteProjectsCache = this.quoteProjectsCache.filter(quoteProject => quoteProject.id !== projectId);

    return apiQuotePrev.toDomain();
  }

  async updateProjectQuote(quoteId: string, price: number, paymentTerms: string, projectStartDate: Date, projectEndDate: Date, fileUrls: string[]): DataResponse<QuotePrev> {
    const apiQuotePrev = await this.projectRemoteDataSource.updateProjectQuote(
      quoteId,
      price,
      paymentTerms,
      projectStartDate,
      projectEndDate,
      fileUrls
    ) as ApiQuotePrev;

    this.quoteProjectsCache = this.quoteProjectsCache.filter(quoteProject => quoteProject.vendorQuote?.id !== quoteId);

    return apiQuotePrev.toDomain();
  }

  async createProject(
    jobSiteId: string,
    projectType: string,
    scopeOfWork: string,
    budget: { min: number; max: number },
    materialsBeProvided: boolean,
    startDate: Date,
    endDate: Date,
    deadline: Date,
    squareFeet: number
  ): DataResponse<CreatedProject> {
    const apiCreatedProject =
      (await this.projectRemoteDataSource.createProject(
        jobSiteId,
        projectType,
        scopeOfWork,
        budget,
        materialsBeProvided,
        startDate,
        endDate,
        deadline,
        squareFeet
      )) as ApiCreatedProject;

    //Delete projects prev cache in order to fetch the projects list again
    this.deleteProjectsPrevCache();

    return apiCreatedProject.toDomain();
  }

  async updateProject(
    projectId: string,
    billingContactId?: string,
    siteSupervisorId?: string,
    documentUrls?: string[],
    mediaUrls?: string[],
    typeOfWork?: string,
    scope?: string,
    budget?: {min: number, max:number},
    materialBeProvided?: boolean,
    estimatedStartDate?: Date,
    estimatedEndDate?: Date,
    quoteDeadline?: Date,
    jobSiteId?: number,
    title?: string,
    squareFeet?: number
  ): DataResponse<CreatedProject> {
    const apiUpdatedCreatedProject =
      (await this.projectRemoteDataSource.updateProject(
        projectId,
        billingContactId,
        siteSupervisorId,
        documentUrls,
        mediaUrls,
        typeOfWork,
        scope,
        budget,
        materialBeProvided,
        estimatedStartDate,
        estimatedEndDate,
        quoteDeadline,
        jobSiteId,
        title,
        squareFeet,
      )) as ApiCreatedProject;

    //Delete projects prev cache in order to fetch the projects list again
    this.deleteProjectsPrevCache();
    this.deleteProjectCacheById(projectId);

    return apiUpdatedCreatedProject.toDomain();
  }

  async getProjectTypes(): DataResponse<ProjectType[]> {
    const apiProjectTypes = await this.projectRemoteDataSource.getProjectTypes() as ApiProjectType[];
    const projectTypes = apiProjectTypes.map(apiProjectType => apiProjectType.toDomain())
    return projectTypes;
  }

  private deleteProjectsPrevCache() {
    this.quoteProjectsPrevCache = [];
    this.createdProjectsPrevCache = [];
  }

  private deleteProjectCacheById(projectId: string) {
    this.createdProjectsCache = this.createdProjectsCache.filter(project => project.id !== projectId)
  }
}
