import React from "react";
import { useForm as useReactHookForm } from "react-hook-form";
import { emailRegex } from "src/presentation/utils/ExpressionMatcher";

type IFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

const useForm = () => {
  const { register, handleSubmit, formState, reset } =
    useReactHookForm<IFormValues>();

  const firstNameInput = register("firstName", {
    required: "First Name is required",
  });
  const lastNameInput = register("lastName", {
    required: "Last Name is required",
  });
  const emailInput = register("email", {
    required: "Email address is required",
    disabled: true,
  });
  const phoneNumberInput = register("phoneNumber", {
    required: "Phone number is required",
  });

  const setFormValuesHandler = (values: Partial<IFormValues>) => {
    reset(values);
  };

  const errors = {
    firstName: formState.errors.firstName?.message,
    lastName: formState.errors.lastName?.message,
    email: formState.errors.email?.message,
    phoneNumber: formState.errors.phoneNumber?.message,
  };

  return {
    inputs: {
      firstName: firstNameInput,
      lastName: lastNameInput,
      email: emailInput,
      phoneNumber: phoneNumberInput,
    },
    errors,
    onSetFormValues: setFormValuesHandler,
    onSubmit: handleSubmit,
  };
};

export default useForm;
