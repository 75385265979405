/** @jsxImportSource @emotion/react */
import React from "react";
import useViewModel from "./CreatedProjectListScreenViewModel";

import { StyleType } from "src/presentation/types";
import { css } from "@emotion/react";
import CreatedProjectFieldsInfo from "./components/CreatedProjecFieldstInfo";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import {
  ActionButtonWithPlusIcon,
  CreatedProjectPrev,
  LoadingCover,
  TextField,
} from "src/presentation/components/UI";
import HeaderContainer from "src/presentation/components/HeaderProfile/HeaderContainer";

type Props = {
  onGoToEditProject: (projectId: string) => void;
  onGoToViewQuotes: (projectId: string) => void;
  onGoToCreateProject: () => void;
};

const CreatedProjectListScreenView = ({ onGoToEditProject, onGoToViewQuotes, onGoToCreateProject }: Props) => {
  const { data, isLoading, error, isError } = useViewModel();

  if (isLoading) return <LoadingCover />;

  if (isError) return <div>{error?.message}</div>;

  return (
    <div css={styles.mainContainer}>
      <HeaderContainer customCss={styles.headerContainer}>
        <TextField fontWeight="bold" customFontSize={32} color="darkGray">
          {data.userLogged?.company?.name}
        </TextField>
      </HeaderContainer>

      <div css={styles.subHeaderContainer}>
      <TextField fontWeight="bold" fontSize="extraLarge" color="darkGray">Projects</TextField>
        <ActionButtonWithPlusIcon onClick={onGoToCreateProject} css={{marginLeft: "auto"}}>Create project</ActionButtonWithPlusIcon>
      </div>

      <div css={styles.projectFieldsInfoContainer}>
        <CreatedProjectFieldsInfo />
      </div>

      <div css={styles.projectsContainer}>
        {data.createdProjects.map((project) => (
          <CreatedProjectPrev
            key={project.id}
            project={project}
            onGoToEditProject={() => onGoToEditProject(project.id)}
            onGoToViewQuotes={() => onGoToViewQuotes(project.id)}
          />
        ))}
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    width: "100%",
    height: "max-content",
    minHeight: "100%",
    padding: "2rem 2rem",
  }),
  headerContainer: css({
    marginBottom: "50px",
    alignItems: "center",
    [BREAKPOINTS.MEDIUM]: {
      justifyContent: "center",
      marginBottom: "25px",
    },
  }),
  subHeaderContainer: css({
    display: "flex",
    marginBottom: 20,
  }),
  projectsContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  }),
  projectFieldsInfoContainer: css({
    marginBottom: "25px",
    width: "100%",

    [BREAKPOINTS.MEDIUM_LARGE]: {
      display: "none",
    },
  }),
};

export default CreatedProjectListScreenView;
