import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'
import ActionButtonWithIcon from './ActionButtonWithIcon'
import { type Props as ActionProps } from './ActionButton'

type Props = ActionProps

const ActionButtonWithPlusIcon = ({...props}: Props) => {
  return (
    <ActionButtonWithIcon {...props} icon={faPlus}/>
  )
}

export default ActionButtonWithPlusIcon