/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { CSSProperties } from "react";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";

type Props = {
  size: CSSProperties["height"];
  defaultBackgroundColor?: keyof typeof colors;
  src?: React.ImgHTMLAttributes<HTMLImageElement>["src"];
  alt?: React.ImgHTMLAttributes<HTMLImageElement>["alt"];
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const CircularImage = ({
  size,
  defaultBackgroundColor = "white",
  src,
  alt,
  ...props
}: Props) => {
  return (
    <div
      css={[
        styles.mainContainer,
        {
          backgroundColor: colors[defaultBackgroundColor],
          width: size,
          height: size,
          minWidth: size,
          minHeight: size,
        },
      ]}
    >
      {src && alt && <img css={styles.image} src={src} alt={alt} />}
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    borderRadius: "100%",
    overflow: "hidden",
  }),
  image: css({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  }),
};

export default CircularImage;
