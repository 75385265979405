/** @jsxImportSource @emotion/react */
import React from "react";
import DropDownMenu, {
  OptionType,
} from "src/presentation/components/UI/DropDownMenu";
import FormStepContainer from "./FormStepContainer";
import { ActionButtonWithPlusIcon } from "src/presentation/components/UI";
import { StyleType } from "src/presentation/types";
import { css } from "@emotion/react";
import useFormContext from "../context/useFormContext";

type Props = {
  onGoToCreateJobSite: () => void;
};

const SelectJobSiteForm = ({
  onGoToCreateJobSite,
}: Props) => {
  const {data, fieldValues, onChangeValues} = useFormContext();

  return (
    <FormStepContainer>
      <DropDownMenu
        defaultOptionLabel="Select Job Site..."
        value={fieldValues.selectedJobSiteId ?? ""}
        label="Project type"
        onChange={onChangeValues.onChangeSelectedJobSite}
        options={data.availableJobSites}
      />

      <div css={styles.buttonContainer}>
        <ActionButtonWithPlusIcon onClick={onGoToCreateJobSite}>
          Add new Location
        </ActionButtonWithPlusIcon>
      </div>
    </FormStepContainer>
  );
};

const styles: StyleType = {
  buttonContainer: css({
    width: "fit-content",
    margin: "0 auto",
  }),
};

export default SelectJobSiteForm;
