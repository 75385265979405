import { colors } from "../theme";

export default function getStatusColor(status: string) {
  switch (status.toLowerCase()) {
    case 'pending':
    case 'creating':
      return colors.secondary;
    case 'created':
    case 'completed':
      return colors.primary;
    case 'cancelled':
      return colors.error;
    default:
      return colors.pureBlack;
  }
}
