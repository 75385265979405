import React from "react";

import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ForgotPasswordScreen from "src/presentation/pages/ForgotPasswordScreen/ForgotPasswordScreenView";
import PageWithTitle from "../hoc/PageWithTitle";
import CheckEmailScreenView from "../pages/CheckEmailScreen/CheckEmailScreenView";
import ResetPasswordScreenView from "../pages/ResetPasswordScreen/ResetPasswordScreenView";
import ResetPasswordSuccessScreenView from "../pages/ResetPasswordSuccessScreen/ResetPasswordSuccessScreenView";

const routes = {
  FORGOT_PASSWORD: "/",
  CHECK_EMAIL: "check-email",
  // RESET_PASSWORD: "reset-password",
  // SUCCESS: "success",
};

type Props = {
  onGoToLogin: () => void;
};

const ForgotPasswordNavigation = ({ onGoToLogin }: Props) => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path={`${routes.FORGOT_PASSWORD}`}
        element={
          <PageWithTitle title="Forgot password - Ubild">
            <ForgotPasswordScreen
              onResetPassword={(email) =>
                navigate(`${routes.CHECK_EMAIL}/${email}`)
              }
              onGoToLogin={onGoToLogin}
            />
          </PageWithTitle>
        }
      />
      <Route
        path={`/${routes.CHECK_EMAIL}/:email`}
        element={
          <PageWithTitle title="Check email - Ubild">
            <CheckEmailScreenView onGoToLogin={onGoToLogin} />
          </PageWithTitle>
        }
      />
      {/* <Route
        path={`/${routes.RESET_PASSWORD}/:token`}
        element={
          <PageWithTitle title="Reset password - Ubild">
            <ResetPasswordScreenView onResetPassword={() => navigate(`${routes.SUCCESS}`)} onGoToLogin={onGoToLogin}/>
          </PageWithTitle>
        }
      />
      <Route
        path={`/${routes.SUCCESS}`}
        element={
          <PageWithTitle title="Reset password Success - Ubild">
            <ResetPasswordSuccessScreenView onContinue={onGoToLogin} onGoToLogin={onGoToLogin} />
          </PageWithTitle>
        }
      /> */}
      <Route path="*" element={<Navigate to="/forgot-password" />} />
    </Routes>
  );
};

export default ForgotPasswordNavigation;
