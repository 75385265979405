/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import { css } from "@emotion/react";
import {
  QuotePrev,
  TextField,
} from "src/presentation/components/UI";
import { QuotePrev as Quote } from "src/domain/entities/QuotePrev";
import { StyleType } from "src/presentation/types";
import QuotePrevFieldsInfo from "./QuotePrevFieldsInfo";
import { BREAKPOINTS } from "src/presentation/breakpoints";

type Props = {
  quotes: Quote[];
};

const QuoteList = ({ quotes }: Props) => {
  return (
    <div css={styles.mainContainer}>
      <div css={styles.fieldsContainer}>
        {quotes.length > 0 && <QuotePrevFieldsInfo />}
      </div>

      {quotes.length > 0 ? (
        quotes.map((quote) => (
          <QuotePrev key={quote.id} quote={quote} onMessage={() => {}} />
        ))
      ) : (
        <TextField fontWeight="light">No quotes yet</TextField>
      )}
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "15px",
  }),
  fieldsContainer: css({
    [BREAKPOINTS.MEDIUM_LARGE]: {
      display: "none",
    },
  })
};

export default QuoteList;
