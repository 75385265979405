/** @jsxImportSource @emotion/react */
import { css, Interpolation, Theme } from "@emotion/react";
import React, { CSSProperties } from "react";
import { colors, fonts } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";
import TextField from "./TextField";

type Props = {
  height?: CSSProperties["height"];
  width?: CSSProperties["width"];
  label?: string;
  customCss?: Interpolation<Theme>;
} & React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const TextArea = ({ height, width, label, customCss, ...props }: Props) => {
  return (
    <div css={[styles.mainContainer, customCss]}>
      {label && (
        <TextField color="darkGray" fontSize="small" fontWeight="bold">
          {label}
        </TextField>
      )}
      <div css={[styles.inputContainer, { height, width }]}>
        <textarea css={styles.textarea} {...props} />
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  }),
  inputContainer: css({
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    border: "1px solid rgba(209, 211, 211, 0.5);",
    color: colors.darkGray,
    fontSize: fonts.sizes.extraSmall,
    ":focus-within": {
      borderColor: colors.primary,
    },
  }),
  textarea: css({
    padding: "5px 10px",
    margin: "2px",
    height: "calc(100% - 6px)",
    width: "calc(100% - 6px)",
  }),
};

export default TextArea;
