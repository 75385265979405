/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import { colors } from "src/presentation/theme";
import TextField from "./TextField";

export type OptionType = {
  value: string;
  label: string;
};

type Props = {
  label?: string;
  defaultOptionLabel?: string;
  options: OptionType[];
} & React.ClassAttributes<HTMLSelectElement> & React.SelectHTMLAttributes<HTMLSelectElement>;

const DropDownMenu = ({ label, defaultOptionLabel, options, ...props }: Props) => {
  const menuOptions = defaultOptionLabel ? [{label: defaultOptionLabel, value: ""}, ...options] : options;
  return (
    <div css={styles.mainContainer}>
      {label && (
        <TextField color="darkGray" fontSize="small" fontWeight="bold">
          {label}
        </TextField>
      )}
      <select css={styles.selectContainer} {...props}>
        {menuOptions.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  }),
  selectContainer: css({
    border: "1px solid " + colors.darkGrayTransparent50,
    padding: "13px 22px",
    borderRadius: "6px",
    width: "100%",
    cursor: "pointer",
  }),
};

export default DropDownMenu;
