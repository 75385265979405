/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import { StyleType } from "src/presentation/types";
import TextField from "./TextField";
import { colors } from "src/presentation/theme";

type Props = {
  label?: string;
  selected?: boolean;
  children?: string;
};

const CircleWithLabel = ({ label, selected, children }: Props) => {
  return (
    <div css={styles.mainContainer}>
      <div css={[styles.innerContainer, selected ? {backgroundColor: colors.secondary} : {}]}>
        <TextField fontSize="small" fontWeight="bold" color="white">{children}</TextField>
      </div>
      {label && (
        <TextField fontSize="extraSmall" fontWeight="bold" color="darkGray">
          {label}
        </TextField>
      )}
    </div>
  );
};

const styles: StyleType = {
  mainContianer: css({
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  }),
  innerContainer: css({
    display: "grid",
    placeItems: "center",
    margin: "0 auto 0 auto",
    height: "40px",
    width: "40px",
    borderRadius: "100%",
    backgroundColor: colors.grayBackground2,
  }),
};

export default CircleWithLabel;
