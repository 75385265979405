export class User {
  constructor(
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly email: string,
    readonly phone: string,
    readonly image: string,
    readonly sendbirdId: string,
    readonly sendbirdToken: string,
    readonly company?: {
      id: number,
      name: string,
      address: string,
    },
  ) {}
}