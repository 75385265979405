import { GetCompanyContactsPrevUseCase } from "../usecases/company/GetCompanyContactsPrevUseCase";

export class CompanyInteractor {
  constructor(
    private getCompanyContactsPrevUseCase: GetCompanyContactsPrevUseCase,
  ) {}

  getCompanyContactsPrev() {
    return this.getCompanyContactsPrevUseCase.execute();
  }
}
