import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "src/presentation/di";

type Props = {
  authMainRoute?: string;
  children: JSX.Element;
};

const AppRoute = ({ authMainRoute = "/login", children }: Props) => {
  const { isLogged } = useAuth();

  if (!isLogged) {
    return <Navigate replace to={authMainRoute} />;
  }

  return children;
};

export default AppRoute;
