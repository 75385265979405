import React from "react";
import { Oval } from "react-loader-spinner";
import { colors } from "src/presentation/theme";

type Props = {
  color: keyof typeof colors;
  secondaryColor?: keyof typeof colors;
  size: number | string;
};

const LoadingSpinner = ({ color, secondaryColor = "pureWhite", size }: Props) => {
  return (
    <Oval
      visible={true}
      height={size}
      width={size}
      ariaLabel="blocks-loading"
      wrapperClass="blocks-wrapper"
      color={colors[color]}
      secondaryColor={secondaryColor}
      strokeWidth={5}
    />
  );
};

export default LoadingSpinner;
