/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { DateFormatter } from "src/domain/utils/DateFormatter";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { StyleType } from "src/presentation/types";
import TitleWithText from "./TitleWithText";

const locationImage = require("src/presentation/assets/images/location-map-image.png");

type Props = {
  scope: string;
  materialsBeProvided: boolean;
  estimatedStartDate: Date;
  estimatedEndDate: Date;
  locationName: string;
  locationCoordinates: {
    lat?: number | undefined;
    lng?: number | undefined;
  };
  onOpenLocation: () => void;
  squareFeet?: number;
  budget: { min: number; max: number };
  deadline?: Date;
};

const SummaryAndLocationTab = ({
  scope,
  materialsBeProvided,
  estimatedStartDate,
  estimatedEndDate,
  locationName,
  locationCoordinates,
  onOpenLocation,
  squareFeet,
  budget,
  deadline,
}: Props) => {
  const locationAvailable = !!(locationCoordinates?.lat && locationCoordinates?.lng);

  return (
    <div css={styles.mainContainer}>
      <div css={styles.summaryContainer}>
        <TitleWithText title="Scope of work" text={scope} />
        <TitleWithText
          title="Who is providing materials?"
          text={materialsBeProvided ? "My company" : "Subtrade"}
        />
        <div css={styles.subInfoContainer}>
          {deadline && (
            <TitleWithText
              title="Project deadline"
              text={DateFormatter.toMMMdoYYYY(deadline)}
            />
          )}
          <TitleWithText
            title="Estimated start"
            text={DateFormatter.toMMMdoYYYY(estimatedStartDate)}
          />
          <TitleWithText
            title="Estimated end"
            text={DateFormatter.toMMMdoYYYY(estimatedEndDate)}
          />
          {(squareFeet !== undefined) && (
            <TitleWithText title="Square feet" text={squareFeet.toString()} />
          )}
          <TitleWithText
            title="Budget"
            text={`$${budget.min} - $${budget.max}`}
          />
        </div>
      </div>

      <div css={styles.locationContainer}>
        <TitleWithText title="Location" text={locationName} />

        {locationAvailable && <div css={styles.locationImageContainer} onClick={onOpenLocation}>
          <img css={styles.locationImage} src={locationImage} alt="Map image" />
        </div>}
      </div>
    </div>
  );
};

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    flex: 1,
    gap: "100px",

    [BREAKPOINTS.SMALL_MEDIUM]: {
      flexDirection: "column",
      gap: "40px",
    },
  }),
  summaryContainer: css({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "40px",
    width: "50%",

    [BREAKPOINTS.SMALL_MEDIUM]: {
      width: "100%",
    },
  }),
  locationContainer: css({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "50%",
    gap: "20px",

    [BREAKPOINTS.SMALL_MEDIUM]: {
      width: "100%",
    },
  }),
  subInfoContainer: css({
    display: "flex",
    flexWrap: "wrap",
    gap: "40px",
    alignItems: "center",
    justifyContent: "space-between",

    div: {
      flex: 1,

      p: {
        whiteSpace: "nowrap",
      },
    },
  }),
  locationImageContainer: css({
    cursor: "pointer",
  }),
  locationImage: css({
    width: "100%",
  }),
};

export default SummaryAndLocationTab;
