import {
  CREATE_PROJECT_URL,
  GET_CREATED_PROJECTS_LIST_URL,
  GET_CREATED_PROJECT_BY_ID_URL,
  GET_PROJECT_TYPES_URL,
  GET_QUOTE_PROJECTS_LIST_URL,
  GET_QUOTE_PROJECT_BY_ID_URL,
  UPDATE_PROJECT_QUOTE_URL,
  UPDATE_PROJECT_URL,
  UPLOAD_PROJECT_QUOTE__URL,
} from "src/data/apiUrls";
import { ApiCreatedProject } from "src/data/models/project/ApiCreatedProject";
import { ApiCreatedProjectPrev } from "src/data/models/project/ApiCreatedProjectPrev";
import { ApiQuoteProject } from "src/data/models/project/ApiQuoteProject";
import { ApiQuoteProjectPrev } from "src/data/models/project/ApiQuoteProjectPrev";
import {
  CustomError,
  DataResponse,
  DateFormatter,
  errorCodeEnum,
  errorMessageEnum,
  IHttpClient,
} from "src/domain";
import { IProjectRemoteDataSource } from "./IProjectRemoteDataSource";
import { ApiQuotePrev } from "src/data/models/project/ApiQuotePrev";
import { ApiProjectType } from "src/data/models/project/ApiProjectType";

export class ProjectRemoteDataSourceImp implements IProjectRemoteDataSource {
  constructor(private httpClient: IHttpClient) {}

  async getCreatedProjectsPrev(): DataResponse<ApiCreatedProjectPrev[]> {
    try {
      const apiProjectsJson = (await this.httpClient.get<ApiCreatedProjectPrev[]>(
        GET_CREATED_PROJECTS_LIST_URL
      )) as ApiCreatedProjectPrev[];
      const apiProjects = apiProjectsJson.map((apiProjectJson) => ApiCreatedProjectPrev.fromJSON(apiProjectJson));

      return apiProjects;
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.SERVER_ERROR
      );
    }
  }

  async getCreatedProjectById(id: string): DataResponse<ApiCreatedProject> {
    try {
      const projectJSON = (await this.httpClient.get<ApiCreatedProject>(
        `${GET_CREATED_PROJECT_BY_ID_URL}/${id}`
      )) as ApiCreatedProject;
      const project = ApiCreatedProject.fromJSON(projectJSON);

      return project;
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.SERVER_ERROR
      );
    }
  }

  async getQuoteProjectsPrev(): DataResponse<ApiQuoteProjectPrev[]> {
    try {
      const apiProjectsJson = (await this.httpClient.get<ApiQuoteProjectPrev[]>(
        GET_QUOTE_PROJECTS_LIST_URL
      )) as ApiQuoteProjectPrev[];
      const apiProjects = apiProjectsJson.map((apiProjectJson) => ApiQuoteProjectPrev.fromJSON(apiProjectJson));

      return apiProjects;
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.SERVER_ERROR
      );
    }
  }

  async getQuoteProjectById(id: string): DataResponse<ApiQuoteProject> {
    try {
      const projectJSON = (await this.httpClient.get<ApiQuoteProject>(
        `${GET_QUOTE_PROJECT_BY_ID_URL}/${id}`
      )) as ApiQuoteProject;
      const project = ApiQuoteProject.fromJSON(projectJSON);

      return project;
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.SERVER_ERROR
      );
    }
  }

  async uploadProjectQuote(
    projectId: string,
    price: number,
    paymentTerms: string,
    projectStartDate: Date,
    projectEndDate: Date,
    fileUrls: string[]
  ): DataResponse<ApiQuotePrev> {
    const projectStartDateString = DateFormatter.toYYYYMMDD(projectStartDate);
    const projectEndDateString = DateFormatter.toYYYYMMDD(projectEndDate);
    
    try {
      const apiQuotePrevJson = await this.httpClient.post(UPLOAD_PROJECT_QUOTE__URL, {
        project_id: projectId,
        price: price,
        payment_terms: paymentTerms,
        project_start_date: projectStartDateString,
        project_end_date: projectEndDateString,
        documents: fileUrls,
      });

      return ApiQuotePrev.fromJSON(apiQuotePrevJson);
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.UPLOAD_QUOTE_ERROR
      );
    }
  }

  async updateProjectQuote(quoteId: string, price: number, paymentTerms: string, projectStartDate: Date, projectEndDate: Date, fileUrls: string[]): DataResponse<ApiQuotePrev> {
    const projectStartDateString = DateFormatter.toYYYYMMDD(projectStartDate);
    const projectEndDateString = DateFormatter.toYYYYMMDD(projectEndDate);
    
    try {
      const apiQuotePrevJson = await this.httpClient.put(`${UPDATE_PROJECT_QUOTE_URL}/${quoteId}`, {
        price: price,
        payment_terms: paymentTerms,
        project_start_date: projectStartDateString,
        project_end_date: projectEndDateString,
        documents: fileUrls,
      });

      return ApiQuotePrev.fromJSON(apiQuotePrevJson);
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.UPLOAD_QUOTE_ERROR
      );
    }
  }

  async createProject(
    jobSiteId: string,
    typeOfWork: string,
    scope: string,
    budget: { min: number; max: number },
    materialsBeProvided: boolean,
    startDate: Date,
    endDate: Date,
    deadline: Date,
    squareFeet: number
  ): DataResponse<ApiCreatedProject> {
    const startDateString = DateFormatter.toYYYYMMDD(startDate);
    const endDateString = DateFormatter.toYYYYMMDD(endDate);
    const deadlineString = DateFormatter.toYYYYMMDD(deadline);

    try {
      const apiCreatedProjectJson = await this.httpClient.post(
        CREATE_PROJECT_URL,
        {
          job_site_id: jobSiteId,
          type_of_work: typeOfWork,
          scope,
          budget: JSON.stringify(budget),
          material_be_provided: JSON.stringify(materialsBeProvided),
          estimated_start_date: startDateString,
          estimated_end_date: endDateString,
          deadline: deadlineString,
          square_feet: squareFeet,
        }
      );

      const apiCreatedProject = ApiCreatedProject.fromJSON(
        apiCreatedProjectJson
      );

      return apiCreatedProject;
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.CREATE_PROJECT_ERROR
      );
    }
  }

  async updateProject(
    projectId: string,
    billingContactId?: string,
    siteSupervisorId?: string,
    documentUrls?: string[],
    mediaUrls?: string[],
    typeOfWork?: string,
    scope?: string,
    budget?: {min: number, max:number},
    materialBeProvided?: boolean,
    estimatedStartDate?: Date,
    estimatedEndDate?: Date,
    quoteDeadline?: Date,
    jobSiteId?: number,
    title?: string,
    squareFeet?: number
  ): DataResponse<ApiCreatedProject> {
    const startDateString = estimatedStartDate ? DateFormatter.toYYYYMMDD(estimatedStartDate) : undefined;
    const endDateString = estimatedEndDate ? DateFormatter.toYYYYMMDD(estimatedEndDate) : undefined;
    const deadlineString = quoteDeadline ? DateFormatter.toYYYYMMDD(quoteDeadline) : undefined;

    try {
      const updatedCreatedProjectJson = await this.httpClient.put(
        `${UPDATE_PROJECT_URL}/${projectId}`,
        {
          blueprint_documents: documentUrls,
          relevant_documents: mediaUrls,
          billing_contact_id: billingContactId,
          site_supervisor_id: siteSupervisorId,
          type_of_work: typeOfWork,
          scope,
          budget,
          material_be_provided: materialBeProvided,
          estimated_start_date: startDateString,
          estimated_end_date: endDateString,
          job_site_id: jobSiteId,
          title,
          square_feet: squareFeet,
          deadline: deadlineString,
        }
      );

      const updatedCreatedProject = ApiCreatedProject.fromJSON(updatedCreatedProjectJson)

      return updatedCreatedProject;
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.CREATE_PROJECT_ERROR
      );
    }
  }

  async getProjectTypes(): DataResponse<ApiProjectType[]> {
    try {
      const apiProjectTypesJson = await this.httpClient.get(`${GET_PROJECT_TYPES_URL}`) as any[];

      const apiProjectTypes = apiProjectTypesJson.map((apiProjectTypeJson: any) => ApiProjectType.fromJSON(apiProjectTypeJson)) as ApiProjectType[]

      return apiProjectTypes;
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        errorMessageEnum.GET_PROJECT_TYPES_ERROR
      );
    }
  }
}
