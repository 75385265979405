/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { ProjectPrev } from "src/domain";
import { DateFormatter } from "src/domain/utils/DateFormatter";
import { BREAKPOINTS } from "src/presentation/breakpoints";
import { colors } from "src/presentation/theme";
import { StyleType } from "src/presentation/types";
import ActionButton from "./ActionButton";
import Card from "./Card";
import CircularImage from "./CircularImage";
import CardFieldContainer from "./CardFieldContainer";
import TextField from "./TextField";

type Props = {
  project: ProjectPrev;
  onGoToPreview: () => void;
  onGoToViewProject: () => void;
};

const QuoteProjectPrev = ({
  project,
  onGoToPreview,
  onGoToViewProject,
}: Props) => {
  return (
    <Card>
      <div css={styles.mainContainer}>
        <div css={[styles.centeredFlexContainer, styles.companyContainer]}>
          <CircularImage size="40px" defaultBackgroundColor="lightGreen" />

          <TextField fontWeight="regular" fontSize="small">
            {project.companyName}
          </TextField>
        </div>

        <CardFieldContainer label="type of work" flex={2 / FLEX_TOTAL}>
          <TextField fontWeight="light" fontSize="small" color="darkGrayText">
            {project.typeOfWork}
          </TextField>
        </CardFieldContainer>

        <CardFieldContainer label="estimated start" flex={2 / FLEX_TOTAL}>
          <TextField fontWeight="light" fontSize="small" color="darkGrayText">
            {DateFormatter.toInvitedProjectDateFormat(
              project.estimatedStartDate
            )}
          </TextField>
        </CardFieldContainer>

        <CardFieldContainer label="estimated end" flex={2 / FLEX_TOTAL}>
          <TextField fontWeight="light" fontSize="small" color="darkGrayText">
            {DateFormatter.toInvitedProjectDateFormat(project.estimatedEndDate)}
          </TextField>
        </CardFieldContainer>

        <CardFieldContainer label="quote deadline" flex={2 / FLEX_TOTAL}>
          <TextField fontWeight="light" fontSize="small" color="darkGrayText">
            {project.deadline ? DateFormatter.toInvitedProjectDateFormat(project.deadline) : ""}
          </TextField>
        </CardFieldContainer>

        <div css={[styles.centeredFlexContainer, styles.buttonsContainer]}>
          {/* <ActionButton onClick={onGoToPreview} color="secondary">
            Preview
          </ActionButton> */}
          <ActionButton onClick={onGoToViewProject} color="primary">
            View project
          </ActionButton>
        </div>
      </div>
    </Card>
  );
};

const FLEX_TOTAL = 14;

const styles: StyleType = {
  mainContainer: css({
    display: "flex",
    padding: "15px 20px",
    width: "100%",
    gap: "10px",

    [BREAKPOINTS.MEDIUM_LARGE]: {
      flexDirection: "column",
    },
  }),
  centeredFlexContainer: css({
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  }),
  companyContainer: css({
    flex: 3 / FLEX_TOTAL,
    gap: "28px",
    flexShrink: 1,
  }),
  buttonsContainer: css({
    flex: 3 / FLEX_TOTAL,
    gap: "12px",
    justifyContent: "end",
    [BREAKPOINTS.MEDIUM_LARGE]: {
      justifyContent: "center",
    },
  }),
};

export default QuoteProjectPrev;
